import { ArticleSummaryPageContent } from './ArticleSummaryPageContent';
import { DefaultLayout } from "@components/core/Layouts/DefaultLayout/DefaultLayout";
export const ArticleSummaryPage = ({
  page,
  allPages
}: {
  page: any;
  allPages: any[];
}) => {
  return <DefaultLayout page={page} allPages={allPages} data-sentry-element="DefaultLayout" data-sentry-component="ArticleSummaryPage" data-sentry-source-file="ArticleSummaryPage.tsx">
      <ArticleSummaryPageContent page={page} allPages={allPages} data-sentry-element="ArticleSummaryPageContent" data-sentry-source-file="ArticleSummaryPage.tsx" />
    </DefaultLayout>;
};