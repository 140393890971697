import React from "react";
import { ASPECT_RATIO } from "@consts/aspectRatio";
import { RADIUS, TRadiusValue } from "@consts/roundCorners";
import { ImageNEW } from "@projectTypes/index";
import { SmartImage } from "@components/reusables/SmartImage/SmartImage";
interface ImageBlockProps {
  image: ImageNEW;
  cropAspectRatio?: ASPECT_RATIO | undefined;
  radius?: TRadiusValue | undefined;
  title?: null | string;
  subtitle?: null | string;
}
const createAlt = (image: ImageNEW, title: string | undefined | null): string => {
  if (title && title != "") return title.trim();
  if (image?.title && image.title != "") return image.title.trim();
  return "";
};
export const ImageBlock = ({
  image,
  cropAspectRatio = undefined,
  radius = RADIUS.NONE,
  title = null,
  subtitle = null
}: ImageBlockProps) => {
  const alt = createAlt(image, title);
  title = title && title.trim() != "" ? title.trim() : null;
  subtitle = subtitle && subtitle.trim() != "" ? subtitle.trim() : null;
  return <>
      <SmartImage src={image.downloadUrl} cropAspectRatio={cropAspectRatio} radius={radius} alt={alt} width={image.width} height={image.height} data-sentry-element="SmartImage" data-sentry-source-file="ImageBlock.tsx" />
      {title ? <p style={{
      display: "none"
    }}>{title}</p> : null}
      {subtitle ? <p style={{
      display: "none"
    }}>{subtitle}</p> : null}
    </>;
};