import React from "react";
import classNames from "classnames";
import Link from "next/link";
import { BreadcrumbComponent, BreadcrumbLink, BreadcrumbHeightPadding, BreadcrumbSpan, BreadcrumbLinkDark, BreadcrumbSpanDark } from "./BreadcrumbNavigation.css";
// TODO: tohle se musi s novou responsivitou udelat jinak
const maxWidthMobileLarge = 591;
interface BreadcrumbNavigationItem {
  label: string;
  url: string;
}
export interface BreadcrumbNavigationProps {
  items: BreadcrumbNavigationItem[];
  threeLineMenu?: boolean;
  darkMode?: boolean;
}
export const BreadcrumbNavigation = ({
  items,
  threeLineMenu = false,
  darkMode = false
}: BreadcrumbNavigationProps) => {
  items = items.slice(1);
  return <div className={classNames(BreadcrumbComponent, threeLineMenu ? BreadcrumbHeightPadding : null)} data-sentry-component="BreadcrumbNavigation" data-sentry-source-file="BreadcrumbNavigation.tsx">
      <a href="https://www.ceskatelevize.cz/vse-o-ct/" className={classNames(darkMode ? BreadcrumbLinkDark : BreadcrumbLink)}>
        Vše o ČT
      </a>
      {items.map((item, i) => i === items.length - 1 ? <span key={i} className={classNames(darkMode ? BreadcrumbSpanDark : BreadcrumbSpan)}>
            {item.label}
          </span> : <Link key={i} href={item.url} className={classNames(darkMode ? BreadcrumbLinkDark : BreadcrumbLink)}>
            {item.label}
          </Link>)}
    </div>;
};