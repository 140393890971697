import { DefaultLayout } from "@components/webCareer/Layouts/DefaultLayout/DefaultLayout";
import { StructuredContenBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock";
import { AttachedDocumentsBlock } from "@components/blocks/AttachedDocumentsBlock/AttachedDocumentsBlock";
import { formatDateTime } from "@utils/datetime";
import { AuthorDateBox } from '@components/reusables/AuthorDateBox/AuthorDateBox';
export const ArticlePage = ({
  page,
  allPages
}: any) => {
  return <DefaultLayout page={page} allPages={allPages} data-sentry-element="DefaultLayout" data-sentry-component="ArticlePage" data-sentry-source-file="ArticlePage.tsx">
      <>
        {/** výpis autora a data */}
        <AuthorDateBox author={page?.displayedAuthorFullName} date={formatDateTime(page?.displayDate)} data-sentry-element="AuthorDateBox" data-sentry-source-file="ArticlePage.tsx" />

        {/** strukturovany obsah */}
        <StructuredContenBlock content={page.structuredContent} data-sentry-element="StructuredContenBlock" data-sentry-source-file="ArticlePage.tsx" />

        {/** pripojene soubory */}
        {page.attachedDocuments?.length > 0 ? <AttachedDocumentsBlock documents={page.attachedDocuments.map((item: any) => item.value)} /> : null}
      </>
    </DefaultLayout>;
};