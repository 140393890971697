import { useState, useEffect } from "react";
import { WithSideMenuLayout } from "@components/core/Layouts/WithSideMenuLayout/WithSideMenuLayout";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import { CarouselHorizontalMenu } from "@components/reusables/CarouselHorizontalMenu/CarouselHorizontalMenu";
import { DATE_FORMAT } from "consts/consts";
import classNames from "classnames";
import { prepareEvents, createEventsCarouselHorizontalNavigationMenuItems, filterEventsByYear } from "@components/webHistory/utils";
import { componentHeader, componentHeader_h1, historySlider, historyItems, hisotryItem, hisotryItem_h3, historyBtns, historyBtn, historyBtn_svg, historyBtn_svgPath, historyBtn_div, historyBtn_span, historyBtn_next, historyBtn_nextSvg, historyBtn_back, historyBtn_backSvg } from "./EventsPage.css";
const EventItem = ({
  event
}: {
  event: any;
}) => {
  return <div className={classNames(hisotryItem)} data-sentry-component="EventItem" data-sentry-source-file="EventsPage.tsx">
      <h3 className={classNames(hisotryItem_h3)}>
        {event.date.format(DATE_FORMAT.YEAR_MONTH_DAY)}
      </h3>
      <HTMLBlock HTML={event.description} data-sentry-element="HTMLBlock" data-sentry-source-file="EventsPage.tsx" />
    </div>;
};
export const EventsPage = ({
  page,
  allPages
}: any) => {
  // events
  const events = prepareEvents(page.events.map((item: any) => item.value));

  // polozky navigace (roky)
  const navigationItems = createEventsCarouselHorizontalNavigationMenuItems(events);

  // aktualni rok
  const [currentId, setCurrentId] = useState<string>(navigationItems[0].id);

  // predchozi id
  const [previousId, setPreviousId] = useState<string | null>(null);

  // nasledujici id
  const [nextId, setNextId] = useState<string | null>(null);

  // reakce na zmenu currentId
  useEffect(() => {
    let index: number = 0;
    navigationItems.forEach((item: any, i: number) => {
      if (item.id === currentId) index = i;
    });
    setPreviousId(navigationItems[index - 1] ? navigationItems[index - 1].id : null);
    setNextId(navigationItems[index + 1] ? navigationItems[index + 1].id : null);
  }, [currentId, navigationItems]);
  return <WithSideMenuLayout page={page} allPages={allPages} data-sentry-element="WithSideMenuLayout" data-sentry-component="EventsPage" data-sentry-source-file="EventsPage.tsx">
      <>
        <div className={classNames(componentHeader)}>
          <h1 className={classNames(componentHeader_h1)}>{page.title}</h1>
          <HTMLBlock HTML={page.perex} data-sentry-element="HTMLBlock" data-sentry-source-file="EventsPage.tsx" />
        </div>
        <div className={classNames(historySlider)}>
          <CarouselHorizontalMenu currentId={currentId} items={navigationItems} onSelect={id => {
          if (id !== currentId) {
            setCurrentId(id);
          }
        }} data-sentry-element="CarouselHorizontalMenu" data-sentry-source-file="EventsPage.tsx" />
        </div>

        <div className={classNames(historyItems)}>
          {filterEventsByYear(events, currentId).map((event: any, i: number) => <EventItem key={i} event={event} />)}
        </div>
        {previousId || nextId ? <div className={classNames(historyBtns)}>
            {previousId ? <button onClick={() => setCurrentId(previousId)} className={classNames(historyBtn, historyBtn_next)}>
                <svg className={classNames(historyBtn_svg, historyBtn_nextSvg)} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="40" height="40" rx="8" fill="#F2F4F8" />
                  <path className={classNames(historyBtn_svgPath)} fillRule="evenodd" clipRule="evenodd" d="M19.3871 12.2097C19.7467 11.9301 20.2533 11.9301 20.6129 12.2097L20.7071 12.2929L20.7903 12.3871C21.0699 12.7467 21.0699 13.2533 20.7903 13.6129L20.7071 13.7071L15.414 18.999L28 19C28.5523 19 29 19.4477 29 20C29 20.5128 28.614 20.9355 28.1166 20.9933L28 21L15.414 20.999L20.7071 26.2929L20.7903 26.3871C21.0953 26.7794 21.0676 27.3466 20.7071 27.7071C20.3466 28.0676 19.7794 28.0953 19.3871 27.7903L19.2929 27.7071L12.2929 20.7071L12.2097 20.6129C11.9301 20.2533 11.9301 19.7467 12.2097 19.3871L12.2929 19.2929L19.2929 12.2929L19.3871 12.2097Z" fill="#041E42" />
                </svg>

                <div className={classNames(historyBtn_div)}>
                  {previousId}
                  <span className={classNames(historyBtn_span)}>Následující rok</span>
                </div>
              </button> : <div> </div>}
            {nextId ? <button onClick={() => setCurrentId(nextId)} className={classNames(historyBtn, historyBtn_back)}>
                <div className={classNames(historyBtn_div)}>
                  {nextId}
                  <span className={classNames(historyBtn_span)}>Předchozí rok</span>
                </div>
                <svg className={classNames(historyBtn_svg, historyBtn_backSvg)} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="40" height="40" rx="8" transform="matrix(-1 0 0 1 40 0)" fill="#F2F4F8" />
                  <path className={classNames(historyBtn_svgPath)} fillRule="evenodd" clipRule="evenodd" d="M20.6129 12.2097C20.2533 11.9301 19.7467 11.9301 19.3871 12.2097L19.2929 12.2929L19.2097 12.3871C18.9301 12.7467 18.9301 13.2533 19.2097 13.6129L19.2929 13.7071L24.586 18.999L12 19C11.4477 19 11 19.4477 11 20C11 20.5128 11.386 20.9355 11.8834 20.9933L12 21L24.586 20.999L19.2929 26.2929L19.2097 26.3871C18.9047 26.7794 18.9324 27.3466 19.2929 27.7071C19.6534 28.0676 20.2206 28.0953 20.6129 27.7903L20.7071 27.7071L27.7071 20.7071L27.7903 20.6129C28.0699 20.2533 28.0699 19.7467 27.7903 19.3871L27.7071 19.2929L20.7071 12.2929L20.6129 12.2097Z" fill="#041E42" />
                </svg>
              </button> : null}
          </div> : null}
      </>
    </WithSideMenuLayout>;
};