import React from 'react';
import { Content } from '@czechtv/components';
import Link from 'next/link';
import { speedLinkBoxContainer, speedLinkBoxHeader, speedLinkBoxLink, speedLinkBoxParagraph, headerDecoration } from "@components/blocks/SpeedLinkBox/SpeedLinkBox.css";
export const SpeedLinkBox = () => {
  return <Content data-sentry-element="Content" data-sentry-component="SpeedLinkBox" data-sentry-source-file="SpeedLinkBox.tsx">
        <div className={speedLinkBoxContainer}>
            <h2 className={speedLinkBoxHeader}>Projekt historické a teoretické analýzy televizní tvorby</h2>
            <div className={headerDecoration}></div>
            <p className={speedLinkBoxParagraph}>Odborná reflexe televize jako média.</p>
            <Link href={"/historie/novodoba-historie-ct/projekt-historicke-a-teoreticke-analyzy-televizni-tvorby/"} className={speedLinkBoxLink} data-sentry-element="Link" data-sentry-source-file="SpeedLinkBox.tsx">
              Studie - přehledy - analýzy
            </Link>
        </div>
    </Content>;
};