import { HTMLBlock } from '@components/blocks/HTMLBlock/HTMLBlock';
import { IconBlock } from '@components/blocks/IconBlock/IconBlock';
import classNames from 'classnames';
import { headerDecoration, headerIcon, headerIconRest } from "@components/blocks/StructuredContentBlock/sublocks/HeadlineWithIconBlock/HeadlineWithIconBlock.css";
export const HeadlineWithIconBlock = ({
  value
}: {
  value: any;
}) => {
  return <div className={headerIcon} data-sentry-component="HeadlineWithIconBlock" data-sentry-source-file="HeadlineWithIconBlock.tsx">
      {value?.icon ? <IconBlock value={value.icon} /> : null}
      <div className={headerIconRest}>
        <HTMLBlock HTML={value.text} data-sentry-element="HTMLBlock" data-sentry-source-file="HeadlineWithIconBlock.tsx" />
        {!!value.decoration ? <div className={headerDecoration}></div> : null}
      </div>
    </div>;
};