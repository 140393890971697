import { ImageBlock } from "@components/blocks/ImageBlock/ImageBlock";
import Link from "next/link";
import classNames from "classnames";
import { ButtonPrimary } from "@czechtvPackagesOverrides/components/ButtonPrimary";
import { RADIUS } from "@consts/roundCorners";
import { ASPECT_RATIO } from "@consts/aspectRatio";
import { mainContainer, mainHeadline, directorList, directorList4, directorList3, itemTxt, itemTxt_h3, itemTxt_p, itemTxt_a, btnContainer, HelperImgHAuto } from "./DirectorsStrip.css";
interface DirectorsStripProps {
  data: any;
  displayedCount: number;
}
export const DirectorsStrip = ({
  data,
  displayedCount
}: DirectorsStripProps) => {
  const preparedData: any = {};
  const prepareData = () => {
    preparedData.headline = data.headline;
    preparedData.detailLink = data.detailLink[0];
    preparedData.directors = [];
    data.directors.forEach((source: any) => {
      source.forEach((directorData: any) => {
        const concatDate = {
          ...directorData.detailPage,
          ...directorData.photo
        };
        preparedData.directors.push(concatDate);
      });
    });
    //preparedData.directors.reverse()
    preparedData.directors = preparedData.directors.slice(0, displayedCount);
  };
  prepareData();
  return <div className={mainContainer} data-sentry-component="DirectorsStrip" data-sentry-source-file="DirectorsStrip.tsx">
      {/** headline */}
      {preparedData?.headline ? <h2 className={mainHeadline}>{preparedData.headline}</h2> : null}

      {/** vypis reditelu */}
      {preparedData?.directors?.length ? <ul className={classNames(directorList, displayedCount == 4 ? directorList4 : directorList3)}>
          {/* @ts-ignore */}

          {preparedData.directors.map((personPhoto: any, i: number) => <DirectorsStripItem key={i} personPhoto={personPhoto} />)}
        </ul> : null}

      {/** link */}
      {preparedData?.detailLink ? <div className={btnContainer}>
          <ButtonPrimary anchor href={preparedData.detailLink.url}>
            {preparedData.detailLink.title}
          </ButtonPrimary>
        </div> : null}
    </div>;
};
const DirectorsStripItem = ({
  personPhoto
}: {
  personPhoto: any;
}) => {
  return <li className={itemTxt} data-sentry-component="DirectorsStripItem" data-sentry-source-file="DirectorsStrip.tsx">
      {personPhoto?.url ? <Link href={personPhoto.url} className={itemTxt_a}>
          <div className={HelperImgHAuto}>
            <ImageBlock radius={RADIUS.CIRCLE} image={{
          downloadUrl: personPhoto.image.downloadUrl,
          width: personPhoto.image.width,
          height: personPhoto.image.height,
          title: personPhoto.title
        }} cropAspectRatio={ASPECT_RATIO.SQUARE} />
          </div>
          <h3 className={itemTxt_h3}>{personPhoto.title}</h3>
          <p className={itemTxt_p}>{personPhoto.subtitle}</p>
        </Link> : <>
          <div className={HelperImgHAuto}>
            <ImageBlock radius={RADIUS.CIRCLE} image={{
          downloadUrl: personPhoto.image.downloadUrl,
          width: personPhoto.image.width,
          height: personPhoto.image.height,
          title: personPhoto.title
        }} cropAspectRatio={ASPECT_RATIO.SQUARE} />
          </div>
          <h3 className={itemTxt_h3}>{personPhoto.title}</h3>
          <p className={itemTxt_p}>{personPhoto.subtitle}</p>
        </>}
    </li>;
};