import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fglobal%2Fglobal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42WTW%2FbMAyG7%2FsVPK6AUoiULNnabbcdBgzLsOvgJE7qLl91kjbtsP8%2BMLGU1lTXIrEO8iO%2BIl%2BJSeg2mz38%2BQAwGv3CatXd1LUOMN4cumkzrte7b91m9L1ZHJZ1p2BXr3ejXdO1808vVuBwxbhZtZ83y9nrS2i45Mu%2BXrbT1xeYAD9%2BMjyedk2zfntTdrDg%2F%2FspBvTXZtYeVq%2FzLkC3mNQfK6cAnVZARaEAr15Svqe04s%2Fwbdm%2F5aXxGTJVZIxXVCrjBFE%2Fi4LOZHQmPVF4RdoprEgg0x4xhYISFWDlZTazHkJyCssyo9REwlkui1GAJclA87hly1IaFZhM8RYpMaOACPthiN0kjKJcaSTWJoxjnIRtBrsN8HLi95sGLGPSFW%2BRj0KmdqsIWT4wp8JIaB0hXShgTktm0zPOKSAFVhLbFEXB%2BTsk7mJOxJU1vO0iE6iLgcqCEyPGMm7uUmqclreMZUT38cZwCOsVkM2U4BAPIqd%2F8txkPL%2BPCXgFpxtYZkr%2B8B7o%2BKxYp5OhM4V47CGrjFZOnoCnGOTkGmWSR31BkLNyGQZTtwCyCnLlQUpXh%2BucBsGZZDEjaRCcTYeX1S7DkCvSzea3l2HIuXQYLtcsYyDGzoh4qkgahlzskXyv4yOg2CQLryA%2BAop90pQK4iOgSSobF%2Bz8CGiaIEq1zQWbJa8oeZXjmnfGmyevit4myp1WXCSvimiTz3E37%2BTadEd88qrKcLc95%2F3ZpypXvNhPuZOwTy7nVWyphs4%2B5foJppbqzj6Z3N7XAcrtcTC5CYBOzG4DkBWzdwEMidkugNVidhfASrV9gEKqHQI4qXYfwEu1hwClVDsGKKXaY4BKqj0F7mzDadIBEIUeYQAkIUjE00KRTAA0QpJsALRSsgiAhZR0bImU9DwtJcsA6KVkFQBLKVkHwEpKTgKQlpLTANPDpJ2OJs1T23T83%2B0alb42ou3TjHPRq91gOv0Lev5br6%2FpCvT2CLg9Am2PIJXn%2BXXFZZ3JrlsEkEnfZA4%2BtbmDT7cB%2FNnAv%2F8Achf%2FrxAMAAA%3D%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22utils%2Fvanilla%2Fshorthand.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xMThmMXAzMCB7CiAgZGlzcGxheTogZmxleDsKICBmbGV4LXdyYXA6IHdyYXA7CiAgYWxpZ24taXRlbXM6IGNlbnRlcjsKfQouXzExOGYxcDMxIHsKICBkaXNwbGF5OiBmbGV4OwogIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47CiAgZmxleDogMTsKfQ%3D%3D%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2Fblocks%2FTestimonialBlock%2FTestimonialBlock.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA62V0W7CIBSG732Ks4st60Ub6Fq19WZvsqCgsrXAEKdu2bsvRXRSWuuSxRgjcD7%2B858%2FbfKCdabMa4HgawRQE73iIp5LY2RdwgfRj3H8gotarwnB79Fs9D1KTiXYlqyICg7KaDYIUz4stbAdp2ZdAkbo3tt9srtLKUy8JDWvDm1cam%2B0Bzb8k5WAkzTXrG5WKy5YvGZ8tTYlpG7xKK4E1PxZyErqQGHhK8x%2BNZyuwN134OS0fEUxsoop36iKHEqYV3Lxdi7ZOVKGkKchDzX0KJjcICGPeps%2FXE7QSFUCUvvhoR4HrwilXKycuSenW0dF1CDP3zteK6kNEcZreHw1FlNvN809ifpohW9gEfLaBR0teYJaw%2BhpT0aA7KenscmNHDHASeEBKP%2BwiLnUlOlYE8q3m55GnmtGOYHNQjMmgAgKjzUXsXNknCG1jyws8Z8MQ5Pf2sl%2FXxbmXmGnQz0BaIHGDuREkq2RM7uwrNi%2BBBwUTFxBsx%2FvdPOEErL5DU5OfXSauZgPhEKH3Rb%2FRgoi1muh6gpH4MVfBtJJG0rNZDwNUoOvjaD7raGj0IvUd%2FUJOVcvRF0qKfIwvy1EjqZnxA9VvIMa%2FgYAAA%3D%3D%22%7D"
export var testimonial = '_1r4ptj90';
export var testimonialDescription = '_1r4ptj93';
export var testimonialHeading = '_1r4ptj95';
export var testimonialImageRounded = '_1r4ptj98';
export var testimonialItemRounded = '_1r4ptj97 _118f1p30';
export var testimonialPosition = '_1r4ptj94';
export var testimonialRectangle = '_1r4ptj91 _118f1p30';
export var testimonialTxt = '_1r4ptj96';
export var testimonialVideoRectangle = '_1r4ptj92';
export var testimonialVideoRounded = '_1r4ptj99';