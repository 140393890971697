import { useMediaQuery } from "@czechtv/styles";
export const DEFAULT_REGULAR = "SourceSansPro-Regular, sans-serif"; // v glabalni vanille
export const DEFAULT_SEMIBOLD = "SourceSansPro-SemiBold, sans-serif"; // v glabalni vanille
export const DEFAULT_ITALIC = "SourceSansPro-Italic, sans-serif"; // v glabalni vanille
export const IMPORTANT_REGULAR = "TVSansScreen-Regular, sans-serif"; // v glabalni vanille
export const IMPORTANT_BOLD = "TVSansScreen-Bold, sans-serif"; // v glabalni vanille
export const IMPORTANT_MEDIUM = "TVSansScreen-Medium, sans-serif"; // v glabalni vanille

const DEFAULT_FONT_SIZE = 16;
export const Blue_base = "#041e42";
export const Grey_80 = "#393939";
export const Grey_60 = "#6f6f6f";
export const Blue_lighter_20 = "#124990";
export const Red_base = "#ed1c24";
export const Red_lighter_20 = "#fb8d8f";
export const Red_darker_10 = "#bf141b";
export const Grey_70 = "#525252";
export const Grey_50 = "#8d8d8d";
export const Grey_40 = "#a8a8a8";
export const Grey_30 = "#c6c6c6";
export const Grey_20 = "#e0e0e0";
export const Grey_10 = "#f4f4f4";
export const Blue_dark_background = "#001830";
export const Focus_base = "#60a0ff";
export const Valid = "#6dd400";
export const ButtonTransitionDuration = "150ms";
export const BoxShadowFocus = `0px 0px 0px 2px ${Focus_base}`;
export const BoxShadowFocusInset = `inset ${BoxShadowFocus}`;
export const DefaultRadius = 4;
export const pxToRem = (pixels: number): string => {
  return `${pixels / DEFAULT_FONT_SIZE}rem`;
};
export const mobile360 = 360;
export const mediaMinMobile360 = `@media (min-width: ${mobile360}px)`;
export const mobile480 = 480;
export const mediaMinMobile480 = `@media (min-width: ${mobile480}px)`;
export const tablet640 = 640;
export const mediaMinTablet640 = `@media (min-width: ${tablet640}px)`;
export const desktop1024 = 1024;
export const mediaMinDesktop1024 = `@media (min-width: ${desktop1024}px)`;
const minDesktop1024Query = {
  minWidth: desktop1024
};
export const useMinDesktop1024 = () => useMediaQuery(minDesktop1024Query);
export const tablet768 = 768;
export const mediaMinTablet768 = `@media (min-width: ${tablet768}px)`;
const minTablet768Query = {
  minWidth: tablet768
};
export const useMinTablet768 = () => useMediaQuery(minTablet768Query);
export const Body14Xsmall = {
  fontSize: pxToRem(14),
  letterSpacing: pxToRem(0.2),
  lineHeight: pxToRem(20),
  fontFamily: DEFAULT_REGULAR
};
export const Body16Small = {
  fontSize: pxToRem(16),
  lineHeight: pxToRem(22),
  letterSpacing: 0,
  fontFamily: DEFAULT_REGULAR
};
export const Body18Medium = {
  fontSize: pxToRem(18),
  lineHeight: pxToRem(24),
  letterSpacing: 0,
  fontFamily: DEFAULT_REGULAR
};
export const Body18SemiBold = {
  fontSize: pxToRem(18),
  lineHeight: pxToRem(24),
  letterSpacing: 0,
  fontFamily: DEFAULT_SEMIBOLD,
  fontWeight: 600
};
export const Body21Large = {
  fontSize: pxToRem(21),
  lineHeight: pxToRem(28),
  letterSpacing: 0,
  fontFamily: DEFAULT_REGULAR
};
export const Hero36Medium = {
  fontFamily: IMPORTANT_BOLD,
  fontSize: pxToRem(36),
  lineHeight: pxToRem(40),
  letterSpacing: pxToRem(-0.4)
};
export const Hero48Large = {
  fontFamily: IMPORTANT_BOLD,
  fontSize: pxToRem(48),
  lineHeight: pxToRem(52),
  letterSpacing: pxToRem(-1)
};
export const Hero60XLarge = {
  fontFamily: IMPORTANT_BOLD,
  fontSize: pxToRem(60),
  lineHeight: pxToRem(64),
  letterSpacing: pxToRem(-1.5)
};
export const Header16XSmall = {
  fontFamily: IMPORTANT_MEDIUM,
  fontSize: pxToRem(16),
  lineHeight: pxToRem(22),
  letterSpacing: 0
};
export const Header18Small = {
  fontFamily: IMPORTANT_MEDIUM,
  fontSize: pxToRem(18),
  lineHeight: pxToRem(24),
  letterSpacing: 0
};
export const Header21Medium = {
  fontFamily: IMPORTANT_MEDIUM,
  fontSize: pxToRem(21),
  lineHeight: pxToRem(28),
  letterSpacing: 0
};
export const Header24Large = {
  fontFamily: IMPORTANT_MEDIUM,
  fontSize: pxToRem(24),
  lineHeight: pxToRem(30),
  letterSpacing: 0
};
interface label {
  fontFamily: string;
  fontSize: string;
  letterSpacing: string;
  lineHeight: string;
  textTransform: "uppercase";
}
export const Label14Medium: label = {
  textTransform: "uppercase",
  fontSize: pxToRem(14),
  lineHeight: pxToRem(16),
  letterSpacing: pxToRem(0.5),
  fontFamily: DEFAULT_SEMIBOLD
};
export const Label16Medium: label = {
  textTransform: "uppercase",
  fontSize: pxToRem(16),
  lineHeight: pxToRem(22),
  fontFamily: DEFAULT_SEMIBOLD,
  letterSpacing: pxToRem(0)
};