import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Header } from '@components/layouts/components/Header/Header';
import { Content } from '@czechtv/components';
import { BreadcrumbNavigation } from '@components/layouts/components/BreadcrumbNavigation/BreadcrumbNavigation';
import { createBreadcrumbs } from '@utils/pages';
import classNames from 'classnames';
import { LETTERS } from '@consts/consts';
import { CTGlobalFooter } from '@components/layouts/components/CTGlobalFooter/CTGlobalFooter';
import { pageHtmlUrl2path } from '@utils/pages';
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import { slugify } from '@utils/slugify';
import Link from 'next/link';
import { LinkListBlock } from "@components/blocks/StructuredContentBlock/sublocks/LinkListBlock/LinkListBlock";
import { active, aside, asideHeader, componentHeader, containerMain, headerDecoration, hidden, itemList, itemListDD, itemListLink, itemListWrap, itemListWrapHeader, letterItem, letterItemLink, letterSwitcher } from "@components/core/PageTypes/DictionaryPage/DictionaryPage.css";
export const LetterSwitcher = ({
  activeLetter,
  dictionaryUrl = ''
}: {
  activeLetter?: string | undefined | null;
  dictionaryUrl?: string | undefined;
}) => {
  return <ul className={classNames(letterSwitcher)} data-sentry-component="LetterSwitcher" data-sentry-source-file="DictionaryPage.tsx">
      {LETTERS.map((letter: string) => <li key={`switcher${letter}`} className={classNames(letterItem, letter == activeLetter ? active : null)}>
          <Link className={classNames(letterItemLink)} href={`${dictionaryUrl}#${letter}`}>{letter.toUpperCase()}</Link>
        </li>)}
    </ul>;
};
export const TopItems = ({
  items
}: {
  items: any[];
}) => {
  const topItems = items.filter((item: any) => item.top == true).map((item: any) => {
    return {
      url: pageHtmlUrl2path(item.htmlUrl),
      title: item.title
    };
  });
  if (topItems.length == 0) return <></>;
  return <div className={classNames(aside)} data-sentry-component="TopItems" data-sentry-source-file="DictionaryPage.tsx">
      <h2 className={classNames(asideHeader)}>Nejhledanější pojmy</h2>
      <LinkListBlock value={topItems} linkListStyle="BUTTONS" data-sentry-element="LinkListBlock" data-sentry-source-file="DictionaryPage.tsx" />
    </div>;
};
export const DictionaryPage = ({
  page,
  allPages
}: any) => {
  const router = useRouter();
  const items = page.items;
  const [activeLetter, setActiveLetter] = useState<string | null>(null);

  // pocatecni nastaveni pismene
  useEffect(() => {
    if (items.length && router.isReady) {
      const parsedPath = (router.asPath as string).split('#');
      setActiveLetter(parsedPath.length == 1 ? LETTERS[0] : parsedPath[1]);
    }
  }, [items, router]);

  // vraci true pokud je pismeno nebo title (heslo) aktivni
  const isActive = (str: string) => slugify(str[0]) == activeLetter;
  return <>
    <Header data-sentry-element="Header" data-sentry-source-file="DictionaryPage.tsx" />
    <Content data-sentry-element="Content" data-sentry-source-file="DictionaryPage.tsx">
      {allPages ? <BreadcrumbNavigation items={createBreadcrumbs(page, allPages)} /> : null}
      <div className={classNames(containerMain)}>

          {/** hlavicka */}
          <div className={classNames(componentHeader)}>
            <h1>{page.title}</h1>
            <HTMLBlock HTML={page.perex} data-sentry-element="HTMLBlock" data-sentry-source-file="DictionaryPage.tsx" />
          </div>

          {/** prepinac zobrazeni */}
          <LetterSwitcher activeLetter={activeLetter} data-sentry-element="LetterSwitcher" data-sentry-source-file="DictionaryPage.tsx" />

          {/** vypis polozek */}
          {items.length ? <dl className={classNames(itemList)}>
              {items.map((item: any) => <div key={item.id} className={classNames(!isActive(item.title) ? hidden : itemListWrap)}>
                  <dt>
                    <h3 className={itemListWrapHeader}>
                        {item.title}
                    </h3>
                    <div className={classNames(headerDecoration)}></div>
                  </dt>
                  <dd className={classNames(itemListDD, !isActive(item.title) ? hidden : null)}>
                    <HTMLBlock HTML={item.perex} />
                  </dd>
                  <Link href={pageHtmlUrl2path(item.htmlUrl)} className={classNames(itemListLink)}>
                    Další informace o{item.title}
                  </Link>
                </div>)}
            </dl> : null}

      </div>
      <TopItems items={items} data-sentry-element="TopItems" data-sentry-source-file="DictionaryPage.tsx" />
    </Content>
    <CTGlobalFooter data-sentry-element="CTGlobalFooter" data-sentry-source-file="DictionaryPage.tsx" />
  </>;
};