import { GRID_GAP } from '@consts/consts';
export const RADIUS = {
  NONE: 0,
  SMALL: `${GRID_GAP}px`,
  BASE: `${2 * GRID_GAP}px`,
  BIG: `${3 * GRID_GAP}px`,
  CIRCLE: '50%'
} as const;
export type TRadiusValue = (typeof RADIUS)[keyof typeof RADIUS];
export const withBorderRadius = (value: TRadiusValue = RADIUS.NONE) => {
  return {
    borderRadius: `${value}`
  };
};