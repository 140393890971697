import React from 'react';
import classNames from 'classnames';
import { getSubblockComponent } from '@components/blocks/StructuredContentBlock/StructuredContentBlock';
import { grid, grid_column_1_1, grid_column_1_2, grid_column_1_3, grid_column_1_4, gridItem, movedUp } from "@components/blocks/StructuredContentBlock/sublocks/ContainerBlock/ContainerBlock.css";
export const ContainerBlock = ({
  container
}: {
  container: any;
}) => {
  const getGridColumnClassName = (childrenSize: string) => {
    switch (childrenSize) {
      case '1_4':
        return grid_column_1_4;
      case '1_3':
        return grid_column_1_3;
      case '1_2':
        return grid_column_1_2;
      default:
        return grid_column_1_1;
    }
  };
  const gridColumnClassName = getGridColumnClassName(container.childrenSize);
  return <div className={classNames(!!container?.moveUp ? movedUp : undefined)} data-sentry-component="ContainerBlock" data-sentry-source-file="ContainerBlock.tsx">
      <div className={classNames(grid, gridColumnClassName)}>
        {container.childrens.map((children: any) => <div key={children.id} className={gridItem}>
            {getSubblockComponent(children)}
          </div>)}
      </div>
    </div>;
};