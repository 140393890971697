import { useEffect } from "react";
const smartTableClassName = "smartTable";
const smartTableWrapperClassName = "smartTableWrapper";
const navigationContainerClassName = "smartTableNavigationContainer";
const navigationClassName = "smartTableNavigation";
const leftNavigationClassName = "smartTableNavigationLeft";
const rightNavigationClassName = "smartTableNavigationRight";
import "./SmartTableProvider.css"; // Import globálních stylů

type TSmartTableNavigation = {
  elm: HTMLElement;
  toLeft: HTMLElement | null;
  toRight: HTMLElement | null;
};
const wrappHtmlElement = (element: HTMLElement, wrapper: HTMLElement) => {
  element.parentElement?.insertBefore(wrapper, element);
  wrapper.appendChild(element);
};
const setDisabled = (element: HTMLElement, disabled: boolean) => {
  if (disabled) {
    element.setAttribute("disabled", "disabled");
  } else {
    element.removeAttribute("disabled");
  }
};
const getShiftStep = (table: HTMLTableElement, wrapper: HTMLElement): number => {
  // vypocet kroku pro posouvani
  // nejuzsi sloupec - 10 px
  let shiftStep = 0;
  const elms = table.querySelector("tr")?.querySelectorAll("td, th");
  if (elms && elms?.length > 0) {
    Array.from(elms).forEach((cell, i: number) => {
      const cellWith = (cell as HTMLElement).offsetWidth;
      shiftStep = i == 0 ? cellWith : Math.min(shiftStep, cellWith);
    });
  }
  return shiftStep < 10 ? 10 : shiftStep;
};
const handleNavigationLeft = (table: HTMLTableElement, wrapper: HTMLElement, navigation: TSmartTableNavigation) => {
  const shiftStep = getShiftStep(table, wrapper);
  let newPosition = table.offsetLeft + shiftStep;
  table.style.left = `${newPosition}px`;
  updateTableDisplay(table, wrapper, navigation);
};
const handleNavigationRight = (table: HTMLTableElement, wrapper: HTMLElement, navigation: TSmartTableNavigation) => {
  const shiftStep = getShiftStep(table, wrapper);
  let newPosition = table.offsetLeft - shiftStep;
  table.style.left = `${newPosition}px`;
  updateTableDisplay(table, wrapper, navigation);
};
const updateTableDisplay = (table: HTMLTableElement, wrapper: HTMLElement, navigation: TSmartTableNavigation) => {
  const maxOffset = wrapper.offsetWidth - table.offsetWidth;

  // maxima polohy tabulky
  if (table.offsetLeft > 0) {
    table.style.left = `0px`;
  } else if (table.offsetLeft < maxOffset) {
    table.style.left = `${maxOffset}px`;
  }

  // navigace
  navigation.elm.style.display = table.offsetWidth > wrapper.offsetWidth ? "" : "none";
  navigation.toLeft && setDisabled(navigation.toLeft, table.offsetLeft == 0);
  navigation.toRight && setDisabled(navigation.toRight, table.offsetLeft == maxOffset);
};
const createNavigation = (table: HTMLTableElement, wrapper: HTMLElement) => {
  const navigationWrapper = document.createElement("div");
  navigationWrapper.classList.add(navigationContainerClassName);
  const navigationHTML = document.getElementById("smartTableNavigationTemplate")?.innerHTML;
  if (!navigationHTML) return;
  navigationWrapper.innerHTML = navigationHTML;
  wrapper.appendChild(navigationWrapper);
  navigationWrapper.style.display = "none";
  const navigation: TSmartTableNavigation = {
    elm: navigationWrapper,
    toLeft: navigationWrapper.querySelector(`.${leftNavigationClassName}`),
    toRight: navigationWrapper.querySelector(`.${rightNavigationClassName}`)
  };

  // aktivace zobrazovani/skryvani navigace
  window.addEventListener("resize", () => {
    updateTableDisplay(table, wrapper, navigation);
  });
  wrapper.classList.add("actived");
  setTimeout(() => {
    updateTableDisplay(table, wrapper, navigation);
  }, 500);

  // posouvani tabulky
  if (navigation.toLeft) {
    navigation.toLeft.addEventListener("click", () => {
      handleNavigationLeft(table, wrapper, navigation);
    });
  }
  if (navigation.toRight) {
    navigation.toRight.addEventListener("click", () => {
      handleNavigationRight(table, wrapper, navigation);
    });
  }
};
const createSmartTable = (table: HTMLTableElement) => {
  // trida pro tabulku
  table.classList.add(smartTableClassName);

  // vytvoreni wrapperu
  const wrapper = document.createElement("div");
  wrapper.classList.add(smartTableWrapperClassName);
  wrappHtmlElement(table, wrapper);

  // vytvoreni navigace
  createNavigation(table, wrapper);
};
export const SmartTableProvider = ({
  children
}: any) => {
  useEffect(() => {
    const unsmartedTables = Array.from(document.querySelectorAll("table")).filter(table => !table?.parentElement?.classList.contains(smartTableWrapperClassName));
    unsmartedTables.forEach(table => {
      createSmartTable(table);
    });
  });
  return <>
      {children ? children : null}
      <div id="smartTableNavigationTemplate" className={navigationContainerClassName} style={{
      display: "none"
    }}>
        <button className={`${navigationClassName} ${leftNavigationClassName}`}></button>
        <button className={`${navigationClassName} ${rightNavigationClassName}`}></button>
      </div>
    </>;
};