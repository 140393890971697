import React from 'react';
import classNames from 'classnames';
import { HTMLBlock } from '@components/blocks/HTMLBlock/HTMLBlock';
import { AS_COLUMNS_WRAP, BASE_CONTENT, BASE_HEAD, BASE_WRAP, DEFAULT_WRAP } from "@components/blocks/StructuredContentBlock/sublocks/DefinitionsListBlock/DefinitionsListBlock.css";
type TLinkListStyle = 'DEFAULT' | 'AS_COLUMNS';
export const DefinitionsListBlock = ({
  value,
  linkListStyle = 'DEFAULT'
}: {
  value: any;
  linkListStyle?: TLinkListStyle;
}) => {
  const classes = linkListStyle === 'AS_COLUMNS' ? classNames(AS_COLUMNS_WRAP) : classNames(DEFAULT_WRAP);
  return <dl className={classNames(BASE_WRAP, classes)} data-sentry-component="DefinitionsListBlock" data-sentry-source-file="DefinitionsListBlock.tsx">
      {value.map((item: any, i: number) => <React.Fragment key={i}>
          <dt className={BASE_HEAD}>{item.term}</dt>
          <dd className={BASE_CONTENT}><HTMLBlock HTML={item.definition} /></dd>
        </React.Fragment>)}
    </dl>;
};