import classNames from "classnames";
import { SmartImage } from "@components/reusables/SmartImage/SmartImage";
import { CTServices, CTServicesLink, CTServicesLogo } from "@components/core/PageTypes/HomePage/components/CtServices/CtServices.css";
import Link from "next/link";
import ct1logo from "../../images/ct1Logo.svg";
import ct2logo from "../../images/ct2Logo.svg";
import ct24logo from "../../images/ct24Logo.svg";
import ctsportlogo from "../../images/ctSportLogo.svg";
import ctartlogo from "../../images/ctArtLogo.svg";
import ctdeckologo from "../../images/ctDeckoLogo.svg";
import ctivysilanilogo from "../../images/ctIvysilaniLogo.svg";
import ctedulogo from "../../images/ctEduLogo.svg";
export const CtServices = () => {
  const services = [{
    name: "ČT 1",
    url: "https://www.ceskatelevize.cz/zive/ct1/",
    image: ct1logo
  }, {
    name: "ČT 2",
    url: "https://www.ceskatelevize.cz/zive/ct2/",
    image: ct2logo
  }, {
    name: "ČT 24",
    url: "https://ct24.ceskatelevize.cz/",
    image: ct24logo
  }, {
    name: "ČT sport",
    url: "https://sport.ceskatelevize.cz/",
    image: ctsportlogo
  }, {
    name: "ČT art",
    url: "https://art.ceskatelevize.cz/",
    image: ctartlogo
  }, {
    name: "ČT Déčko",
    url: "https://decko.ceskatelevize.cz/",
    image: ctdeckologo
  }, {
    name: "I vysílání",
    url: "https://www.ceskatelevize.cz/ivysilani/",
    image: ctivysilanilogo
  }, {
    name: "ČT EDU",
    url: "https://edu.ceskatelevize.cz/",
    image: ctedulogo
  }];
  return <div className={classNames(CTServices)} data-sentry-component="CtServices" data-sentry-source-file="CtServices.tsx">
      {services.map((service, index) => <Link key={index} href={service.url} className={classNames(CTServicesLink)}>
            <SmartImage src={service.image.src} width={service.image.width} height={service.image.height} alt={service.name} className={classNames(CTServicesLogo)} />
          </Link>)}
    </div>;
};