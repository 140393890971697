import { useState, useEffect } from "react";
import Link from "next/link";
import classNames from "classnames";
import { ButtonSecondary } from "@czechtvPackagesOverrides/components/ButtonSecondary";
import { ButtonPrimary } from "@czechtvPackagesOverrides/components/ButtonPrimary";
import { StaticContentBlockComponentProps } from "@components/blocks/StructuredContentBlock/sublocks/StaticContentBlock/StaticContentBlock";
import { Spinner } from "@components/reusables/Spinner/Spinner";
import { getPositions, getCategoryByUrl, filterPositionsByCategory, getPositionCategories } from "./../utils";
import { getCategoryJobsCzUrl } from "./../categories";
import { jobsCZDetailLink, jobTableBtn, jobTableCategoryList, jobTableCategoryListItem, jobTableCategoryListItemLink, jobTableHide, jobTableLink, jobTableLinkElement, jobTableLinkElementImage, jobTableMobile, jobTableMobileHide, jobTableMobileOver, jobTableMobileOverLink, jobTableMobilePosition, jobTableMobileTd, jobTableMobileTdLoc, jobTableMobileTr, jobTablePosition, jobTableTH, jobTableWrap, writeToUs, writeToUsSvg, writeToUsTxt } from "@components/webCareer/StaticContentComponents/ListJobsPositions/ListJobsPositions.css";

// pocet zobrazovanych pozic
//const itemsLimit = 5
const itemsLimit = 10;
export const ListJobsPositions = ({
  parameters
}: StaticContentBlockComponentProps) => {
  const categoryUrl = parameters?.categoryUrl;
  const category = getCategoryByUrl(categoryUrl);
  const [positions, setPositions] = useState<any[] | null>(null);
  useEffect(() => {
    getPositions().then((positions: any[]) => {
      if (positions !== undefined) {
        const filteredPositions = filterPositionsByCategory(positions, category);
        setPositions(filteredPositions);
      } else {
        setPositions([]);
      }
    }).catch(() => {
      setPositions([]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const pluralize_00 = (count: number | null) => {
    if (count === null || count == 0) return "";
    if (count == 1) return `pozici`;
    if (count == 2) return `obě pozice`;
    if (count > 2 && count < 5) return `všechny ${count} pozice`;
    return `všech ${count} pozic`;
  };
  return <>
      <div className={classNames(jobTableWrap, positions === null || positions.length == 0 ? jobTableHide : null)}>
        {positions === null ? <Spinner /> : <table className={classNames(jobTableMobile, jobTableMobile)}>
            <thead className={classNames(jobTableMobile)}>
              <tr className={classNames(jobTableMobile)}>
                <th className={classNames(jobTableMobile, jobTableTH)}>Pozice</th>
                <th className={classNames(jobTableMobile, jobTableTH)}>Obor</th>
                <th className={classNames(jobTableMobile, jobTableTH)}>Typ úvazku</th>
                <th className={classNames(jobTableMobile, jobTableTH)}>Lokalita</th>
                <th className={classNames(jobTableMobile, jobTableTH)}></th>
              </tr>
            </thead>
            <tbody className={classNames(jobTableMobile)}>
              {positions.slice(0, itemsLimit).map((position: any) => <tr key={position.id} className={classNames(jobTableMobile, jobTableMobileTr)}>
                  <td className={classNames(jobTablePosition, jobTableMobile, jobTableMobileTd)}>
                    <a className={classNames(jobsCZDetailLink)} href={position.jobsCzDetailUrl} target="_blank" rel="noopener noreferrer">
                      {position.positionName}
                    </a>
                  </td>
                  <td className={classNames(jobTableMobile, jobTableMobileTd)}>
                    {getPositionCategories(position).length > 0 ? <ul className={classNames(jobTableCategoryList)}>
                        {getPositionCategories(position).map((category: any) => <li className={classNames(jobTableCategoryListItem)} key={category.title}>
                            <Link href={category.url} className={classNames(jobTableCategoryListItemLink, jobTableMobilePosition)}>{category.title}</Link>
                          </li>)}
                      </ul> : null}
                  </td>
                  <td className={classNames(jobTableMobile, jobTableMobileTd, position.employment.length == 0 ? jobTableMobileHide : null)}>
                    {position.employment.length > 0 ? position.employment.map((item: any) => item.label).join(", ") : null}
                  </td>
                  <td className={classNames(jobTableMobile, jobTableMobileTd, jobTableMobileTdLoc)}>
                    {position.localityHumanReadable}
                  </td>
                  <td className={classNames(jobTableLink, jobTableMobile, jobTableMobileTd, jobTableMobileOver)}>
                    <a href={position.jobsCzDetailUrl} className={classNames(jobTableLinkElement, jobTableMobileOverLink)} target="_blank" rel="noopener noreferrer">
                      <svg className={classNames(jobTableLinkElementImage)} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M10.5107 3.50874L10.5893 3.57806L16.4226 9.4114C16.723 9.7118 16.7461 10.1845 16.4919 10.5114L16.4226 10.5899L10.5893 16.4232C10.2638 16.7487 9.73618 16.7487 9.41074 16.4232C9.11034 16.1228 9.08723 15.6501 9.34142 15.3232L9.41074 15.2447L13.8208 10.8332L3.33333 10.834C2.8731 10.834 2.5 10.4609 2.5 10.0007C2.5 9.57329 2.8217 9.22106 3.23615 9.17292L3.33333 9.16732L13.8208 9.16648L9.41074 4.75657C9.11034 4.45617 9.08723 3.98348 9.34142 3.65657L9.41074 3.57806C9.71115 3.27766 10.1838 3.25455 10.5107 3.50874Z" fill="#041E42" />
                      </svg>
                    </a>
                  </td>
                </tr>)}
            </tbody>
          </table>}
      </div>

      {/** link do jobs.cz */}

      <div className={classNames(jobTableBtn)}>
        {positions !== null && positions.length > 10 ? <ButtonPrimary anchor target="_blank" href={getCategoryJobsCzUrl(category)}>
            Zobrazit {pluralize_00(positions.length)}
          </ButtonPrimary> : null}
      </div>

      <AnotherPositionInterest positionCount={positions ? positions.length : 0} data-sentry-element="AnotherPositionInterest" data-sentry-source-file="ListJobsPositions.tsx" />
    </>;
};
const AnotherPositionInterest = ({
  positionCount
}: {
  positionCount: number;
}) => {
  return <article className={classNames(writeToUs)} data-sentry-component="AnotherPositionInterest" data-sentry-source-file="ListJobsPositions.tsx">
      <div className={classNames(writeToUsTxt)}>
        {positionCount > 0 ? <h3>Nenašli jste, co jste hledali?</h3> : <h3>Momentálně nemáme žádné pozice.</h3>}
        Zašlete nám svůj životopis společně s kontaktními údaji a my se s vámi spojíme.
      </div>
      <ButtonSecondary anchor href={"/vse-o-ct/kariera/kontakty#formularKariera"} data-sentry-element="ButtonSecondary" data-sentry-source-file="ListJobsPositions.tsx">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={classNames(writeToUsSvg)} data-sentry-element="svg" data-sentry-source-file="ListJobsPositions.tsx">
          <path fillRule="evenodd" clipRule="evenodd" d="M4.22231 4.22199C8.5183 -0.073997 15.4823 -0.073997 19.7783 4.22199C24.0743 8.51798 24.0743 15.482 19.7783 19.778C15.4823 24.074 8.5183 24.074 4.22231 19.778C-0.0728006 15.482 -0.0728006 8.51798 4.22231 4.22199ZM19.0003 10.5L12.4476 13.7764L12.3241 13.8281C12.1143 13.8999 11.8864 13.8999 11.6765 13.8281L11.5531 13.7764L5.00035 10.5V15.4C5.00035 16.2346 5.63931 16.9199 6.45471 16.9935L6.60035 17H17.4003C18.2349 17 18.9202 16.361 18.9938 15.5456L19.0003 15.4V10.5ZM17.4003 7H6.60035C5.76578 7 5.08046 7.63896 5.00689 8.45437L5.00035 8.6V9.25L11.5531 12.5264L11.6765 12.5781C11.8864 12.6499 12.1143 12.6499 12.3241 12.5781L12.4476 12.5264L19.0003 9.25V8.6C19.0003 7.76544 18.3614 7.08011 17.546 7.00654L17.4003 7Z" fill="#041E42" data-sentry-element="path" data-sentry-source-file="ListJobsPositions.tsx" />
        </svg>
        Napište nám
      </ButtonSecondary>
    </article>;
};