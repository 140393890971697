import { useState } from "react";
import classNames from "classnames";
import { ButtonPrimary } from "@czechtv/components";
import Link from "next/link";
import { ValidatedTextInput, ValidatedTextArea, ValidatedCheckbox, ValidatedSelect, useForm, Form } from "@czechtv/form";
import { MessageSuccess, MessageFailed } from "@czechtv/components";
import { sendForm } from "@utils/form/sendForm";
import { FileInput, MB } from "@components/reusables/Form/inputs/FileInput/FileInput";
import { formContact, formContact_h2, formContactSubHead, formContactOneC, formContactTwoC, formContactSendGroup, formContactCheck, formContactCheck_span, formContactCheck_spanGDPR, formContactCheck_alert, formContactTextArea, formContactTextArea_p, formContactFileField, formContactFileField_label, formContactFileField_p, formContactRadio } from "./ComplaintsAndSuggestionsForm.css";
interface FormValues {
  name: string;
  address: string;
  interestPerson: string;
  subject: string;
  email: string;
  message: string;
  gdpr: string;
  agreePublic: string;
  attachement: null | File[];
}

// zajmy
const interestPerson = ["Fyzická osoba", "Právnická osoba"];
const interestPersonOptions = interestPerson.map((item: string) => {
  return {
    id: item,
    text: item
  };
});

// placeholder pro select zajmy
// (musi byt ve variable protoze je potreba v selectu validovat ze hodnota != placeholder)
const interestPersonPlaceholder = "Vyberte typ stěžovatele / tazatele";
export const ComplaintsAndSuggestionsForm = () => {
  const form = useForm<FormValues>();
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    setValue,
    formState: {
      isSubmitting,
      isValid
    },
    reset
  } = form;

  // prasácké handlování selectu :(
  const [selectedinterestPerson, setSelectedinterestPerson] = useState("");
  const onFormSubmit = async (data: FormValues) => {
    const url = "/vse-o-ct/api/forms/ct-council-complaint-form";
    try {
      await sendForm(url, data);
      resetForm();
    } catch (error: any) {
      console.log(error);
      throw new Error(error.message);
    } finally {
      setDisabled(true);
      setTimeout(() => {
        setDisabled(false);
      }, 10000);
    }
  };
  const resetForm = () => {
    reset();
    setSelectedinterestPerson("");
  };
  return <article className={classNames(formContact)} id="kontaktniFormular" data-sentry-component="ComplaintsAndSuggestionsForm" data-sentry-source-file="ComplaintsAndSuggestionsForm.tsx">
      <h2 className={classNames(formContact_h2)}>Kontaktní formulář</h2>
      <p className={classNames(formContactSubHead)}>
        Máte nějakou stížnost nebo podnět? Napište nám:
      </p>

      <Form errorState={FormError} form={form} successState={FormSuccess} onSubmit={onFormSubmit} data-sentry-element="Form" data-sentry-source-file="ComplaintsAndSuggestionsForm.tsx">
        {!disabled ? <>
            <div className={classNames(formContactTwoC)}>
              <div>
                <ValidatedTextInput {...register("name", {
              required: "Zadejte jméno a příjmení"
            })} autoComplete="name" label={{
              required: true,
              title: "Jméno a příjmení"
            }} maxWidth={2000} showValidationIcon />
              </div>
              <div className={classNames(formContactRadio)}>
                <div>
                  <ValidatedSelect {...register("interestPerson", {
                required: true,
                validate: (value: string) => {
                  return value.trim() != "" && value != interestPersonPlaceholder || "Zadejte typ stěžovatele / tazatele";
                }
              })} displayValidationStatus={true} label={{
                required: true,
                title: "Typ stěžovatele / tazatele"
              }} options={interestPersonOptions} placeholder={interestPersonPlaceholder} selectedOption={selectedinterestPerson} onSelected={(text: string) => {
                setSelectedinterestPerson(text);
                setValue("interestPerson", text, {
                  shouldValidate: true
                });
              }} maxWidth={2000} />
                </div>
              </div>
            </div>
            <div className={classNames(formContactOneC)}>
              <div>
                <ValidatedTextInput {...register("address", {
              required: "Zadejte Vaši adresu trvalého bydliště"
            })} autoComplete="address" label={{
              required: true,
              title: "Adresa trvalého bydliště"
            }} maxWidth={2000} showValidationIcon />
              </div>
            </div>
            <div className={classNames(formContactTwoC)}>
              <div>
                <ValidatedTextInput {...register("email", {
              required: {
                value: true,
                message: "Zadejte e-mail"
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "E-mail, který jste zadali, je ve špatném formátu. Zadejte platný e-mail."
              }
            })} autoComplete="email" label={{
              required: true,
              title: "E-mail"
            }} maxWidth={2000} showValidationIcon />
              </div>
              <div>
                <ValidatedTextInput {...register("subject", {
              required: true
            })} autoComplete="subject" label={{
              required: true,
              title: "Předmět stížnosti / podnětu"
            }} type="text" maxWidth={2000} showValidationIcon={true} />
              </div>
            </div>

            <div className={classNames(formContactTextArea)}>
              <ValidatedTextArea {...register("message", {
            required: {
              value: true,
              message: "Napište Vaši zprávu"
            }
          })} label={{
            required: true,
            title: "Text stížnosti/ podnětu"
          }} maxWidth={2000} />
            </div>
            <div className={classNames(formContactFileField)}>
              <label htmlFor="attachement" className={classNames(formContactFileField_label)}>
                Zde můžete vložit přílohu
              </label>
              <div>
                <FileInput {...register("attachement")} required={false} accept=".doc,.png,.jpg,.jpeg,.docx,.pdf" maxSize={10 * MB} />
              </div>
              <p className={classNames(formContactFileField_p)}>
                Přijímáme všechny přílohy typu JPG, PNG, PDF, DOC, DOCX do velikosti 10 MB.
              </p>
            </div>
            <div className={classNames(formContactSendGroup)}>
              <div className={classNames(formContactCheck)}>
                <ValidatedCheckbox {...register("agreePublic")} item={{
              id: "agreePublic",
              title: ""
            }} />{" "}
                <span className={classNames(formContactCheck_span)}>
                  Zaškrtnutím tohoto políčka SOUHLASÍTE s uvedením Vašeho jména, příjmení a obce
                  Vašeho trvalého bydliště při veřejném projednávání Vaší stížnosti Radou České
                  televize. Pokud souhlas neudělíte (políčko zůstane prázdné), bude Vaše stížnost a
                  odpověď na ni uvedena na webu Rady České televize bez Vašeho jména, přičemž
                  adresně Vám bude odpovězeno pouze písemnou formou. Váš souhlas (zaškrtnutí
                  políčka) není podmínkou projednání Vaší stížnosti.
                </span>
              </div>
            </div>
            <div className={classNames(formContactSendGroup)}>
              <div className={classNames(formContactCheck)}>
                <div className={classNames(formContactCheck_alert)}>
                  <ValidatedCheckbox {...register("gdpr", {
                required: {
                  value: true,
                  message: "Pro odeslání zprávy musíte souhlasit se zpracováním osobních údajů"
                }
              })} item={{
                id: "gdpr",
                title: ""
              }} />{" "}
                </div>
                <span className={classNames(formContactCheck_span, formContactCheck_spanGDPR)}>
                  Souhlasím se{" "}
                  <Link href="./gdpr/" rel="noreferrer" target="_blank">
                    zpracováním osobních údajů
                  </Link>
                </span>
              </div>
            </div>
            <div className={classNames(formContactTextArea)}>
              <p className={classNames(formContactTextArea_p)}>
                Stížnosti Rada ČT vyřizuje v souladu s
                <a href="https://www.ceskatelevize.cz/vse-o-ct/api/media/documents/Korektura_Postup_RCT_pri_vyrizovani_stiznosti_FINAL.pdf" target="_blank" rel="noreferrer">
                  čl. 4 odst. 7 Postupu Rady ČT
                </a>{" "}
                při vyřizování podnětů a stížností adresovaných Radě ČT zpravidla do 60 kalendářních
                dnů ode dne doručení stížnosti; jde-li o případy složitější, lze tuto lhůtu
                překročit.
              </p>
            </div>
            <ButtonPrimary disabled={isSubmitting || !isValid || disabled} type="submit" waiting={isSubmitting} style={{
          maxWidth: "190px",
          width: "100%"
        }}>
              Odeslat
            </ButtonPrimary>
          </> : null}
      </Form>
    </article>;
};
const FormError = ({
  error
}: {
  error: unknown;
}) => <>
    <MessageFailed description={error instanceof Error ? error.message : "Vyskytla se neočekávaná chyba"} data-sentry-element="MessageFailed" data-sentry-source-file="ComplaintsAndSuggestionsForm.tsx" />
    <br />
  </>;
const FormSuccess = () => <>
    <MessageSuccess description={"Formulář byl úspěšně odeslán, budeme Vás kontaktovat na uvedeném e-mailu."} data-sentry-element="MessageSuccess" data-sentry-source-file="ComplaintsAndSuggestionsForm.tsx" />
    <br />
  </>;