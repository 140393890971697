import { PageViewData, PageViewEventType, usePageView } from "@utils/pageView";
interface PageViewDetails {
  data: PageViewData;
  extra?: Record<string, any>;
  loading?: boolean;
  name: PageViewEventType;
}
interface Props {
  pageView: PageViewDetails;
}
export const PageView = ({
  pageView
}: Props) => {
  const {
    name,
    data,
    extra,
    loading
  } = pageView;
  usePageView(name, data, loading, extra);
  return null;
};