import { WithSideMenuLayout } from "@components/core/Layouts/WithSideMenuLayout/WithSideMenuLayout";
import { CtCouncilHomePageLayout } from "./../../Layouts/CtCouncilHomePageLayout/CtCouncilHomePageLayout";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import { StructuredContenBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock";
import { AttachedDocumentsBlock } from "@components/blocks/AttachedDocumentsBlock/AttachedDocumentsBlock";
import classNames from "classnames";
import { componentHeader, componetHeader_H1 } from "./BaseContentPage.css";
const getLayout = (page: any) => {
  switch (page?.customLayout) {
    case "CtCouncilHomePageLayout":
      return CtCouncilHomePageLayout;
    default:
      return WithSideMenuLayout;
  }
};
export const BaseContentPage = ({
  page,
  allPages
}: any) => {
  const Layout = getLayout(page);
  return <Layout page={page} allPages={allPages} data-sentry-element="Layout" data-sentry-component="BaseContentPage" data-sentry-source-file="BaseContentPage.tsx">
      <>
        {/** hlavicka */}
        <div className={classNames(componentHeader)}>
          <h1 className={classNames(componetHeader_H1)}>{page.title}</h1>
          <HTMLBlock HTML={page.perex} data-sentry-element="HTMLBlock" data-sentry-source-file="BaseContentPage.tsx" />
        </div>

        {/** strukturovany obsah */}
        <StructuredContenBlock content={page.structuredContent} data-sentry-element="StructuredContenBlock" data-sentry-source-file="BaseContentPage.tsx" />

        {/** pripojene soubory */}
        {page.attachedDocuments?.length > 0 ? <AttachedDocumentsBlock documents={page.attachedDocuments.map((item: any) => item.value)} /> : null}
      </>
    </Layout>;
};