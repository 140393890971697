import React from "react";
import { useRouter } from "next/router";
import { projectGlobalMenuConfig } from "./projectGlobalMenuConfig";
import { GlobalHeader, GlobalHeaderConfig } from "@czechtv/global-components";
import { CustomLinkComponent, setActiveInProductMenu } from "./utils";
import { header } from "./Header.css";
export const Header = ({
  globalMenuConfig = projectGlobalMenuConfig
}: {
  globalMenuConfig?: GlobalHeaderConfig;
}) => {
  const router = useRouter();
  const updatedGlobalMenuConfig = {
    ...globalMenuConfig,
    productMenu: globalMenuConfig?.productMenu != undefined ? {
      ...globalMenuConfig.productMenu,
      items: setActiveInProductMenu(globalMenuConfig?.productMenu?.items, router.asPath)
    } : undefined,
    CustomLinkComponent: CustomLinkComponent
  };
  return <header className={header} data-sentry-component="Header" data-sentry-source-file="Header.tsx">
      <GlobalHeader {...updatedGlobalMenuConfig} data-sentry-element="GlobalHeader" data-sentry-source-file="Header.tsx" />
    </header>;
};