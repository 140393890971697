import { WithSideMenuLayout } from "@components/webFaq/Layouts/WithSideMenuLayout";
import { StructuredContenBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock";
import { AttachedDocumentsBlock } from "@components/blocks/AttachedDocumentsBlock/AttachedDocumentsBlock";
import { FaqPageContent } from "@components/core/PageTypes/FaqPage/FaqPageContent";
import { FaqContactUsBox } from "@components/webFaq/components/FaqContactUsBox";
import { contentLimit } from "./FaqPage.css";
export const FaqPage = ({
  page,
  allPages
}: any) => {
  return <WithSideMenuLayout page={page} allPages={allPages} data-sentry-element="WithSideMenuLayout" data-sentry-component="FaqPage" data-sentry-source-file="FaqPage.tsx">
      <div className={contentLimit}>
        {/** strukturovany obsah */}
        <StructuredContenBlock content={page.structuredContent} data-sentry-element="StructuredContenBlock" data-sentry-source-file="FaqPage.tsx" />

        {/** FAQs s navigaci */}
        <FaqPageContent page={page} data-sentry-element="FaqPageContent" data-sentry-source-file="FaqPage.tsx" />

        {/** Info box s odkazem */}
        <FaqContactUsBox data-sentry-element="FaqContactUsBox" data-sentry-source-file="FaqPage.tsx" />

        {/** pripojene soubory */}
        {page.attachedDocuments?.length > 0 ? <AttachedDocumentsBlock documents={page.attachedDocuments.map((item: any) => item.value)} /> : null}
      </div>
    </WithSideMenuLayout>;
};