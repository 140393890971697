import { Fragment, createElement } from "react";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import { superTableClassnames } from "./SuperTableBlock.css";
export enum SUPER_TABLE_CELL_CONTENT_TYPE {
  TEXT = "TEXT",
  HTML = "HTML",
  NUMBER = "NUMBER",
}
export interface ISuperTableCell {
  contentType?: SUPER_TABLE_CELL_CONTENT_TYPE;
  content?: string;
}
export type TSuperTableRow = ISuperTableCell[];
export interface ISuperTable {
  data?: TSuperTableRow[];
  caption?: string;
  firstRowIsHeader?: boolean;
  firstColumnIsHeader?: boolean;
}
const DEFAULT_CELL_CONTENT_TYPE: SUPER_TABLE_CELL_CONTENT_TYPE = SUPER_TABLE_CELL_CONTENT_TYPE.TEXT;
const getCellContentType = (cell: ISuperTableCell): SUPER_TABLE_CELL_CONTENT_TYPE => cell?.contentType ? cell.contentType : DEFAULT_CELL_CONTENT_TYPE;
interface CellComponentProps {
  cell: ISuperTableCell;
  asHeader: boolean;
}
export const SuperTableBlock = ({
  data = [],
  caption,
  firstRowIsHeader,
  firstColumnIsHeader
}: ISuperTable) => {
  const headerRow = data?.length && !!firstRowIsHeader ? data[0] : undefined;
  const bodyRows = !!headerRow ? data.slice(1) : data;
  const Cell = (props: CellComponentProps) => {
    switch (getCellContentType(props.cell)) {
      case SUPER_TABLE_CELL_CONTENT_TYPE.HTML:
        return <CellRichText {...props} />;
      case SUPER_TABLE_CELL_CONTENT_TYPE.NUMBER:
        return <CellNumber {...props} />;
    }
    return <CellText {...props} data-sentry-element="CellText" data-sentry-component="Cell" data-sentry-source-file="SuperTableBlock.tsx" />;
  };
  if (!data?.length) return <></>;
  return <>
      <table>
        {/** caption  */}
        {caption ? <caption>{caption}</caption> : null}

        {/** hlavicka */}
        {headerRow ? <thead>
            <tr>
              {headerRow.map((cell, cellIndex) => <th key={`headerCell${cellIndex}`}>{cell.content}</th>)}
            </tr>
          </thead> : null}

        {/** radky  */}
        {bodyRows.length ? <tbody>
            {bodyRows.map((row, rowIndex) => <tr key={`bodyRow${rowIndex}`}>
                {row.map((cell, cellIndex) => <Fragment key={`bodyCell${rowIndex}${cellIndex}`}>
                    <Cell cell={cell} asHeader={!!firstColumnIsHeader && cellIndex == 0} />
                  </Fragment>)}
              </tr>)}
          </tbody> : null}
      </table>
    </>;
};
const CellText = ({
  cell,
  asHeader = false
}: CellComponentProps) => {
  const props = {
    className: superTableClassnames.cellHTML
  };
  return createElement(asHeader ? "th" : "td", props, cell.content);
};
const CellRichText = ({
  cell,
  asHeader = false
}: CellComponentProps) => {
  const props = {
    className: superTableClassnames.cellHTML
  };
  return createElement(asHeader ? "th" : "td", props, <HTMLBlock HTML={cell.content ? cell.content : ""} />);
};
const CellNumber = ({
  cell,
  asHeader = false
}: CellComponentProps) => {
  const props = {
    className: superTableClassnames.cellNumber
  };
  return createElement(asHeader ? "th" : "td", props, cell.content);
};