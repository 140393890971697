import { FaqBlock, TOP_CATEGORY_SLUG } from '@components/blocks/FaqBlock/FaqBlock';
import { headerFaq } from "@components/blocks/StructuredContentBlock/sublocks/FaqWidgetBlock/FaqWidgetBLock.css";
export const FaqWidgetBlock = ({
  value
}: {
  value: any;
}) => {
  return <div data-sentry-component="FaqWidgetBlock" data-sentry-source-file="FaqWidgetBlock.tsx">
      {/** nadpis bloku */}
      {value.title ? <h2 className={headerFaq}>{value.title}DD</h2> : null}

      {/** vypis FAQs */}
      <FaqBlock faqCategories={value.faqs} displayCategorySlug={value.ident ? value.ident : TOP_CATEGORY_SLUG} data-sentry-element="FaqBlock" data-sentry-source-file="FaqWidgetBlock.tsx" />
    </div>;
};