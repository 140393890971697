import React from "react";
import { useState } from "react";
import { ValidatedTextInput, useForm, Form } from "@czechtv/form";
import { ButtonPrimary } from "@czechtvPackagesOverrides/components/ButtonPrimary";
import { MessageSuccess, MessageFailed } from "@czechtv/components";
import { sendForm } from "@utils/form/sendForm";
import classNames from "classnames";
import Link from "next/link";
import { container, container_H2, cardBox, cardBox_p, headerDecoration, doubleBox, doubleBoxItem, linkFullWithArrow, pMargin, flex } from "./PhotoserviceBox.css";

/*
const basicLink = {
  "& a": {
    ...Body18SemiBold,
    color: Blue_base,
    transition: `color ${ButtonTransitionDuration} ${ANIMATION_BEZIER_HOVER}`,
  },
  "& a:hover": {
    color: "#000000",
    transition: `color ${ButtonTransitionDuration} ${ANIMATION_BEZIER_HOVER}`,
  },
};

const useStyles = createUseStyles({
  container: {
    marginBottom: `${GRID_GAP * 6}px`,
    [mediaMinTablet640]: {
      marginBottom: `${GRID_GAP * 8}px`,
    },
    "& h2": {
      ...HeaderH3,
      marginBottom: `${GRID_GAP * 2}px`,
    },
    ...basicLink,
    ...Body18Medium,
  },
  cardBox: {
    ...cardBox,
    "& p": {
      flex: `1`,
      margin: `0`,
    },
  },
  headerDecoration: headerDecoration,
  doubleBox: {
    display: "flex",
    justifyContent: "space-between",
    gap: `${GRID_GAP * 3}px`,
    marginBottom: `${GRID_GAP * 3}px`,
    flexWrap: `wrap`,
    ...basicLink,
    [mediaMinTablet640]: {
      flexWrap: `nowrap`,
    },
    "&:last-child": {
      marginBottom: `${GRID_GAP * 0}px`,
    },
  },
  doubleBoxItem: {
    flex: "auto",
    width: `100%`,
    [mediaMinTablet640]: {
      flex: "1",
      width: `auto`,
    },
  },
  linkArrow: {
    "& a": linkFullWithArrow,
  },
  pMargin: {
    marginBottom: `${GRID_GAP * 3}px !important`,
  },
  flex: {
    flex: 1,
  },
});
*/
interface FormValues {
  email: string;
  name: string;
  medium: string;
}
export const PhotoserviceBox = () => {
  //const classes = useStyles();
  const form = useForm<FormValues>();
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    formState: {
      isSubmitting,
      isValid
    },
    reset
  } = form;
  const onFormSubmit = async (data: FormValues) => {
    const url = "/vse-o-ct/api/forms/photo-service-request-form";
    try {
      await sendForm(url, data);
      resetForm();
    } catch (error: any) {
      console.log(error);
      throw new Error(error.message);
    } finally {
      setDisabled(true);
      setTimeout(() => {
        setDisabled(false);
      }, 10000);
    }
  };
  const resetForm = () => {
    reset();
  };
  return <div className={classNames(container)} data-sentry-component="PhotoserviceBox" data-sentry-source-file="PhotoserviceBox.tsx">
      <div className={classNames(doubleBox)}>
        <div className={classNames(doubleBoxItem, cardBox)}>
          <h2 className={classNames(container_H2)}>Aktuální fotografie k programu</h2>
          <div className={classNames(headerDecoration)}></div>
          <div className={classNames(flex)}></div>
          <div>
            <Link href="https://ctcloud1.czech-tv.cz/s/094jj0gXCoELd4C" target="_blank" className={classNames(linkFullWithArrow)} data-sentry-element="Link" data-sentry-source-file="PhotoserviceBox.tsx">
              Zobrazit více
            </Link>
          </div>
        </div>
        <div className={classNames(doubleBoxItem, cardBox)}>
          <h2 className={classNames(container_H2)}>Fotobanka</h2>
          <div className={classNames(headerDecoration)}></div>
          <div className={classNames(flex)}></div>
          <div>
            <Link href="https://fotobanka.ceskatelevize.cz/" className={classNames(linkFullWithArrow)} data-sentry-element="Link" data-sentry-source-file="PhotoserviceBox.tsx">
              Zobrazit více
            </Link>
          </div>
        </div>
        <div className={classNames(doubleBoxItem, cardBox)}>
          <h2 className={classNames(container_H2)}>Loga ke stažení</h2>
          <div className={classNames(headerDecoration)}></div>
          <div className={classNames(flex)}></div>
          <div>
            <Link href="./pro-media/loga-ceske-televize-ke-stazeni/" className={classNames(linkFullWithArrow)} data-sentry-element="Link" data-sentry-source-file="PhotoserviceBox.tsx">
              Zobrazit více
            </Link>
          </div>
        </div>
      </div>
      <div className={classNames(doubleBox)}>
        <div className={classNames(doubleBoxItem, cardBox)}>
          <h2 className={classNames(container_H2)}>
            Žádost o přístup k aktuálním fotografiím k programu
          </h2>
          <div className={classNames(headerDecoration)}></div>
          <p className={classNames(cardBox_p, pMargin)}>
            Jste novinář, který informuje o programu České televize? Vyžádejte si heslo do fotobanky
            k aktuálním pořadům.
          </p>
          <Form errorState={FormError} form={form} successState={FormSuccess} onSubmit={onFormSubmit} data-sentry-element="Form" data-sentry-source-file="PhotoserviceBox.tsx">
            {!disabled ? <>
                <ValidatedTextInput {...register("name", {
              required: "Zadejte jméno a příjmení"
            })} autoComplete="name" label={{
              required: true,
              title: "Jméno a příjmení"
            }} maxWidth={2000} showValidationIcon />
                <ValidatedTextInput {...register("medium", {
              required: "Zadejte název média"
            })} autoComplete="medium" label={{
              required: true,
              title: "Médium"
            }} maxWidth={2000} showValidationIcon />
                <ValidatedTextInput {...register("email", {
              required: {
                value: true,
                message: "Zadejte e-mail"
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "E-mail, který jste zadali, je ve špatném formátu. Zadejte platný e-mail."
              }
            })} autoComplete="email" label={{
              required: true,
              title: "E-mail"
            }} maxWidth={2000} showValidationIcon />

                <ButtonPrimary disabled={isSubmitting || !isValid || disabled} type="submit" waiting={isSubmitting}>
                  Odeslat
                </ButtonPrimary>
              </> : null}
          </Form>
        </div>
      </div>
      <div className={classNames(doubleBox)}>
        <div className={classNames(doubleBoxItem, cardBox)}>
          <h2 className={classNames(container_H2)}>Nenašli jste vhodnou fotografii? </h2>
          <div className={classNames(headerDecoration)}></div>
          <p>
            Kontaktujte fotoservis České televize na e-mailu{" "}
            <a href="mailto:pavel.kral@ceskatelevize.cz">pavel.kral@ceskatelevize.cz</a>.
          </p>
        </div>
      </div>
    </div>;
};
const FormError = ({
  error
}: {
  error: unknown;
}) => <>
    <MessageFailed description={error instanceof Error ? error.message : "Vyskytla se neočekávaná chyba"} data-sentry-element="MessageFailed" data-sentry-source-file="PhotoserviceBox.tsx" />
    <br />
  </>;
const FormSuccess = () => <>
    <MessageSuccess description={"Formulář byl úspěšně odeslán, budeme Vás kontaktovat na uvedeném e-mailu."} data-sentry-element="MessageSuccess" data-sentry-source-file="PhotoserviceBox.tsx" />
    <br />
  </>;