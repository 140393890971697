// svět je někdy temné a drsné místo
// IntersectionObserver je sice defaultně zapnutý u 95% uživatelů Safari, u některých je ale
// záhadně tato experimentální funkce vypnutá, tak potřebujeme polyfill
import "intersection-observer";
import type { AppProps } from "next/app";
import { useEffect, useState, useCallback } from "react";
import { useRouter } from "next/router";
import dayjs from "dayjs";
import cs from "dayjs/locale/cs";
import { ResponsiveContextProvider } from "@czechtv/styles";
import { setOnCookieConsentChanged } from "@utils/setOnCookieConsentChanged";
import { SmartTableProvider } from "components/reusables/SmartTable/SmartTableProvider";
import { GemiusProvider } from "@components/Analytics/Gemius";
import { AnalyticsContextProvider } from "@czechtv/analytics-react";
import { gtmSubscriber } from "@czechtv/analytics";
import { snakeCase } from "snake-case";

// normalizace
import "normalize.css";
import "@czechtv/styles/dist/styles.css";
import "@czechtv/styles/dist/css/fonts.css";
import "@czechtv/components/dist/components.css";
import "@czechtv/form/dist/form.css";
import "@czechtv/player/dist/all.css";
import "@czechtv/global-components/dist/all.css";
import "@czechtvPackagesOverrides/styles/vanilla/global/global.css";
import "@czechtvPackagesOverrides/styles/vanilla/czechTvPackTheme/czechTvPackTheme.css";

// globalni styly
import "@czechtvPackagesOverrides/styles/globals/index";

// dayjs init
dayjs.locale(cs);

// subscribery pro analytiku
const analyticsSubscribers = [gtmSubscriber()];

// custom AppProps (pripraveno na rozsirovani)
interface CustomAppProps extends AppProps {
  // error predavame dal do komponenty https://github.com/vercel/next.js/issues/8592
}
export default function App({
  Component,
  pageProps
}: CustomAppProps) {
  const router = useRouter();
  const [shouldReload, setShouldReload] = useState(false);
  const [wasHydrated, setWasHydrated] = useState(false);

  // styly

  // route change start
  const handleRouteChangeStart = useCallback((url: any) => {
    const [path] = url.split("?");
    // po zmene cookie consentu potrebujeme pri nasledujici navigaci reload stranky
    // děje se pouze při změně path, viz. IVYS-1889
    if (shouldReload && path !== window.location.pathname) {
      window.location.assign(url);
      return;
    }
  }, [shouldReload]);

  // route change complete
  const handleRouteChangeComplete = useCallback(async () => {}, []);

  // route change error
  const handleRouteChangeError = useCallback(async (e: any, url: any) => {
    console.log(e, {
      contexts: {
        data: {
          url
        }
      }
    });
  }, []);

  // inject cookie alert
  const injectCookieAlert = useCallback(() => {
    setOnCookieConsentChanged(() => {
      setShouldReload(true);
    });
  }, []);

  // mount
  useEffect(() => {
    // registrace router eventu
    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);
    router.events.on("routeChangeError", handleRouteChangeError);

    // odstraníme ssr css, protože se nahradí tím, co si mountnout komponenty
    const style = document.getElementById("server-side-styles");
    if (style && style.parentNode) {
      style.parentNode.removeChild(style);
    }

    // inject cookie alert
    injectCookieAlert();

    // nastaveni hydratace
    setWasHydrated(true);

    // unmount
    // v produkci se unmount reálně nikde nezavolá
    // ale při vývoji ano
    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
      router.events.off("routeChangeError", handleRouteChangeError);
    };
  }, [handleRouteChangeComplete, handleRouteChangeError, handleRouteChangeStart, injectCookieAlert, router.events]);
  return <ResponsiveContextProvider value={{
    wasHydrated
  }} data-sentry-element="ResponsiveContextProvider" data-sentry-component="App" data-sentry-source-file="_app.tsx">
      <AnalyticsContextProvider subscribers={analyticsSubscribers} data-sentry-element="AnalyticsContextProvider" data-sentry-source-file="_app.tsx">
        <SmartTableProvider data-sentry-element="SmartTableProvider" data-sentry-source-file="_app.tsx">
          <GemiusProvider data-sentry-element="GemiusProvider" data-sentry-source-file="_app.tsx">
            <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
          </GemiusProvider>
        </SmartTableProvider>
      </AnalyticsContextProvider>
    </ResponsiveContextProvider>;
}