import React from "react";
import classNames from "classnames";
import { LayoutProps } from "@components/core/Layouts";
import { Header } from "@components/layouts/components/Header/Header";
import { BreadcrumbNavigation } from "@components/layouts/components/BreadcrumbNavigation/BreadcrumbNavigation";
import { Content } from "@czechtv/components";
import { CTGlobalFooter } from "@components/layouts/components/CTGlobalFooter/CTGlobalFooter";
import { createBreadcrumbs } from "@utils/pages";
import { containerMain, containerContent } from "./WithoutSideMenuLayout.css";
export const WithoutSideMenuLayout: React.FC<LayoutProps> = ({
  page,
  allPages,
  children
}) => {
  return <>
      <Header data-sentry-element="Header" data-sentry-source-file="WithoutSideMenuLayout.tsx" />
      <Content data-sentry-element="Content" data-sentry-source-file="WithoutSideMenuLayout.tsx">
        {allPages ? <BreadcrumbNavigation items={createBreadcrumbs(page, allPages)} /> : null}
        <div className={classNames(containerMain)}>
          <div className={classNames(containerContent)}>{children ? children : null}</div>
        </div>
      </Content>
      <CTGlobalFooter data-sentry-element="CTGlobalFooter" data-sentry-source-file="WithoutSideMenuLayout.tsx" />
    </>;
};