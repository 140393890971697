import { LayoutProps } from "@components/core/Layouts";
import classNames from "classnames";
import { WrapperPositions } from "@czechtv/global-components";
import { projectGlobalMenuConfig } from "@components/layouts/components/Header/projectGlobalMenuConfig";
import { Header } from "@components/layouts/components/Header/Header";
import { Content } from "@czechtv/components";
import { CTGlobalFooter } from "@components/layouts/components/CTGlobalFooter/CTGlobalFooter";
import { SmartImage } from "@components/reusables/SmartImage/SmartImage";
import { BreadcrumbNavigation } from "@components/layouts/components/BreadcrumbNavigation/BreadcrumbNavigation";
import { createBreadcrumbs } from "@utils/pages";
import { Blue_base } from "@czechtvPackagesOverrides/styles/fromCTV/index";
import { RulesForComplaintsContactBox } from "./RulesForComplaintsContactBox/RulesForComplaintsContactBox";
import { componetHeaderWithImage, componetHeaderWithImageBackgroundWrap, componetHeaderWithImageTxt, componetHeaderWithImageTxt_h1 } from "@czechtvPackagesOverrides/styles/vanilla/components/headerWithImage/headerWithImage.css";
import { sectionMargin } from "./RulesForComplaintsLayout.css";
import imageBack from "./background.jpg";
const globalMenuConfig = {
  ...projectGlobalMenuConfig,
  defaultPosition: WrapperPositions.absolute,
  style: {
    default: {
      dark: true,
      backgroundColor: "transparent"
    },
    floating: {
      dark: true,
      backgroundColor: Blue_base
    }
  }
};
export const RulesForComplaintsLayout: React.FC<LayoutProps> = ({
  page,
  children,
  allPages
}) => {
  return <>
      <Header globalMenuConfig={globalMenuConfig} data-sentry-element="Header" data-sentry-source-file="RulesForComplaintsLayout.tsx" />
      <main>
        <header className={classNames(componetHeaderWithImage)}>
          {/** custom obrazek na pozadi hlavicky */}
          <div className={classNames(componetHeaderWithImageBackgroundWrap)}>
            <SmartImage src={imageBack.src} alt={page.title} width={3200} height={960} bigImage={true} data-sentry-element="SmartImage" data-sentry-source-file="RulesForComplaintsLayout.tsx" />
          </div>
          <Content data-sentry-element="Content" data-sentry-source-file="RulesForComplaintsLayout.tsx">
            <div className={classNames(componetHeaderWithImageTxt)}>
              <BreadcrumbNavigation items={createBreadcrumbs(page, allPages)} darkMode={true} data-sentry-element="BreadcrumbNavigation" data-sentry-source-file="RulesForComplaintsLayout.tsx" />
              {/** title */}
              <h1 className={classNames(componetHeaderWithImageTxt_h1)}>
                Pravidla pro vyřizování podání, včetně stížností diváků
              </h1>
            </div>
          </Content>
        </header>
        <section className={classNames(sectionMargin)}>
          <Content data-sentry-element="Content" data-sentry-source-file="RulesForComplaintsLayout.tsx">{children ? children : null}</Content>
        </section>
        <Content data-sentry-element="Content" data-sentry-source-file="RulesForComplaintsLayout.tsx">
          <RulesForComplaintsContactBox data-sentry-element="RulesForComplaintsContactBox" data-sentry-source-file="RulesForComplaintsLayout.tsx" />
        </Content>
      </main>
      <CTGlobalFooter data-sentry-element="CTGlobalFooter" data-sentry-source-file="RulesForComplaintsLayout.tsx" />
    </>;
};