import { ButtonPrimary as ButtonPrimaryOriginal } from "@czechtv/components";
export const ButtonPrimary = (props: any) => {
  let updatedProps = {
    ...props
  };
  if (!!props?.href) {
    const hrefParts = props.href.split("/");
    if (hrefParts[0] == "" && hrefParts[1] != "vse-o-ct") {
      updatedProps.href = `/vse-o-ct${props.href}`;
    }
  }
  return <ButtonPrimaryOriginal {...updatedProps} styleType="primary" data-sentry-element="ButtonPrimaryOriginal" data-sentry-component="ButtonPrimary" data-sentry-source-file="ButtonPrimary.tsx" />;
};