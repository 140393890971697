import { TLinkStyle } from "@components/blocks/Link/Link";
import classNames from "classnames";
import { contentBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock.css";
import { BannerCardBanner, BannerCardImage, BannerCardLight, BannerCardParagraph, BannerCardRight, BannerCardTitle, BannerCardTxt } from "./BannerCardBlock.css";
import { SmartImage } from "@components/reusables/SmartImage/SmartImage";
import { ButtonPrimary } from "@czechtvPackagesOverrides/components/ButtonPrimary";
import { ASPECT_RATIO } from "@consts/aspectRatio";
export type Link = {
  url: string;
  title: string;
  target?: string;
  type?: TLinkStyle;
};
export type Image = {
  downloadUrl: string;
  title: string;
  width: number;
  height: number;
};
export type TBannerCard = {
  title: string;
  image: Image;
  imagePosition: "LEFT" | "RIGHT";
  displayMode: "DARK" | "LIGHT";
  text?: string;
  link?: Link;
};
interface BannerCardProps {
  value: TBannerCard;
}
export const BannerCardBlock: React.FC<BannerCardProps> = ({
  value
}) => {
  return <article className={classNames(contentBlock, BannerCardBanner, value.imagePosition === "RIGHT" ? BannerCardRight : "", value.displayMode === "LIGHT" ? BannerCardLight + " lightHelper" : "")} data-sentry-component="BannerCardBlock" data-sentry-source-file="BannerCardBlock.tsx">
      {value.image && value.image.downloadUrl && value.image.width && value.image.height && <div className={classNames(BannerCardImage)}>
          <SmartImage cropAspectRatio={ASPECT_RATIO.HORIZONTAL_16_9} src={value.image.downloadUrl} alt={"Pro média"} width={value.image.width} height={value.image.height} />
        </div>}
      <div className={classNames(BannerCardTxt)}>
        <h2 className={classNames(BannerCardTitle)}>{value?.title}</h2>
        {value?.text && <div className={classNames(BannerCardParagraph)}>{value?.text}</div>}
        {value?.link && value?.link?.url && value?.link?.title && <div>
            <ButtonPrimary anchor href={value.link?.url}>
              {value.link?.title}
            </ButtonPrimary>
          </div>}
      </div>
    </article>;
};