import { Header } from '@components/layouts/components/Header/Header';
import Link from 'next/link';
import { Content } from '@czechtv/components';
import { BreadcrumbNavigation } from '@components/layouts/components/BreadcrumbNavigation/BreadcrumbNavigation';
import { createBreadcrumbs } from '@utils/pages';
import classNames from 'classnames';
import { CTGlobalFooter } from '@components/layouts/components/CTGlobalFooter/CTGlobalFooter';
import { TopItems, LetterSwitcher } from "@components/core/PageTypes/DictionaryPage/DictionaryPage";
import { pageHtmlUrl2path } from '@utils/pages';
import { StructuredContenBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock";
import { AttachedDocumentsBlock } from "@components/blocks/AttachedDocumentsBlock/AttachedDocumentsBlock";
import { cardBox, cardBoxClose, componentHeader, containerMain, dictionaryHeader, headerDecoration, letterSwitcher } from "@components/core/PageTypes/DictionaryItemPage/DictionaryItemPage.css";
export const DictionaryItemPage = ({
  page,
  allPages
}: any) => {
  const getDictionaryUrl = () => !!page.meta.parent ? pageHtmlUrl2path(page.meta.parent.meta.htmlUrl) : undefined;
  const backLetter = page.title.charAt(0).toLowerCase();
  return <>
    <Header data-sentry-element="Header" data-sentry-source-file="DictionaryItemPage.tsx" />
    <Content data-sentry-element="Content" data-sentry-source-file="DictionaryItemPage.tsx">
      {allPages ? <BreadcrumbNavigation items={createBreadcrumbs(page, allPages)} /> : null}
      <div className={classNames(containerMain)}>

          {/** prepinac zobrazeni */}
          {!!getDictionaryUrl() ? <div className={classNames(letterSwitcher)}>
              <LetterSwitcher dictionaryUrl={getDictionaryUrl()} activeLetter={backLetter} />
            </div> : null}
          {/** hlavicka
           <div className={classNames(componentHeader)}>
            <h1>Slovníček</h1>
            <div>
              <div>
                <p>Slovník a vysětlení jednotlivých pojmů</p>
              </div>
            </div>
           </div>
           */}
          <article className={classNames(cardBox)}>
            <Link rel="stylesheet" href={`${getDictionaryUrl()}/#${backLetter}`} className={classNames(cardBoxClose)} data-sentry-element="Link" data-sentry-source-file="DictionaryItemPage.tsx">

            </Link>
            <h3 className={classNames(dictionaryHeader)}>{page.title}</h3>
            <div className={classNames(headerDecoration)}></div>
            {/** strukturovany obsah */}
            <StructuredContenBlock content={page.structuredContent} data-sentry-element="StructuredContenBlock" data-sentry-source-file="DictionaryItemPage.tsx" />

            {/** pripojene soubory */}
            {page.attachedDocuments?.length > 0 ? <AttachedDocumentsBlock documents={page.attachedDocuments.map((item: any) => item.value)} /> : null}
          </article>
          <TopItems items={page.topSiblingsItems} data-sentry-element="TopItems" data-sentry-source-file="DictionaryItemPage.tsx" />
        </div>
    </Content>
    <CTGlobalFooter data-sentry-element="CTGlobalFooter" data-sentry-source-file="DictionaryItemPage.tsx" />
  </>;
};