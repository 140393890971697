import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fglobal%2Fglobal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42WTW%2FbMAyG7%2FsVPK6AUoiULNnabbcdBgzLsOvgJE7qLl91kjbtsP8%2BMLGU1lTXIrEO8iO%2BIl%2BJSeg2mz38%2BQAwGv3CatXd1LUOMN4cumkzrte7b91m9L1ZHJZ1p2BXr3ejXdO1808vVuBwxbhZtZ83y9nrS2i45Mu%2BXrbT1xeYAD9%2BMjyedk2zfntTdrDg%2F%2FspBvTXZtYeVq%2FzLkC3mNQfK6cAnVZARaEAr15Svqe04s%2Fwbdm%2F5aXxGTJVZIxXVCrjBFE%2Fi4LOZHQmPVF4RdoprEgg0x4xhYISFWDlZTazHkJyCssyo9REwlkui1GAJclA87hly1IaFZhM8RYpMaOACPthiN0kjKJcaSTWJoxjnIRtBrsN8HLi95sGLGPSFW%2BRj0KmdqsIWT4wp8JIaB0hXShgTktm0zPOKSAFVhLbFEXB%2BTsk7mJOxJU1vO0iE6iLgcqCEyPGMm7uUmqclreMZUT38cZwCOsVkM2U4BAPIqd%2F8txkPL%2BPCXgFpxtYZkr%2B8B7o%2BKxYp5OhM4V47CGrjFZOnoCnGOTkGmWSR31BkLNyGQZTtwCyCnLlQUpXh%2BucBsGZZDEjaRCcTYeX1S7DkCvSzea3l2HIuXQYLtcsYyDGzoh4qkgahlzskXyv4yOg2CQLryA%2BAop90pQK4iOgSSobF%2Bz8CGiaIEq1zQWbJa8oeZXjmnfGmyevit4myp1WXCSvimiTz3E37%2BTadEd88qrKcLc95%2F3ZpypXvNhPuZOwTy7nVWyphs4%2B5foJppbqzj6Z3N7XAcrtcTC5CYBOzG4DkBWzdwEMidkugNVidhfASrV9gEKqHQI4qXYfwEu1hwClVDsGKKXaY4BKqj0F7mzDadIBEIUeYQAkIUjE00KRTAA0QpJsALRSsgiAhZR0bImU9DwtJcsA6KVkFQBLKVkHwEpKTgKQlpLTANPDpJ2OJs1T23T83%2B0alb42ou3TjHPRq91gOv0Lev5br6%2FpCvT2CLg9Am2PIJXn%2BXXFZZ3JrlsEkEnfZA4%2BtbmDT7cB%2FNnAv%2F8Achf%2FrxAMAAA%3D%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2Fblocks%2FAttachedDocumentsBlock%2FAttachedDocumentsBlock.css.tsx.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA61U247aMBR85yusXVUilYxygQDmpU%2F9jZWTnIC7jh3ZZtm0Quq39NP6JVUcJ82FCFbaSCCMJzNzjsdn9RJs2L7KlY9%2BLRAqqDoyQZBfvttPSnm6fKNqifFLsC%2FUidJAeOgrWnv1%2FmFxXaxahsAxvOMLy8yJoF1sIQhxpg3WpuKATVUCQUIKOPTl6kVJs4yJo131aENLmzFdcloRlHOwnD%2FO2rC8wqkUBoQhSJc0BZyAuQCIw41apmU4e53wbLWhV%2BMSqTJQWNGMnfV9NE1fj0qeRYZTyaUi6Pm7ferNugp8UbQkqP4eFBzZgl0Po7jx2NXftK4HX1t4LoXBmv0EgoJVEG4UFE3nBeATsOPJ1Bvt3xad04Lxioz74rX%2BCArqn878EFV5Aw%2BbiYfb%2BtH2AQe%2BNysbbL3u5YtjFVIVlH%2FgwHu%2BY%2Bv7ttTeG%2FQ94TJ97aUFq0a%2BzZDUzDApCFLAqWFv43z3VQlJIJcKnLoL8NPf33%2Be7pX%2BX4cmWvKzsTrOCw73jRsXnrUz1x5A6NZGlp3x%2BWBt%2BzkMfP%2FL4aF7aKOtDVVmwLb7%2FIi0L81exM2tazsB9lr%2B7NunNv6tgIxRpFMFIBAVGVoWTLSjLa5b69mSVsMJ%2BoEZuusm0LXPEzqeR8bS5%2B9Fth%2BDCSVkM6NGPiPnc3pFBrDNuC0TRDwmYsKGouObXLogvtG34bW662vrYG3AI3cWrVPMITczcZmLPYis0bn%2BA1o%2BmlZYBwAA%22%7D"
export var listWrap = '_15i9yfr0';
export var listWrapCont = '_15i9yfr4';
export var listWrapCont_p = '_15i9yfr5';
export var listWrapCont_span = '_15i9yfr6';
export var listWrapIcon = '_15i9yfr3';
export var listWrapLink = '_15i9yfr7';
export var listWrapLink_a = '_15i9yfr8';
export var listWrap_li = '_15i9yfr2';
export var listWrap_ul = '_15i9yfr1';