/*
Struktura dat:
{
  title: string,
  text: string | "",
  type: "INFO" | "ALERT"
}
*/

import { componentHeaderWarningMessage, componentHeaderWarningMessageIcon, componentHeaderWarningMessageText } from "./AlertBlock.css";
import { SmartImage } from "@components/reusables/SmartImage/SmartImage";
import alertIcon from "./icons/alert.svg";
import infoIcon from "./icons/info.svg";
import React from "react";
export const AlertBlock = ({
  value
}: {
  value: any;
}) => {
  //console.log("AlertBlock", value);
  return <>
      {value.title && value.text && value.type ? <div className={`WarningHelper ${componentHeaderWarningMessage}`}>
          <div className={componentHeaderWarningMessageIcon}>
            {value.type === "ALERT" ? <SmartImage src={alertIcon.src} alt="Chyba" width={alertIcon.width} height={alertIcon.height} /> : <SmartImage src={infoIcon.src} alt="Upozornění" width={infoIcon.width} height={infoIcon.height} />}
          </div>
          <div className={componentHeaderWarningMessageText}>
            {value.title && <strong>{value.title}</strong>} {value.text && value.text}
          </div>
        </div> : null}
    </>;
};