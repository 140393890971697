import type { ReactSVGProps } from "@components/reusables/SVGImage/SVGImage";

// globalni ikony CT - import - BEGIN
import { IconArrowRightCircle } from "@czechtv/icons";
import { IconArrowRight } from "@czechtv/icons";
import { IconArrowLeft } from "@czechtv/icons";
import { IconArrowUp } from "@czechtv/icons";
import { IconArrowDown } from "@czechtv/icons";
import { IconArrowRightSvg } from "@czechtv/icons";
import { IconBookmark } from "@czechtv/icons";
import { IconCalendar } from "@czechtv/icons";
import { IconCheckMark } from "@czechtv/icons";
import { IconCheckMarkCircle } from "@czechtv/icons";
import { IconChevronDown } from "@czechtv/icons";
import { IconChevronLeft } from "@czechtv/icons";
import { IconChevronRight } from "@czechtv/icons";
import { IconChevronUp } from "@czechtv/icons";
import { IconChromecast } from "@czechtv/icons";
import { IconAirplay } from "@czechtv/icons";
import { IconChromecastActive } from "@czechtv/icons";
import { IconClose } from "@czechtv/icons";
import { IconDownload } from "@czechtv/icons";
import { IconErrorTriangle } from "@czechtv/icons";
import { IconErrorCircle } from "@czechtv/icons";
import { IconEtc } from "@czechtv/icons";
import { IconFilter } from "@czechtv/icons";
import { IconFullscreen } from "@czechtv/icons";
import { IconFullscreenExit } from "@czechtv/icons";
import { IconHeadphones } from "@czechtv/icons";
import { IconHearth } from "@czechtv/icons";
import { IconHearthOutline } from "@czechtv/icons";
import { IconHelpCircle } from "@czechtv/icons";
import { IconInfoCircle } from "@czechtv/icons";
import { IconInstagram } from "@czechtv/icons";
import { IconList } from "@czechtv/icons";
import { IconLogin } from "@czechtv/icons";
import { IconMenu } from "@czechtv/icons";
import { IconNewWindow } from "@czechtv/icons";
import { IconNothing } from "@czechtv/icons";
import { IconNotification } from "@czechtv/icons";
import { IconPause } from "@czechtv/icons";
import { IconPlay } from "@czechtv/icons";
import { IconPlayCrossed } from "@czechtv/icons";
import { IconPlayableInFuture } from "@czechtv/icons";
import { IconPlayableNow } from "@czechtv/icons";
import { IconProfilePicture } from "@czechtv/icons";
import { IconRefresh } from "@czechtv/icons";
import { IconReportError } from "@czechtv/icons";
import { IconRewind } from "@czechtv/icons";
import { IconSearch } from "@czechtv/icons";
import { IconSeekBack } from "@czechtv/icons";
import { IconSeekForward } from "@czechtv/icons";
import { IconSettings } from "@czechtv/icons";
import { IconShare } from "@czechtv/icons";
import { IconSortBy } from "@czechtv/icons";
import { IconSoundHigh } from "@czechtv/icons";
import { IconSoundLow } from "@czechtv/icons";
import { IconSoundOff } from "@czechtv/icons";
import { IconSpinner } from "@czechtv/icons";
import { IconStar4Points } from "@czechtv/icons";
import { IconStar5Points } from "@czechtv/icons";
import { IconTime } from "@czechtv/icons";
import { IconTrash } from "@czechtv/icons";
import { IconXTwitter } from "@czechtv/icons";
import { IconVideoGallery } from "@czechtv/icons";
import { IconYouTube } from "@czechtv/icons";
import { IconFacebook } from "@czechtv/icons";
import { IconFacebookHoverWhite } from "@czechtv/icons";
import { IconFacebookHoverWhiteColor } from "@czechtv/icons";
import { IconInstagramHoverWhite } from "@czechtv/icons";
import { IconInstagramHoverWhiteColor } from "@czechtv/icons";
import { IconXTwitterHoverWhite } from "@czechtv/icons";
import { IconXTwitterHoverWhiteColor } from "@czechtv/icons";
import { IconTikTok } from "@czechtv/icons";
import { IconTikTokHoverWhite } from "@czechtv/icons";
import { IconSpotify } from "@czechtv/icons";
import { IconSpotifyHoverWhite } from "@czechtv/icons";
import { IconRedButton } from "@czechtv/icons";
import { IconKeypad8 } from "@czechtv/icons";
import { IconLock } from "@czechtv/icons";
import { IconVisibilityCrossed } from "@czechtv/icons";
import { IconVisibility } from "@czechtv/icons";
import { IconSignOut } from "@czechtv/icons";
import { IconCTBeanDark } from "@czechtv/icons";
import { IconEmailCircle } from "@czechtv/icons";
import { IconProfile } from "@czechtv/icons";
import { IconBin } from "@czechtv/icons";
// globalni ikony CT - import - END

type IconProps = Omit<ReactSVGProps, "src">;

// globalni ikony CT - jako objekt pro pristup pres jmeno - BEGIN
const icons = {
  IconArrowRightCircle: IconArrowRightCircle,
  IconArrowRight: IconArrowRight,
  IconArrowLeft: IconArrowLeft,
  IconArrowUp: IconArrowUp,
  IconArrowDown: IconArrowDown,
  IconArrowRightSvg: IconArrowRightSvg,
  IconBookmark: IconBookmark,
  IconCalendar: IconCalendar,
  IconCheckMark: IconCheckMark,
  IconCheckMarkCircle: IconCheckMarkCircle,
  IconChevronDown: IconChevronDown,
  IconChevronLeft: IconChevronLeft,
  IconChevronRight: IconChevronRight,
  IconChevronUp: IconChevronUp,
  IconChromecast: IconChromecast,
  IconAirplay: IconAirplay,
  IconChromecastActive: IconChromecastActive,
  IconClose: IconClose,
  IconDownload: IconDownload,
  IconErrorTriangle: IconErrorTriangle,
  IconErrorCircle: IconErrorCircle,
  IconEtc: IconEtc,
  IconFilter: IconFilter,
  IconFullscreen: IconFullscreen,
  IconFullscreenExit: IconFullscreenExit,
  IconHeadphones: IconHeadphones,
  IconHearth: IconHearth,
  IconHearthOutline: IconHearthOutline,
  IconHelpCircle: IconHelpCircle,
  IconInfoCircle: IconInfoCircle,
  IconInstagram: IconInstagram,
  IconList: IconList,
  IconLogin: IconLogin,
  IconMenu: IconMenu,
  IconNewWindow: IconNewWindow,
  IconNothing: IconNothing,
  IconNotification: IconNotification,
  IconPause: IconPause,
  IconPlay: IconPlay,
  IconPlayCrossed: IconPlayCrossed,
  IconPlayableInFuture: IconPlayableInFuture,
  IconPlayableNow: IconPlayableNow,
  IconProfilePicture: IconProfilePicture,
  IconRefresh: IconRefresh,
  IconReportError: IconReportError,
  IconRewind: IconRewind,
  IconSearch: IconSearch,
  IconSeekBack: IconSeekBack,
  IconSeekForward: IconSeekForward,
  IconSettings: IconSettings,
  IconShare: IconShare,
  IconSortBy: IconSortBy,
  IconSoundHigh: IconSoundHigh,
  IconSoundLow: IconSoundLow,
  IconSoundOff: IconSoundOff,
  IconSpinner: IconSpinner,
  IconStar4Points: IconStar4Points,
  IconStar5Points: IconStar5Points,
  IconTime: IconTime,
  IconTrash: IconTrash,
  IconXTwitter: IconXTwitter,
  IconVideoGallery: IconVideoGallery,
  IconYouTube: IconYouTube,
  IconFacebook: IconFacebook,
  IconFacebookHoverWhite: IconFacebookHoverWhite,
  IconFacebookHoverWhiteColor: IconFacebookHoverWhiteColor,
  IconInstagramHoverWhite: IconInstagramHoverWhite,
  IconInstagramHoverWhiteColor: IconInstagramHoverWhiteColor,
  IconXTwitterHoverWhite: IconXTwitterHoverWhite,
  IconXTwitterHoverWhiteColor: IconXTwitterHoverWhiteColor,
  IconTikTok: IconTikTok,
  IconTikTokHoverWhite: IconTikTokHoverWhite,
  IconSpotify: IconSpotify,
  IconSpotifyHoverWhite: IconSpotifyHoverWhite,
  IconRedButton: IconRedButton,
  IconKeypad8: IconKeypad8,
  IconLock: IconLock,
  IconVisibilityCrossed: IconVisibilityCrossed,
  IconVisibility: IconVisibility,
  IconSignOut: IconSignOut,
  IconCTBeanDark: IconCTBeanDark,
  IconEmailCircle: IconEmailCircle,
  IconProfile: IconProfile,
  IconBin: IconBin
};
// globalni ikony CT - jako objekt pro pristup pres jmeno - END

type TIcons = keyof typeof icons;
const getIconByName = (name: string) => {
  if (name as TIcons in icons) {
    return icons[name as TIcons];
  } else {
    return undefined;
  }
};
export const iconExists = (name: string) => !!getIconByName(name);
export const IconComponent = ({
  name
}: {
  name: string;
}) => {
  const Ic = getIconByName(name);
  if (Ic === undefined) return null;
  return <Ic data-sentry-element="Ic" data-sentry-component="IconComponent" data-sentry-source-file="index.tsx" />;
};