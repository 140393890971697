import { WithSideMenuLayout } from "@components/core/Layouts/WithSideMenuLayout/WithSideMenuLayout";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import { historicalMilestones2events, prepareEvents } from "@components/webHistory/utils";
import { HistoricalMilestonesBlock } from "@components/blocks/HistoricalMilestonesBlock/HistoricalMilestonesBlock";
import { DirectorsStrip } from "@components/blocks/DirectorsStrip/DirectorsStrip";
import { MediaListStrip } from "@components/blocks/MediaListStrip/MediaListStrip";
import { DisplayColumnsStrip } from "@components/blocks/DisplayColumnsStrip/DisplayColumnsStrip";
import classNames from "classnames";
import { componentHeader, componentHeader_h1, item_wrap, item_wrap_1_3, item_wrap_1_2, item_wrap_1_1 } from "./HistoryPhasePage.css";
export const HistoryPhasePage = ({
  page,
  allPages
}: any) => {
  //priprava event dat
  const events = prepareEvents(historicalMilestones2events(page.historicalMilestones));
  return <WithSideMenuLayout page={page} allPages={allPages} data-sentry-element="WithSideMenuLayout" data-sentry-component="HistoryPhasePage" data-sentry-source-file="HistoryPhasePage.tsx">
      <>
        <div className={classNames(componentHeader)}>
          <h1 className={classNames(componentHeader_h1)}>{page.title}</h1>
          <HTMLBlock HTML={page.perex} data-sentry-element="HTMLBlock" data-sentry-source-file="HistoryPhasePage.tsx" />
        </div>

        {/** historicke milniky */}
        <div>
          <HistoricalMilestonesBlock events={events} headline={"Historické milníky"} data-sentry-element="HistoricalMilestonesBlock" data-sentry-source-file="HistoryPhasePage.tsx" />
        </div>

        {/** reditele */}
        {page?.directors ? <DirectorsStrip data={page.directors} displayedCount={3} /> : null}

        {/** media */}
        {page?.mediaPreview ? <>
            <MediaListStrip media={page.mediaPreview.media} headline={page.mediaPreview.headline} cropAspectRatio={page.mediaPreview.crop} detailLink={{
          url: page.mediaPreview.sourcePage.url,
          label: page.mediaPreview.detailLinkTitle ? page.mediaPreview.detailLinkTitle : page.mediaPreview.sourcePage.title
        }} />
          </> : null}

        {/** 1. sada bloku */}
        {page?.firstBlocks ? <div className={classNames(item_wrap, item_wrap_1_3)}>
            <h2>Rozcestník</h2>
            <DisplayColumnsStrip childrenSize={page.firstBlocks.childrenSize} childrens={page.firstBlocks.childrens} link={page.firstBlocks.link} />
          </div> : null}

        {/** 2. sada bloku */}
        {page?.secondBlocks ? <div className={classNames(item_wrap, item_wrap_1_2)}>
            <DisplayColumnsStrip childrenSize={page.secondBlocks.childrenSize} childrens={page.secondBlocks.childrens} link={page.secondBlocks.link} />
          </div> : null}

        {/** 3. sada bloku */}
        {page?.thirdBlocks ? <div className={classNames(item_wrap, item_wrap_1_1)}>
            <DisplayColumnsStrip childrenSize={page.thirdBlocks.childrenSize} childrens={page.thirdBlocks.childrens} link={page.thirdBlocks.link} />
          </div> : null}
      </>
    </WithSideMenuLayout>;
};