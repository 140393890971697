import React from "react";
import { UneditablePageProps } from "@components/core/PageTypes/UneditablePage/UneditablePage";
import { DefaultLayout } from "@components/webCareer/Layouts/DefaultLayout/DefaultLayout";
import imageTop from "./images/bgcarrercontacts.webp";
import { InternshipApplicationForm } from "./components/InternshipApplicationForm";
export const InternshipApplicationFormPage: React.FC<UneditablePageProps> = ({
  page,
  allPages
}) => {
  // Vytvoření nové kopie objektu page s aktualizovanou hodnotou perex
  const updatedPage = {
    ...page,
    perex: "Máte zájem o stáž v České televizi?<br>Vyplňte formulář níže a my se s vámi co nejdříve spojíme."
  };
  const headerImage = {
    downloadUrl: imageTop.src,
    title: "",
    width: imageTop.width,
    height: imageTop.height
  };
  return <DefaultLayout page={updatedPage} allPages={allPages} headerImage={headerImage} data-sentry-element="DefaultLayout" data-sentry-component="InternshipApplicationFormPage" data-sentry-source-file="InternshipApplicationFormPage.tsx">
      <InternshipApplicationForm data-sentry-element="InternshipApplicationForm" data-sentry-source-file="InternshipApplicationFormPage.tsx" />
    </DefaultLayout>;
};