import React, { useState, useEffect } from "react";
import { CtServices } from "@components/core/PageTypes/HomePage/components/CtServices/CtServices";
import { MostInterest } from "@components/core/PageTypes/HomePage/components/MostInterest/MostInterest";
import { ActualFromCT } from "@components/core/PageTypes/HomePage/components/ActualFromCt/ActualFromCT";
import { VisitUs } from "@components/core/PageTypes/HomePage/components/VisitUs/VisitUs";
import { GeneralDirectorBlog } from "@components/core/PageTypes/HomePage/components/GeneralDirectorBlog/GeneralDirectorBlog";
import { BePartOfCT } from "@components/core/PageTypes/HomePage/components/BePartOfCT/BePartOfCT";
import { WeSupport } from "@components/core/PageTypes/HomePage/components/WeSupport/WeSupport";
import { InformationFromLaw } from "@components/core/PageTypes/HomePage/components/InformationFromLaw/InformationFromLaw";
import { UsefullyLinks } from "@components/core/PageTypes/HomePage/components/UsefullyLinks/UsefullyLinks";
import { AlertBlock } from "@components/blocks/AlertBlock/AlertBlock";
import { projectGlobalMenuConfig } from "@components/layouts/components/Header/projectGlobalMenuConfig";
import { WrapperPositions } from "@czechtv/global-components";
import { vars as globalVars } from "@czechtvPackagesOverrides/styles/vanilla/global/global.css";
import { Header } from "@components/layouts/components/Header/Header";
import classNames from "classnames";
import { componentHeaderBottomHTML, componetHeaderWithImage, componetHeaderWithImageBackgroundWrap, componetHeaderWithImageTxt, componetHeaderWithImageTxt_h1, componetHeaderWithImageTxtAr, componetHeaderWithImageTxtHomepage } from "@czechtvPackagesOverrides/styles/vanilla/components/headerWithImage/headerWithImage.css";
import { SmartImage } from "@components/reusables/SmartImage/SmartImage";
import { Content } from "@czechtv/components";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import { ButtonPrimary } from "@czechtvPackagesOverrides/components/ButtonPrimary";
import { CTGlobalFooter } from "@components/layouts/components/CTGlobalFooter/CTGlobalFooter";
import { BannerCardBlock } from "@components/blocks/BannerCardBlock/BannerCardBlock";

// url: /vse-o-ct/kontakty/domovska-stranka-6bca4ers/
export const HomePage = ({
  page
}: {
  page: any;
}) => {
  const globalMenuConfig = {
    ...projectGlobalMenuConfig,
    defaultPosition: WrapperPositions.absolute,
    style: {
      default: {
        dark: true,
        backgroundColor: "transparent"
      },
      floating: {
        dark: true,
        backgroundColor: globalVars.colors.blueBase
      }
    }
  };
  return <PasswordProtector data-sentry-element="PasswordProtector" data-sentry-component="HomePage" data-sentry-source-file="HomePage.tsx">
      <>
        <Header globalMenuConfig={globalMenuConfig} data-sentry-element="Header" data-sentry-source-file="HomePage.tsx" />
        <main>
          <header className={classNames(componetHeaderWithImage)}>
            {/** custom obrazek na pozadi hlavicky */}
            <div className={classNames(componetHeaderWithImageBackgroundWrap)}>
              {!!page?.headerImage ? <SmartImage src={page.headerImage.downloadUrl} alt={page.title} width={page.headerImage.width} height={page.headerImage.height} bigImage={true} /> : null}
            </div>
            <Content data-sentry-element="Content" data-sentry-source-file="HomePage.tsx">
              <div className={classNames(componetHeaderWithImageTxt, componetHeaderWithImageTxtHomepage)}>
                {/** title */}
                <h1 className={classNames(componetHeaderWithImageTxt_h1)}>{page.title}</h1>

                {/** perex */}
                {!!page?.perex ? <div className={classNames(componetHeaderWithImageTxtAr)}>
                    <HTMLBlock HTML={page.perex} />
                  </div> : null}

                {/** akcni tlacitko v header */}
                {!!page?.headerActionLink ? <ButtonPrimary anchor href={page.headerActionLink.url} target={page.headerActionLink?.target ? page.headerActionLink?.target : undefined}>
                    {page.headerActionLink?.title}
                  </ButtonPrimary> : null}
              </div>

              <div className={componentHeaderBottomHTML}>
                <CtServices data-sentry-element="CtServices" data-sentry-source-file="HomePage.tsx" />
              </div>

              {page?.alert && <AlertBlock value={page.alert} />}
            </Content>
          </header>

          <Content data-sentry-element="Content" data-sentry-source-file="HomePage.tsx">
            {/** nejcasteji vas zajima */}
            {page.favoriteLinks?.length != 0 && <MostInterest items={page.favoriteLinks.map((item: any) => item.value)} />}

            {/** blog Aktualne z CT */}
            {page.shortBlogListNewsFromCt?.pages?.length > 0 && <ActualFromCT value={page.shortBlogListNewsFromCt} />}

            {/** bannery */}
            {page.banners?.length != 0 && page.banners.map((item: any) => <div key={item.id}>
                  <BannerCardBlock value={item.value} />
                </div>)}

            {/** navstivte nas */}
            <VisitUs items={page.visitUs.map((item: any) => item.value)} data-sentry-element="VisitUs" data-sentry-source-file="HomePage.tsx" />

            {/** blog generálního ředitele */}
            {page.shortBlogListCeo?.pages?.length > 0 && <GeneralDirectorBlog value={page.shortBlogListCeo} />}

            {/** kariera */}
            <BePartOfCT data-sentry-element="BePartOfCT" data-sentry-source-file="HomePage.tsx" />

            {/** sponzorstvi a podpora */}
            <WeSupport data-sentry-element="WeSupport" data-sentry-source-file="HomePage.tsx" />

            {/** pravni informace */}
            <InformationFromLaw items={page.lawInformations.map((item: any) => item.value)} data-sentry-element="InformationFromLaw" data-sentry-source-file="HomePage.tsx" />

            {/** uzitecne odkazy */}
            {page.usefulLinks?.length != 0 && <UsefullyLinks items={page.usefulLinks.map((item: any) => item.value)} />}
          </Content>
        </main>
        <CTGlobalFooter data-sentry-element="CTGlobalFooter" data-sentry-source-file="HomePage.tsx" />
      </>
    </PasswordProtector>;
};

// add return type
const PasswordProtector: React.FC<{
  children: React.ReactNode;
}> = ({
  children
}) => {
  const password = "dE84k3k2mUC";
  const name = "vseocthomeprotection";
  const duration = 86400000; // 1 den
  const [isAuthorised, setIsAuthorised] = useState<boolean>(false);
  const calculateTimestamp = () => Math.floor(Date.now() / 1000);
  const validateTimestamp = () => {
    const fromLocalStorage = localStorage.getItem(name);
    const tms = calculateTimestamp();
    setIsAuthorised(tms - parseInt(fromLocalStorage || "0") < duration);
  };
  useEffect(() => {
    validateTimestamp();
    const timer = setInterval(validateTimestamp, 1000);
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>
      {isAuthorised ? children : <div style={{
      display: "flex",
      justifyContent: "center",
      padding: "40px 0",
      height: "100vh"
    }}>
          <div>
            <input type="password" onChange={e => {
          if (e.target.value === password) {
            localStorage.setItem(name, calculateTimestamp().toString());
            setIsAuthorised(true);
          }
        }} style={{
          padding: "5px",
          fontSize: "1.5rem",
          textAlign: "center"
        }} />
          </div>
        </div>}
    </>;
};