import { EventsPage } from '@components/webHistory/PageTypes/EventsPage/EventsPage';
import { BaseContentPage } from '@components/webHistory/PageTypes/BaseContentPage/BaseContentPage';
import { UneditablePage } from '@components/webHistory/PageTypes/UneditablePage/UneditablePage';
import { PersonPage } from '@components/webHistory/PageTypes/PersonPage/PersonPage';
import { PersonListPage } from '@components/webHistory/PageTypes/PersonListPage/PersonListPage';
import { MediaPage } from '@components/webHistory/PageTypes/MediaPage/MediaPage';
import { HistoryHomePage } from '@components/webHistory/PageTypes/HistoryHomePage/HistoryHomePage';
import { HistoryPhasePage } from '@components/webHistory/PageTypes/HistoryPhasePage/HistoryPhasePage';
const sectionId = 'webHistory';
const baseSectionPath = '/historie';
const isThisSection = (page: any) => page.path.startsWith(baseSectionPath);
const pageTypes = [{
  id: 'HistoryHomePage',
  component: HistoryHomePage
}, {
  id: 'HistoryPhasePage',
  component: HistoryPhasePage
}, {
  id: 'UneditablePage',
  component: UneditablePage
}, {
  id: 'BaseContentPage',
  component: BaseContentPage
}, {
  id: 'EventsPage',
  component: EventsPage
}, {
  id: 'PersonPage',
  component: PersonPage
}, {
  id: 'PersonListPage',
  component: PersonListPage
}, {
  id: 'MediaPage',
  component: MediaPage
}];
export const staticContentBlocks = [];
export const oldHistoryBasePath = `${baseSectionPath}/historie-cst`;
export const newHistoryBasePath = `${baseSectionPath}/novodoba-historie-ct`;
export const seventyYearsOfCTBasePath = `${baseSectionPath}/70-let-ct`;
const getSideMenuRootPage = (page: any, pages: any[]): any | undefined => {
  if (!!siteSection.isThisSection(page)) {
    let rootPage = pages.find(p => p.path == baseSectionPath);
    [oldHistoryBasePath, newHistoryBasePath, seventyYearsOfCTBasePath].map(subSectionBasePath => {
      if (page.path.startsWith(subSectionBasePath)) {
        rootPage = pages.find(p => p.path == subSectionBasePath);
      }
    });
    return rootPage;
  }
};
export const siteSection = {
  sectionId: sectionId,
  pageTypes: pageTypes,
  isThisSection: isThisSection,
  staticContentBlocks: staticContentBlocks,
  getSideMenuRootPage: getSideMenuRootPage
};