import React from "react";
import Link from "next/link";
import classNames from "classnames";
import { SmartImage } from "@components/reusables/SmartImage/SmartImage";
import { cardContent, cardImage, cardImageWrapper, cardItem, cardItemAsLink, cardItemText, cardItemTitle, headerDecorationCard } from "@components/blocks/LinkCardBlock/LinkCardBlock.css";
import { TLinkStyle } from "@components/blocks/Link/Link";

/*
Struktura dat:
{
  title: string,
  link: data pro jeden link,
  text: string | "",
  subtitle: string | "",
  image?: data pro obrazek,
}
*/

export type Link = {
  url: string;
  title: string;
  target?: string;
  type?: TLinkStyle;
};
export type Image = {
  downloadUrl: string;
  title: string;
  width: number;
  height: number;
};
export type TLinkCardBlock = {
  link: Link;
  title: string;
  text?: string;
  image?: Image;
};
interface LinkCardBlockProps {
  value: TLinkCardBlock;
}
export const LinkCardBlock: React.FC<LinkCardBlockProps> = ({
  value
}) => {
  //console.log("LinkCardBlock:", value)
  return <>
      {value.link ? <Link href={value.link.url} className={classNames(cardItem, cardItemAsLink)} title={value.title}>

            {value.image ? <div className={classNames(cardImageWrapper)}>
                <SmartImage className={classNames(cardImage)} src={value.image.downloadUrl} width={value.image.width} height={value.image.height} alt={value.image.title} />
              </div> : null}

            <span className={classNames(cardContent)}>
              <h3 className={classNames(cardItemTitle)}>{value.title}</h3>

              {value.text && <span className={classNames(headerDecorationCard)}></span>}

              {value.text && <span className={classNames(cardItemText)}>
                  {value.text}
                </span>}
            </span>
          </Link> : null}
    </>;
};