import classNames from "classnames";
import { ActualFromCTBlock, headline } from "@components/core/PageTypes/HomePage/components/ActualFromCt/ActualFromCT.css";
import { contentBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock.css";
import { ShortBlogList } from "@components/blocks/ShortBlogList/ShortBlogList";
export const ActualFromCT = ({
  value
}: {
  value: any;
}) => {
  return <div className={classNames(contentBlock, ActualFromCTBlock)} data-sentry-component="ActualFromCT" data-sentry-source-file="ActualFromCT.tsx">
      <h2 className={classNames(headline)}>Aktuálně z ČT</h2>
      <ShortBlogList value={value} data-sentry-element="ShortBlogList" data-sentry-source-file="ActualFromCT.tsx" />
    </div>;
};