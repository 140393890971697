import { ISuperTable, TSuperTableRow, ISuperTableCell, SUPER_TABLE_CELL_CONTENT_TYPE } from '@components/blocks/StructuredContentBlock/sublocks/SuperTableBlock/SuperTableBlock';
import { table } from 'console';
export const asSuperTable = (wagtailData: any): ISuperTable => {
  // vraci typ sloupce podle indexu
  const getColumnType = (index: number, wagtailData: any): SUPER_TABLE_CELL_CONTENT_TYPE | undefined => {
    const wagtailColumnType = wagtailData.table.columns[index]?.type;
    switch (wagtailColumnType) {
      case 'text':
        return SUPER_TABLE_CELL_CONTENT_TYPE.TEXT;
      case 'rich_text':
        return SUPER_TABLE_CELL_CONTENT_TYPE.HTML;
      case 'number':
        return SUPER_TABLE_CELL_CONTENT_TYPE.NUMBER;
      default:
        return undefined;
    }
  };

  // vraci data hlavicky tabulky
  const getHeaderRowData = (wagtailData: any): TSuperTableRow => {
    const headerRow: TSuperTableRow = [];
    const notEmpty = !!wagtailData.table.columns.filter((cellData: any) => !!cellData.heading).length;
    if (notEmpty) {
      wagtailData.table.columns.forEach((cellData: any, i: number) => {
        const cell: ISuperTableCell = {
          content: cellData.heading,
          contentType: getColumnType(i, wagtailData)
        };
        headerRow.push(cell);
      });
    }
    return headerRow;
  };

  // data tabulky
  const tableData: ISuperTable = {
    caption: wagtailData?.caption,
    firstColumnIsHeader: !!wagtailData?.firstColumnIsHeader
  };

  // data radku tabulky
  const data: TSuperTableRow[] = [];

  // hlavicka tabulky
  const headerRowData = getHeaderRowData(wagtailData);
  if (headerRowData.length) {
    data.push(headerRowData);
    tableData.firstRowIsHeader = true;
  }

  // radky tabulky
  for (let i = 0; i < wagtailData.table.rows.length; i++) {
    const row: TSuperTableRow = [];
    for (let cellIndex = 0; cellIndex < wagtailData.table.rows[i].values.length; cellIndex++) {
      const cell: ISuperTableCell = {
        content: wagtailData.table.rows[i].values[cellIndex],
        contentType: getColumnType(cellIndex, wagtailData)
      };
      row.push(cell);
    }
    data.push(row);
  }
  if (data.length) tableData.data = data;
  return tableData;
};