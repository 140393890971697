import { buildMediaQuery } from '@czechtv/styles';

// TODO: tohle se musi s novou responsivitou udelat jinak
const maxWidthMobileLarge = 591;
const maxWidthMediumDesktop = 937;

// definice konstant
export const GRID_GAP = 8;
export const ANIMATION_BEZIER_HOVER = "cubic-bezier(0,0.1,0.3,1)";
export const MAIN_CONTAINER = {
  width: "100%",
  maxWidth: "1200px",
  marginLeft: "auto",
  marginRight: "auto",
  boxSizing: "border-box",
  paddingLeft: `${GRID_GAP * 2}px`,
  paddingRight: `${GRID_GAP * 2}px`,
  [buildMediaQuery({
    minWidth: maxWidthMobileLarge
  })]: {
    paddingLeft: `${GRID_GAP * 3}px`,
    paddingRight: `${GRID_GAP * 3}px`
  },
  [buildMediaQuery({
    minWidth: maxWidthMediumDesktop
  })]: {
    paddingLeft: `${GRID_GAP * 4}px`,
    paddingRight: `${GRID_GAP * 4}px`
  }
};
export const DATE_FORMAT = {
  YEAR: "YYYY",
  YEAR_MONTH: "M. YYYY",
  YEAR_MONTH_DAY: "D. M. YYYY",
  YEAR_MONTH_DAY_TIME: "D. M. YYYY H:mm",
  MONTH_DAY: "D. M.",
  TIME: "H:mm"
};
export type TDateFormat = keyof typeof DATE_FORMAT;
export const LETTERS = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];