import classNames from "classnames";
import { contentBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock.css";
import Link from "next/link";
import { buttonPrimary } from "@czechtvPackagesOverrides/styles/vanilla/components/buttons.css";
import { BePartOfCTContent, BePartOfCTContentBlocks, BePartOfCTContentBlocksItem, BePartOfCTContentBlocksItemLink, BePartOfCTContentBlocksItemParagraph, BePartOfCTContentBlocksItems, BePartOfCTContentBlocksItemTitle, BePartOfCTContentBlocksItemTitleBlue, BePartOfCTContentBlocksItemTitleRed, BePartOfCTContentButton, BePartOfCTContentHeading, BePartOfCTContentSubHeading } from "@components/core/PageTypes/HomePage/components/BePartOfCT/BePartOfCT.css";
import { useEffect, useState, useRef } from "react";
import { getPositions } from "@components/webCareer/StaticContentComponents/utils";
import { linkFullWithArrow } from "@czechtvPackagesOverrides/styles/vanilla/components/links.css";
export const BePartOfCT = () => {
  const [positions, setPositions] = useState<any[] | null>(null);
  const [countPositions, setCountPositions] = useState<number>(0);
  const countRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    getPositions().then(positions => {
      setPositions(positions !== undefined ? positions : []);
      setCountPositions(positions !== undefined ? positions.length : 0);
    }).catch(() => {
      setPositions([]);
      setCountPositions(0);
    });
  }, []);
  const pluralize_00 = (count: number) => {
    if (count === 0) return "volných míst";
    if (count == 1) return "volné místo";
    if (count > 1 && count < 5) return "volná místa";
    return "volných míst";
  };
  return <div className={classNames(contentBlock, BePartOfCTContent)} data-sentry-component="BePartOfCT" data-sentry-source-file="BePartOfCT.tsx">
      <div className={classNames(BePartOfCTContentBlocks)}>
        <h2 className={classNames(BePartOfCTContentHeading)}>
          Staňte se součástí ČT
        </h2>
        <div className={classNames(BePartOfCTContentSubHeading)}>
          Každý den začíná nový příběh. Začněte ten svůj v České televizi.
        </div>
        <div className={classNames(BePartOfCTContentBlocksItems)}>
          <div className={classNames(BePartOfCTContentBlocksItem)}>
            <div className={classNames(BePartOfCTContentBlocksItemTitle)}>
              <span className={classNames(BePartOfCTContentBlocksItemTitleRed)}>{countPositions}</span> {pluralize_00(countPositions)}
            </div>
            <p className={classNames(BePartOfCTContentBlocksItemParagraph)}>
              Podívejte se na všechny naše otevřené pozice, které aktuálně obsazujeme.
            </p>
            <Link href="https://ceskatelevize.jobs.cz/" className={classNames(BePartOfCTContentBlocksItemLink)} data-sentry-element="Link" data-sentry-source-file="BePartOfCT.tsx"> Volná místa </Link>
          </div>
          <div className={classNames(BePartOfCTContentBlocksItem)}>
            <div className={classNames(BePartOfCTContentBlocksItemTitle)}>
              <span className={classNames(BePartOfCTContentBlocksItemTitleBlue)}>200</span> stáží
            </div>
            <p>
              Vyberte si z mnoha zajímavých stáží v rámci České Televize
            </p>
            <Link href="/kariera/studenti-a-staze/" className={classNames(BePartOfCTContentBlocksItemLink)} data-sentry-element="Link" data-sentry-source-file="BePartOfCT.tsx">Více o stážích </Link>
          </div>
          <div className={classNames(BePartOfCTContentBlocksItem)}>
            <div className={classNames(BePartOfCTContentBlocksItemTitle)}>
              Konkurzy
            </div>
            <p>
              Nastartujte svou hereckou dráhu v České televizi
            </p>
            <Link href="https://www.ceskatelevize.cz/vse-o-ct/kariera/konkurzy/" className={classNames(BePartOfCTContentBlocksItemLink)} data-sentry-element="Link" data-sentry-source-file="BePartOfCT.tsx"> Více o konkurzech </Link>
          </div>
        </div>
        <div className={classNames(BePartOfCTContentButton)}>
          <Link href="https://ceskatelevize.jobs.cz/" className={classNames(buttonPrimary.normal)} data-sentry-element="Link" data-sentry-source-file="BePartOfCT.tsx">
            Volná místa
          </Link>
        </div>
      </div>
    </div>;
};