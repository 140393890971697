import Link from "next/link";
import { ImageBlock } from "@components/blocks/ImageBlock/ImageBlock";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import { RADIUS } from "@consts/roundCorners";
import { formatDateTime } from "@utils/datetime";
import defaultPlaceholder from "./placeholder.jpg";
import { ASPECT_RATIO } from "@consts/aspectRatio";
import { createArticleSummaryQueryString } from "@components/core/PageTypes/ArticleSummaryPage/ArticleSummaryPageContent";
import { ImageNEW } from "@projectTypes/index";
import { buttonPrimary, careerBlogBoxes, careerBlogItem, careerBlogItem_h3, careerBlogItem_span, careerBlogItem_div, ShortBlogWrap, ShortBlogBTNWrapp, HelperImgHAuto } from "./ShortBlogList.css";
export const ShortBlogList = ({
  value
}: {
  value: any;
}) => {
  const getPlaceholder = (item: any): ImageNEW => {
    // vyber placeholderu
    // 1. placeholder ze stranky articlu
    // 2. placeholder definovany pro blok
    // 3. defaultni placeholder
    if (!!item.image) {
      return {
        downloadUrl: item.image.downloadUrl,
        width: item.image.width,
        height: item.image.height,
        title: item.title
      };
    }
    if (!!value.placeholder) {
      return {
        downloadUrl: value.placeholder.downloadUrl,
        width: value.placeholder.width,
        height: value.placeholder.height,
        title: item.title
      };
    }
    return {
      downloadUrl: defaultPlaceholder.src,
      width: defaultPlaceholder.width,
      height: defaultPlaceholder.height,
      title: item.title
    };
  };

  // link na source page vcetne query stringu
  const sourcePageUrlWithCategory = `${value.sourcePage.url}${createArticleSummaryQueryString(value.category)}`;
  return <div className={ShortBlogWrap} data-sentry-component="ShortBlogList" data-sentry-source-file="ShortBlogList.tsx">
      <div className={careerBlogBoxes}>
        {value.pages.map((item: any) => <Link key={item.url} href={item.url} className={careerBlogItem}>
            <div className={HelperImgHAuto}>
              <ImageBlock radius={RADIUS.BASE} image={{
            ...getPlaceholder(item)
          }} cropAspectRatio={ASPECT_RATIO.HORIZONTAL_16_9} />
            </div>
            <h3 className={careerBlogItem_h3}>{item.title}</h3>
            <span className={careerBlogItem_span}>{formatDateTime(item.displayDate, "DATE")}</span>
            <div className={careerBlogItem_div}>
              {" "}
              {item?.perex ? <HTMLBlock HTML={item.perex} /> : null}
            </div>
          </Link>)}
      </div>
      <div className={ShortBlogBTNWrapp}>
        <Link href={sourcePageUrlWithCategory} className={buttonPrimary} data-sentry-element="Link" data-sentry-source-file="ShortBlogList.tsx">
          Všechny články
        </Link>
      </div>
    </div>;
};