import { useState, useEffect } from "react";
import Link from "next/link";
import { Spinner } from "@components/reusables/Spinner/Spinner";
import { StaticContentBlockComponentProps } from "@components/blocks/StructuredContentBlock/sublocks/StaticContentBlock/StaticContentBlock";
import { VideoBlock } from "@components/blocks/VideoBlock/VideoBlock";
import { formatDateTime } from "@utils/datetime";
import classNames from "classnames";
import { ctCouncilVideo, ctCouncilVideo_h2, playBannerStyle, playBannerStyle_h2, playBannerStyle_link } from "./../../Layouts/CtCouncilHomePageLayout/CtCouncilHomePageLayou.css";
import { VideoBlockWrap_alert } from "./../../PageTypes/CtCouncilMeetingPage/CtCouncilMeetingPage.css";
const getData = async (rootUrl: string) => {
  const response = await fetch(`/vse-o-ct/api/services/web-ct-council/actual-meeting/${rootUrl}`);
  return await response.json();
};
export const ActualCtCouncilMeeting = ({
  parameters
}: StaticContentBlockComponentProps) => {
  const rootUrl = parameters?.rootUrl;
  const [meetingsData, setMeetingsData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!rootUrl) return;
    getData(rootUrl).then(data => {
      setMeetingsData(data);
    }).catch(error => console.error(error)).finally(() => {
      setLoading(false);
    });
  }, [rootUrl]);
  const getBannerTexts = (meeting: any) => {
    return meeting?.onAir ? {
      hedline: `Dnes probíhá: ${meeting.menuTitle}`,
      button: "Sledovat"
    } : {
      hedline: `Pozvánka a materiály: ${meeting.menuTitle}`,
      button: "Zobrazit více"
    };
  };
  return <>
      {loading ? <Spinner /> : <>
          {/** baner z actualMeeting */}
          {meetingsData?.actualMeeting && <div className={classNames(playBannerStyle)}>
              <h2 className={classNames(playBannerStyle_h2)}>
                {getBannerTexts(meetingsData.actualMeeting)?.hedline}
              </h2>
              <Link href={meetingsData.actualMeeting.path} className={classNames(playBannerStyle_link)}>
                {getBannerTexts(meetingsData.actualMeeting)?.button}
              </Link>
            </div>}

          {/** video z lastMeeting */}
          {meetingsData?.lastMeeting?.meetingRecord && <div className={classNames(ctCouncilVideo)}>
              <h2 className={classNames(ctCouncilVideo_h2)}>{meetingsData.lastMeeting.title}</h2>

              {meetingsData?.lastMeeting?.meetingRecord && new Date(meetingsData?.lastMeeting?.meetingRecord).setHours(13, 0, 0, 0) >= new Date().getTime() && <div className={classNames(VideoBlockWrap_alert)}>
                    Přímý přenos se spustí{" "}
                    <strong>
                      {meetingsData.lastMeeting.eventDate && formatDateTime(meetingsData.lastMeeting.eventDate)}{" "}
                      ve 12:00
                    </strong>
                    .
                  </div>}
              <VideoBlock videoID={meetingsData.lastMeeting.meetingRecord.id} videoIdType={meetingsData.lastMeeting.meetingRecord.videoIdType} previewImageUrl={meetingsData.lastMeeting.meetingRecord.thumbnail?.downloadUrl} />
            </div>}
        </>}
    </>;
};