const BASE_JOBSCZ_URL = "https://ceskatelevize.jobs.cz";
export type TCategory = {
  title: string;
  url: string;
  amount?: number;
  jobsCzBranchIds?: string[];
  locality?: {
    property: string;
    value: string;
  }; // filtrovani podle lokality
  employmentIncludes?: string[]; // filtrovani podle typu uvazku
  showAmount?: boolean; // zobrazit pocet pozic
};
export const categories: TCategory[] = [{
  title: "Zpravodajství a sport",
  url: "/kariera/moznosti-uplatneni/prace-ve-zpravodajstvi",
  jobsCzBranchIds: ["cc2"]
}, {
  title: "Tvůrčí pozice",
  url: "/kariera/moznosti-uplatneni/tvurci-pozice",
  jobsCzBranchIds: ["cc12", "cc13"]
}, {
  title: "Technika",
  url: "/kariera/moznosti-uplatneni/prace-v-technice",
  jobsCzBranchIds: ["cc11"]
}, {
  title: "Informační technologie",
  url: "/kariera/moznosti-uplatneni/it-uvnitr-ct",
  jobsCzBranchIds: ["cc3"]
}, {
  title: "Odborné a administrativní pozice",
  url: "/kariera/moznosti-uplatneni/specializovane-a-administrativni-pozice",
  jobsCzBranchIds: ["cc1", "cc5", "cc6", "cc7", "cc10", "cc14", "cc15"]
}, {
  title: "Řemeslo a umění ",
  url: "/kariera/moznosti-uplatneni/remeslo-a-umeni",
  jobsCzBranchIds: ["cc9"]
}, {
  title: "Digitální služby",
  url: "/kariera/moznosti-uplatneni/digitalni-sluzby",
  jobsCzBranchIds: ["cc4"]
}, {
  title: "Produkce",
  url: "/kariera/moznosti-uplatneni/produkce",
  jobsCzBranchIds: ["cc8"]
}, {
  title: "Studio Brno",
  url: "/kariera/moznosti-uplatneni/televizni-studio-brno",
  locality: {
    property: "city",
    value: "Brno"
  }
}, {
  title: "Studio Ostrava",
  url: "/kariera/moznosti-uplatneni/televizni-studio-ostrava",
  locality: {
    property: "city",
    value: "Ostrava"
  }
}, {
  title: "Stáže",
  url: "/kariera/studenti-a-staze",
  employmentIncludes: ["internship-temporary"]
}, {
  title: "Konkurzy",
  url: "/kariera/konkurzy",
  showAmount: false
}];
export const getCategoryJobsCzUrl = (category?: TCategory | undefined): string => {
  let url = `${BASE_JOBSCZ_URL}/`;

  // podle jobsCzBranchId
  if (category?.jobsCzBranchIds !== undefined && category?.jobsCzBranchIds.length > 0) {
    url = url + "?";
    category?.jobsCzBranchIds.forEach((jobsCzBranchId: string) => {
      url = url + `custom_branch=${jobsCzBranchId}`;
    });
  }
  return url;
};