import { useState } from "react";
import classNames from "classnames";
import { ButtonPrimary } from "@czechtvPackagesOverrides/components/ButtonPrimary";
import Link from "next/link";
import { ValidatedTextInput, ValidatedTextArea, ValidatedCheckbox, ValidatedSelect, useForm, Form } from "@czechtv/form";
import { MessageSuccess, MessageFailed } from "@czechtv/components";
import { FileInput, MB } from "@components/reusables/Form/inputs/FileInput/FileInput";
import { sendForm } from "@utils/form/sendForm";
import { useRouter } from "next/router";
import { formContact, formContactCheck, formContactFileField, formContactFileFieldDescription, formContactFileFieldLabel, formContactHead, formContactLink, formContactRadio, formContactSendButton, formContactSendGroup, formContactStatement, formContactSubHead, formContactTextArea, formContactTextAreaElement, formContactTextAreaStatement, formContactTwoC } from "@components/core/PageTypes/UneditablePage/pages/GlobalContactsPage/components/GlobalContactForm/GlobalContactForm.css";
interface FormValues {
  name: string;
  interest: string;
  phone: string;
  email: string;
  message: string;
  attachement: null | File[];
  gdpr: string;
}

// zajmy
const interest = ["Zpětná vazba", "Námět na reportáž - zpravodajství", "Námět na reportáž - obecné", "Připomínka k pořadu", "Technické potíže", "Jiné"];
const interestOptions = interest.map((item: string) => {
  return {
    id: item,
    text: item
  };
});

// placeholder pro select zajmy
// (musi byt ve variable protoze je potreba v selectu validovat ze hodnota != placeholder)
const interestPlaceholder = "Vyberte téma";
export const GlobalContactForm = () => {
  const form = useForm<FormValues>();
  const [disabled, setDisabled] = useState(false);
  const router = useRouter();
  const {
    register,
    setValue,
    formState: {
      isSubmitting,
      isValid
    },
    reset
  } = form;

  // prasácké handlování selectu :(
  const [selectedInterest, setSelectedInterest] = useState("");
  const onFormSubmit = async (data: FormValues) => {
    const url = "/vse-o-ct/api/forms/contact-form";

    // uprava dat
    // pid: id k informacim o problemu v playeru
    const updatedData = router.query?.pid ? {
      ...data,
      pid: router.query.pid
    } : data;
    try {
      await sendForm(url, updatedData);
      resetForm();
    } catch (error: any) {
      console.log(error);
      throw new Error(error.message);
    } finally {
      setDisabled(true);
      setTimeout(() => {
        setDisabled(false);
      }, 10000);
    }
  };
  const resetForm = () => {
    reset();
    setSelectedInterest("");
  };
  return <article className={classNames(formContact)} id="kontaktniFormular" data-sentry-component="GlobalContactForm" data-sentry-source-file="GlobalContactForm.tsx">
      <h2 className={classNames(formContactHead)}>Kontaktní formulář</h2>
      <p className={classNames(formContactSubHead)}>
        Máte dotaz či zajímavý podnět k našim pořadům či službám? Napište nám:
      </p>

      <Form form={form} onSubmit={onFormSubmit} successState={FormSuccess} errorState={FormError} data-sentry-element="Form" data-sentry-source-file="GlobalContactForm.tsx">
        {!disabled ? <>
            <div className={classNames(formContactTwoC)}>
              <div>
                <ValidatedTextInput {...register("name", {
              required: "Zadejte jméno a příjmení"
            })} autoComplete="name" label={{
              required: true,
              title: "Jméno a příjmení"
            }} maxWidth={2000} showValidationIcon />
              </div>
              <div className={classNames(formContactRadio)}>
                <div>
                  <ValidatedSelect {...register("interest", {
                required: true,
                validate: (value: string) => {
                  return value.trim() != "" && value != interestPlaceholder || "Zadejte co vás zajímá";
                }
              })} displayValidationStatus={true} label={{
                required: true,
                title: "Co vás zajímá?"
              }} options={interestOptions} placeholder={interestPlaceholder} selectedOption={selectedInterest} onSelected={(text: string) => {
                setSelectedInterest(text);
                setValue("interest", text, {
                  shouldValidate: true
                });
              }} maxWidth={2000} />
                </div>
              </div>
            </div>

            <div className={classNames(formContactTwoC)}>
              <div>
                <ValidatedTextInput {...register("phone", {
              required: false
            })} autoComplete="phone" label={{
              required: false,
              title: "Telefon"
            }} type="tel" maxWidth={2000} showValidationIcon={false} />
              </div>
              <div>
                <ValidatedTextInput {...register("email", {
              required: {
                value: true,
                message: "Zadejte e-mail"
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "E-mail, který jste zadali, je ve špatném formátu. Zadejte platný e-mail."
              }
            })} autoComplete="email" label={{
              required: true,
              title: "E-mail"
            }} maxWidth={2000} showValidationIcon />
              </div>
            </div>

            <div className={classNames(formContactTextArea)}>
              <ValidatedTextArea {...register("message", {
            required: {
              value: true,
              message: "Napište Vaši zprávu"
            }
          })} label={{
            required: true,
            title: "Zpráva nebo dotaz"
          }} maxWidth={2000} className={classNames(formContactTextAreaElement)} />
            </div>
            <div className={classNames(formContactFileField)}>
              <label className={classNames(formContactFileFieldLabel)} htmlFor="attachement">
                Zde můžete vložit přílohu
              </label>
              <div>
                <FileInput {...register("attachement")} required={false} accept=".doc,.png,.jpg,.jpeg,.docx,.pdf" maxSize={1 * MB} />
              </div>
              <p className={classNames(formContactFileFieldDescription)}>
                Přijímáme všechny přílohy typu JPG, PNG, PDF, DOC, DOCX do velikosti 1 MB.
              </p>
            </div>

            <div className={classNames(formContactSendGroup)}>
              <div className={classNames(formContactCheck)}>
                <ValidatedCheckbox {...register("gdpr", {
              required: {
                value: true,
                message: "Pro odeslání zprávy musíte souhlasit se zpracováním osobních údajů"
              }
            })} item={{
              id: "1",
              title: ""
            }} />{" "}
                <span className={classNames(formContactStatement)}>
                  Souhlasím se{" "}
                  <Link className={classNames(formContactLink)} href="./gdpr/" rel="noreferrer" target="_blank">
                    zpracováním osobních údajů
                  </Link>
                </span>
              </div>
              <ButtonPrimary className={classNames(formContactSendButton)} disabled={isSubmitting || !isValid || disabled} type="submit" waiting={isSubmitting}>
                Odeslat
              </ButtonPrimary>
            </div>
            <div className={classNames(formContactTextArea)}>
              <p className={classNames(formContactTextAreaStatement)}>
                Dotazy zpracováváme v co nejkratší možné době, zpravidla do 5 dnů po jejich
                obdržení. Na obsáhlejší dotazy, jejichž odpověď připravujeme ve spolupráci s kolegy
                ze specializovaných oddělení, reagujeme nejpozději do 60 dnů.{" "}
                <Link className={classNames(formContactLink)} href="./kontakty/pravidla-komunikace/" rel="noreferrer" target="_blank">
                  Pravidla komunikace ČT
                </Link>
              </p>
            </div>
          </> : null}
      </Form>
    </article>;
};
const FormError = () => <>
    <MessageFailed description={"Formulář se bohužel nepodařilo odeslat. Zkuste to později."} data-sentry-element="MessageFailed" data-sentry-source-file="GlobalContactForm.tsx" />
    <br />
  </>;
const FormSuccess = () => <>
    <MessageSuccess description={"Formulář byl úspěšně odeslán, budeme Vás kontaktovat na uvedeném e-mailu."} data-sentry-element="MessageSuccess" data-sentry-source-file="GlobalContactForm.tsx" />
    <br />
  </>;