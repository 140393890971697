import { useState, useEffect } from "react";
import classNames from "classnames";
import { StaticContentBlockComponentProps } from "@components/blocks/StructuredContentBlock/sublocks/StaticContentBlock/StaticContentBlock";
import { ButtonPrimary } from "@czechtvPackagesOverrides/components/ButtonPrimary";
import Link from "next/link";
import { Spinner } from "@components/reusables/Spinner/Spinner";
import { getPositions, getNumOfCities } from "./../utils";
import { getCategoryJobsCzUrl } from "./../categories";
import { flexOne, headerDecoration, introBox, introBoxItem, introBoxItemBig, introBoxItemLink, introBoxItemParagraph, introBoxItemTitleNew, introBoxItemTitleSpan, moveToTop } from "@components/webCareer/StaticContentComponents/HpTopStrip/HpTopStrip.css";
export const HpTopStrip = ({
  parameters
}: StaticContentBlockComponentProps) => {
  const [positions, setPositions] = useState<any[] | null>(null);
  useEffect(() => {
    getPositions().then(positions => {
      setPositions(positions !== undefined ? positions : []);
    }).catch(() => {
      setPositions([]);
    });
  }, []);
  const pluralize_00 = (count: number | null) => {
    if (count === null || count == 0) return "volných míst";
    if (count == 1) return "volné místo";
    if (count > 1 && count < 5) return "volná místa";
    return "volných míst";
  };
  const pluralize_01 = (count: number | null) => {
    if (count === null || count == 0) return "";
    if (count == 1) return `v ${count} městě`;
    if (count > 1 && count < 5) return `ve ${count} městech`;
    return `v ${count} městech`;
  };
  const pluralize_02 = (count: number | null) => {
    if (count === null || count == 0) return "";
    if (count == 1) return `pozici`;
    if (count == 2) return `obě pozice`;
    if (count > 2 && count < 5) return `všechny ${count} pozice`;
    return `všech ${count} pozic`;
  };
  return <article className={classNames(moveToTop)} data-sentry-component="HpTopStrip" data-sentry-source-file="HpTopStrip.tsx">
      <div className={classNames(introBox)}>
        {positions === null ? <Spinner /> : <>
            <div className={classNames(introBoxItem, introBoxItemBig)}>
              <h2 className={classNames(introBoxItemTitleNew)}>
                <span className={classNames(introBoxItemTitleSpan)}>{positions.length}</span>{" "}
                {pluralize_00(positions.length)} {pluralize_01(getNumOfCities(positions))}
              </h2>
              <div className={classNames(headerDecoration)}></div>
              <p className={classNames(introBoxItemParagraph)}>
                Podívejte se na všechny naše otevřené pozice, které aktuálně obsazujeme.
              </p>
              <div className={classNames(flexOne)}></div>
              {positions.length > 0 ? <p className={classNames(introBoxItemParagraph)}>
                  <ButtonPrimary anchor target="_blank" href={getCategoryJobsCzUrl()}>
                    Zobrazit {pluralize_02(positions.length)}
                  </ButtonPrimary>
                </p> : null}
            </div>
            <div className={classNames(introBoxItem)}>
              <h2 className={classNames(introBoxItemTitleNew)}>
                <span className={classNames(introBoxItemTitleSpan)}>Ročně 200+</span> stáží
              </h2>
              <div className={classNames(headerDecoration)}></div>
              <p className={classNames(introBoxItemParagraph)}>
                Vyberte si z mnoha zajímavých stáží v rámci České Televize
              </p>
              <div className={classNames(flexOne)}></div>
              <Link href="/kariera/studenti-a-staze/" className={classNames(introBoxItemLink)}>
                Více o stážích
              </Link>
            </div>
            <div className={classNames(introBoxItem)}>
              <h2 className={classNames(introBoxItemTitleNew)}>
                <span className={classNames(introBoxItemTitleSpan)}>Vyhlášené</span> konkurzy
              </h2>
              <div className={classNames(headerDecoration)}></div>
              <p className={classNames(introBoxItemParagraph)}>
                Nastartujte svou hereckou dráhu v České televizi
              </p>
              <div className={classNames(flexOne)}></div>
              <Link href="/kariera/konkurzy/" className={classNames(introBoxItemLink)}>
                Více o konkurzech
              </Link>
            </div>
          </>}
      </div>
    </article>;
};