import React from "react";
import classNames from 'classnames';
import { defaultState, fullscreenState } from "@components/reusables/Spinner/Spinner.css";
export const Spinner = ({
  fullScreen = false
}: {
  fullScreen?: boolean;
}): JSX.Element => {
  return <>
      {fullScreen ? <div className={classNames(fullscreenState)}></div> : <div className={classNames(defaultState)}></div>}
    </>;
};