import { LayoutProps } from "@components/core/Layouts";
import { CTGlobalFooter } from "@components/layouts/components/CTGlobalFooter/CTGlobalFooter";
import { Header } from "@components/layouts/components/Header/Header";
import { ContactBox } from "./components/ContactBox/ContactBox";
import { Content } from "@czechtv/components";
import classNames from "classnames";
import { narrowContent } from "./RegionalBroadcastingLayout.css";

// rozsireni props layoutu o typ menu
type RegionalBroadcastingLayout = LayoutProps & {
  layoutSubtype?: "HOMEPAGE" | "STANDARD";
};
export const RegionalBroadcastingLayout: React.FC<RegionalBroadcastingLayout> = ({
  children,
  layoutSubtype = "STANDARD"
}) => {
  return <div data-sentry-component="RegionalBroadcastingLayout" data-sentry-source-file="RegionalBroadcastingLayout.tsx">
      <Header data-sentry-element="Header" data-sentry-source-file="RegionalBroadcastingLayout.tsx" />
      {layoutSubtype == "STANDARD" ? <Content>
          <div className={classNames(narrowContent)}>{children ? children : null}</div>
        </Content> : <>{children ? children : null}</>}

      <ContactBox data-sentry-element="ContactBox" data-sentry-source-file="RegionalBroadcastingLayout.tsx" />
      <CTGlobalFooter data-sentry-element="CTGlobalFooter" data-sentry-source-file="RegionalBroadcastingLayout.tsx" />
    </div>;
};