import { DefaultLayout } from "@components/webCareer/Layouts/DefaultLayout/DefaultLayout";
import { StructuredContenBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock";
import { AttachedDocumentsBlock } from "@components/blocks/AttachedDocumentsBlock/AttachedDocumentsBlock";
export const BaseContentPage = ({
  page,
  allPages
}: any) => {
  return <DefaultLayout page={page} allPages={allPages} data-sentry-element="DefaultLayout" data-sentry-component="BaseContentPage" data-sentry-source-file="BaseContentPage.tsx">
      <>
        {/** strukturovany obsah */}
        <StructuredContenBlock content={page.structuredContent} data-sentry-element="StructuredContenBlock" data-sentry-source-file="BaseContentPage.tsx" />

        {/** pripojene soubory */}
        {page.attachedDocuments?.length > 0 ? <AttachedDocumentsBlock documents={page.attachedDocuments.map((item: any) => item.value)} /> : null}
      </>
    </DefaultLayout>;
};