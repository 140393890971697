import { ImageBlock } from '@components/blocks/ImageBlock/ImageBlock';
import { VideoBlock } from '@components/blocks/VideoBlock/VideoBlock';
import { RADIUS } from '@consts/roundCorners';
import { ASPECT_RATIO } from '@consts/aspectRatio';
import { testimonial, testimonialDescription, testimonialHeading, testimonialImageRounded, testimonialItemRounded, testimonialPosition, testimonialRectangle, testimonialTxt, testimonialVideoRectangle, testimonialVideoRounded } from "@components/blocks/TestimonialBlock/TestimonialBlock.css";
export const TestimonialBlock = ({
  value
}: {
  value: any;
}) => {
  if (value.displayType == 'WITH_ROUND_IMAGE') {
    return <div className={testimonial}>
      {value.textPosition == 'LEFT' ? <div className={testimonialItemRounded}>
        <TextContent value={value} />
        <MediaContent value={value} />
        </div> : <div className={testimonialItemRounded}>
          <MediaContent value={value} />
          <TextContent value={value} />
        </div>}
    </div>;
  }
  return <div className={testimonial} data-sentry-component="TestimonialBlock" data-sentry-source-file="TestimonialBlock.tsx">
      {value.textPosition == 'LEFT' ? <div className={testimonialRectangle}>
          <TextContent value={value} />
          <MediaContent value={value} />
        </div> : <div className={testimonialRectangle}>
          <MediaContent value={value} />
          <TextContent value={value} />
        </div>}
    </div>;
};
const TextContent = ({
  value
}: {
  value: any;
}) => {
  return <div className={testimonialTxt} data-sentry-component="TextContent" data-sentry-source-file="TestimonialBlock.tsx">
      <p className={testimonialDescription}>
        {value.text}
      </p>
      <h3 className={testimonialHeading}>
        {value.name}
        {!!value?.position ? <span className={testimonialPosition}>{value.position}</span> : null}
      </h3>
    </div>;
};
const MediaContent = ({
  value
}: {
  value: any;
}) => {
  // nebyl zadan obrazek ani video
  if (!value?.video == false && !value?.image == false) return <></>;

  // typ: WITH_ROUND_IMAGE
  if (value.displayType == 'WITH_ROUND_IMAGE') {
    return <>
        {!!value?.video ? <div className={testimonialVideoRounded}>
            <VideoBlock videoID={value.video} />
          </div> : <div className={testimonialImageRounded}>
            <ImageBlock image={{
          downloadUrl: value.image.downloadUrl,
          width: value.image.width,
          height: value.image.height,
          title: value.image.title
        }} cropAspectRatio={ASPECT_RATIO.SQUARE} radius={RADIUS.CIRCLE} />
          </div>}
      </>;
  }

  // default
  return <>
      {!!value?.video ? <div className={testimonialVideoRectangle}>
          <VideoBlock videoID={value.video} />
        </div> : <div className={testimonialVideoRectangle}>
          <ImageBlock image={{
        downloadUrl: value.image.downloadUrl,
        width: value.image.width,
        height: value.image.height,
        title: value.image.title
      }} />
        </div>}
    </>;
};