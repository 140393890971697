import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fglobal%2Fglobal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42WTW%2FbMAyG7%2FsVPK6AUoiULNnabbcdBgzLsOvgJE7qLl91kjbtsP8%2BMLGU1lTXIrEO8iO%2BIl%2BJSeg2mz38%2BQAwGv3CatXd1LUOMN4cumkzrte7b91m9L1ZHJZ1p2BXr3ejXdO1808vVuBwxbhZtZ83y9nrS2i45Mu%2BXrbT1xeYAD9%2BMjyedk2zfntTdrDg%2F%2FspBvTXZtYeVq%2FzLkC3mNQfK6cAnVZARaEAr15Svqe04s%2Fwbdm%2F5aXxGTJVZIxXVCrjBFE%2Fi4LOZHQmPVF4RdoprEgg0x4xhYISFWDlZTazHkJyCssyo9REwlkui1GAJclA87hly1IaFZhM8RYpMaOACPthiN0kjKJcaSTWJoxjnIRtBrsN8HLi95sGLGPSFW%2BRj0KmdqsIWT4wp8JIaB0hXShgTktm0zPOKSAFVhLbFEXB%2BTsk7mJOxJU1vO0iE6iLgcqCEyPGMm7uUmqclreMZUT38cZwCOsVkM2U4BAPIqd%2F8txkPL%2BPCXgFpxtYZkr%2B8B7o%2BKxYp5OhM4V47CGrjFZOnoCnGOTkGmWSR31BkLNyGQZTtwCyCnLlQUpXh%2BucBsGZZDEjaRCcTYeX1S7DkCvSzea3l2HIuXQYLtcsYyDGzoh4qkgahlzskXyv4yOg2CQLryA%2BAop90pQK4iOgSSobF%2Bz8CGiaIEq1zQWbJa8oeZXjmnfGmyevit4myp1WXCSvimiTz3E37%2BTadEd88qrKcLc95%2F3ZpypXvNhPuZOwTy7nVWyphs4%2B5foJppbqzj6Z3N7XAcrtcTC5CYBOzG4DkBWzdwEMidkugNVidhfASrV9gEKqHQI4qXYfwEu1hwClVDsGKKXaY4BKqj0F7mzDadIBEIUeYQAkIUjE00KRTAA0QpJsALRSsgiAhZR0bImU9DwtJcsA6KVkFQBLKVkHwEpKTgKQlpLTANPDpJ2OJs1T23T83%2B0alb42ou3TjHPRq91gOv0Lev5br6%2FpCvT2CLg9Am2PIJXn%2BXXFZZ3JrlsEkEnfZA4%2BtbmDT7cB%2FNnAv%2F8Achf%2FrxAMAAA%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2FwebCtCouncil%2FStaticContentComponents%2FComplaintsAndSuggestionsForm%2FComplaintsAndSuggestionsForm.css.tsx.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA%2BVW247aMBB95yumD12VB0dOlqt56Z9UJjFgbWJ7HQNhK%2F69snPBgSSwi9SHVgiQPOMz1zOe4P1kqHnH8HsEkPBcpfREYJ3K%2BG01Asio3nKB1tIYmRE4UP0DoV%2FhMtM7SsP3sdVRNEm42N5IlZOuafy21XIvEhTLVOprtUWpJXXCNNI04fv8BkmOV6PzKChdDZ2rGykMyvkHIxAG86lmmYVJuWBox%2Fh2ZwhE1aFT3dCMp6dr5Ikz3unXaezFb6QigFVxPyWynRJ8uUEAA75WF%2BNSxbDCIJryrSCQso3x4o1cvAMYshejJ7Rw6ZyUisbcnAjgYOHZe%2FXs9UWp%2FIJM2r2z1Tyx8Ft6yZk9Q4ZlKqWG2T7YZyInEG70rVTLY06A7o2EjnRjz%2FC0bXiTMmfM%2FqOjtubtrz1yWUHcsCwnEDNhmG5cHOq1Kaz3xkjhDB15YnYEwsXcBtXozFpCjL%2B3ZAk%2FdOXTFUzJnBsuBQHNUmr4gdnTD8RFwgoCkQc0dyAvQN3%2FYMsCGE1FjewUr%2FSiZHx9EldXX8hOHpiurDwF1ePkvBKfR%2BXXJ3IXi8PgtaH3AJOxg216gYvUZrseY3cyXdLHchwtyoatCIwsjwi84lbFFy49pX6kCvjGMyW1ocKn7fIx2nr6lr9Us7LETfjLtm36PzQCDhbdIz0Mood7IWXGMI1yO%2BXcKA5w2Nyu69JRE8BQNsTQ7Ox6KQtUDYH5rF2z9SdbIb554Z4gRtjrb2sgzVoeJy2PO94s%2B1n97aI9W5B%2FkTdNydgDz3brbdtArqhoFRrNVdG3o5xHPzOWcAp5rBkTQEUCPzIu6hzPJlgVYwcX%2BDvlPZ%2F2VTh9W2S5Y57v2p%2FPFj32%2B5DzB5FDHE0%2BCX38OnRYQXvMii4Eul5xA09wRR2EA%2Bzdu7sF3%2Ff2tsS1s%2F7qeVmvzhfFaFBxcDjqar310KZ1ji6bnpD1rufpzSq9yn%2B7U66aiwTCr3bWpMLt2h911c%2F9K2%2B99nqOLltU6Zy4quhKTDnWPKT1s0jnP0p8szUSDgAA%22%7D"
export var formContact = 'qytatq0';
export var formContactCheck = 'qytatq6';
export var formContactCheck_alert = 'qytatqf';
export var formContactCheck_span = 'qytatq7';
export var formContactCheck_spanGDPR = 'qytatq8';
export var formContactFileField = 'qytatqb';
export var formContactFileField_label = 'qytatqc';
export var formContactFileField_p = 'qytatqd';
export var formContactOneC = 'qytatq3';
export var formContactRadio = 'qytatqe';
export var formContactSendGroup = 'qytatq5';
export var formContactSubHead = 'qytatq2';
export var formContactTextArea = 'qytatq9';
export var formContactTextArea_p = 'qytatqa';
export var formContactTwoC = 'qytatq4';
export var formContact_h2 = 'qytatq1';