import classNames from "classnames";
import { contentBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock.css";
import { cardsContent_four, UsefullyLinksMainTitle, UsefullyLinksWrapper } from "@components/core/PageTypes/HomePage/components/UsefullyLinks/UsefullyLinks.css";
import { LinkCardBlock } from "@components/blocks/LinkCardBlock/LinkCardBlock";
export const UsefullyLinks = ({
  items
}: {
  items: any[];
}) => {
  return <div className={classNames(contentBlock, UsefullyLinksWrapper)} data-sentry-component="UsefullyLinks" data-sentry-source-file="UsefullyLinks.tsx">
      <h2 className={classNames(UsefullyLinksMainTitle)}>Užitečné odkazy</h2>
      <div className={classNames(cardsContent_four)}>
        {items.map((item, index) => <LinkCardBlock value={item} key={index} />)}
      </div>
    </div>;
};