import { useState, useEffect } from "react";
import classNames from "classnames";
import { StaticContentBlockComponentProps } from "@components/blocks/StructuredContentBlock/sublocks/StaticContentBlock/StaticContentBlock";
import { VideoBlock } from "@components/blocks/VideoBlock/VideoBlock";
import Link from "next/link";
import { getPositions, getCategoriesInfo } from "./../utils";
import videoThumb from "./thumb.jpg";
import { careerCategoryVideoBlock, careerHighlights, careerHighlightsItem, careerHighlightsItemAmount, categoryList, categoryListTitle, LinkList, LinkListItem, LinkListItemAmount, LinkListItemLink, LinkListWrap } from "@components/webCareer/StaticContentComponents/CategoryJobsSummary/CategoryJobsSummary.css";
export const CategoryJobsSummary = ({}: StaticContentBlockComponentProps) => {
  return <article className={classNames(categoryList)} data-sentry-component="CategoryJobsSummary" data-sentry-source-file="CategoryJobsSummary.tsx">
      <CareerCategoryLinkList data-sentry-element="CareerCategoryLinkList" data-sentry-source-file="CategoryJobsSummary.tsx" />
      <CareerCategoryVideoBlock data-sentry-element="CareerCategoryVideoBlock" data-sentry-source-file="CategoryJobsSummary.tsx" />
    </article>;
};
const CareerCategoryVideoBlock = () => {
  return <div className={classNames(careerCategoryVideoBlock)} data-sentry-component="CareerCategoryVideoBlock" data-sentry-source-file="CategoryJobsSummary.tsx">
      <h2 className={classNames(categoryListTitle)}>Objevte práci, která má širší smysl</h2>
      <VideoBlock videoID="65af91a54b18fc47f50a53f6" previewImageUrl={videoThumb.src} videoIdType="versionId" data-sentry-element="VideoBlock" data-sentry-source-file="CategoryJobsSummary.tsx" />
      <ul className={classNames(careerHighlights)}>
        <li className={classNames(careerHighlightsItem)}>
          <span className={classNames(careerHighlightsItemAmount)}>70+ let</span>už vysíláme pro naše diváky
        </li>
        <li className={classNames(careerHighlightsItem)}>
          <span className={classNames(careerHighlightsItemAmount)}>1. místo</span>ve sledovanosti televizí v ČR
        </li>
        <li className={classNames(careerHighlightsItem)}>
          <span className={classNames(careerHighlightsItemAmount)}>74%</span>vlastní tvorby
        </li>
        <li className={classNames(careerHighlightsItem)}>
          <span className={classNames(careerHighlightsItemAmount)}>4,6 mil.</span>lidí si alespoň 1x za rok naladí ČT
        </li>
      </ul>
    </div>;
};
const CareerCategoryLinkList = () => {
  const [categories, setCategories] = useState<any[]>(getCategoriesInfo([]));
  const [loaded, setLoaded] = useState<boolean>(false);
  const pluralizePositions = (count: number | null) => {
    if (count === null || count == 0) return "pozic";
    if (count > 0 && count < 5) return "pozice";
    return "pozic";
  };
  useEffect(() => {
    getPositions().then(positions => {
      if (positions !== undefined) {
        setCategories(getCategoriesInfo(positions));
        setLoaded(true);
      }
    }).catch(() => {
      //
    });
  }, []);
  return <div className={classNames(LinkListWrap)} data-sentry-component="CareerCategoryLinkList" data-sentry-source-file="CategoryJobsSummary.tsx">
      <h2 className={classNames(categoryListTitle, 'list')}>Možnosti uplatnění</h2>
      <ul className={classNames(LinkList)}>
        {categories.map(item => <li className={classNames(LinkListItem)} key={item.title}>
            <Link className={classNames(LinkListItemLink)} href={item.url}>
              {item.title}
              {loaded && item?.showAmount !== false ? <span className={classNames(LinkListItemAmount)}>
                  {item.amount}
                  {" "}
                  {pluralizePositions(item.amount)}
                </span> : null}
            </Link>
          </li>)}
      </ul>
    </div>;
};