import { ArticleSummaryPageContent } from "@components/core/PageTypes/ArticleSummaryPage/ArticleSummaryPageContent";
import { DefaultLayout } from "@components/core/Layouts/DefaultLayout/DefaultLayout";
import { QuestionContactBox } from '@components/reusables/QuestionContactBox/QuestionContactBox';
import { XTweetBox } from "./XTweeteBlock";
import { ForMediaInfoBox } from "./ForMediaInfoBox";
export const ArticleSummaryPage = ({
  page,
  allPages
}: {
  page: any;
  allPages: any[];
}) => {
  return <DefaultLayout page={page} allPages={allPages} data-sentry-element="DefaultLayout" data-sentry-component="ArticleSummaryPage" data-sentry-source-file="ArticleSummaryPage.tsx">
      <>
        <ArticleSummaryPageContent page={page} allPages={allPages} data-sentry-element="ArticleSummaryPageContent" data-sentry-source-file="ArticleSummaryPage.tsx" />
        <ForMediaInfoBox data-sentry-element="ForMediaInfoBox" data-sentry-source-file="ArticleSummaryPage.tsx" />
        {/** <XTweetBox /> */}
        <QuestionContactBox data-sentry-element="QuestionContactBox" data-sentry-source-file="ArticleSummaryPage.tsx" />
      </>
    </DefaultLayout>;
};