import React from "react";
import classNames from "classnames";
import { LayoutProps } from "@components/core/Layouts";
import { Header } from "@components/layouts/components/Header/Header";
import { BreadcrumbNavigation } from "@components/layouts/components/BreadcrumbNavigation/BreadcrumbNavigation";
import { Content } from "@czechtv/components";
import { SideMenu, getSideMenuItems, getRootPage } from "@components/layouts/components/SideMenu/SideMenu";
import { CTGlobalFooter } from "@components/layouts/components/CTGlobalFooter/CTGlobalFooter";
import { createBreadcrumbs } from "@utils/pages";
import { containerMain, containerSideMenu, containerContent } from "./WithSideMenuLayout.css";
export const WithSideMenuLayout: React.FC<LayoutProps> = ({
  page,
  allPages,
  children
}) => {
  const rootPage = getRootPage(page, allPages);
  const sideMenuItems = getSideMenuItems({
    pages: allPages,
    rootPage: rootPage
  });
  return <>
      <Header data-sentry-element="Header" data-sentry-source-file="WithSideMenuLayout.tsx" />
      <Content data-sentry-element="Content" data-sentry-source-file="WithSideMenuLayout.tsx">
        {allPages ? <BreadcrumbNavigation items={createBreadcrumbs(page, allPages)} /> : null}
        <div className={classNames(containerMain)}>
          <div className={classNames(containerSideMenu)}>
            {allPages ? <SideMenu items={sideMenuItems} rootPage={rootPage} /> : null}
          </div>
          <div className={classNames(containerContent)}>{children ? children : null}</div>
        </div>
      </Content>
      <CTGlobalFooter data-sentry-element="CTGlobalFooter" data-sentry-source-file="WithSideMenuLayout.tsx" />
    </>;
};