import { MediaList } from "@components/blocks/MediaList/MediaList";
import { ASPECT_RATIO } from "@consts/aspectRatio";
import { mainContainer, mainContainer_h2, btnContainer } from "./MediaListStrip.css";
interface MediaListStripProps {
  media: any[];
  detailLink: {
    url: string;
    label: string;
  };
  displayedCount?: number;
  cropAspectRatio?: undefined | ASPECT_RATIO;
  headline?: undefined | string;
}
export const MediaListStrip = ({
  media,
  detailLink,
  cropAspectRatio = undefined,
  displayedCount = undefined,
  headline = undefined
}: MediaListStripProps) => {
  return <div className={mainContainer} data-sentry-component="MediaListStrip" data-sentry-source-file="MediaListStrip.tsx">
      {/** headline */}
      {headline ? <h2 className={mainContainer_h2}>{headline}</h2> : null}

      {/** media */}
      <MediaList media={media} cropAspectRatio={cropAspectRatio} displayedCount={displayedCount} data-sentry-element="MediaList" data-sentry-source-file="MediaListStrip.tsx" />
      {/** detail link */}
      {/*
       <div  className={classNames(btnContainer)}>
        <ButtonPrimary anchor href={detailLink.url} >{detailLink.label}</ButtonPrimary>
       </div>
       */}
    </div>;
};