// Link.tsx
import { default as NextLink } from "next/link";
import classNames from "classnames";
import { styles } from "./Link.css";
export type TLinkStyle = "DEFAULT" | "BUTTON" | "ACTION_BUTTON";
type LinkProps = {
  url: string;
  title?: string;
  target?: string;
  linkStyle?: TLinkStyle;
  externalClass?: string;
};
export const Link = ({
  url,
  title,
  target,
  linkStyle = "DEFAULT",
  externalClass
}: LinkProps) => {
  const baseClass = styles.BASE;
  const linkClass = styles[linkStyle];
  return <NextLink href={url} target={target} className={classNames(baseClass, linkClass, externalClass)} data-sentry-element="NextLink" data-sentry-component="Link" data-sentry-source-file="Link.tsx">
      {title}
    </NextLink>;
};