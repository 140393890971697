import React from "react";
import { LayoutProps } from "@components/core/Layouts";
import { Header } from "@components/layouts/components/Header/Header";
import { CTGlobalFooter } from "@components/layouts/components/CTGlobalFooter/CTGlobalFooter";
import { WrapperPositions } from "@czechtv/global-components";
import classNames from "classnames";
import { SmartImage } from "@components/reusables/SmartImage/SmartImage";
import { Content } from "@czechtv/components";
import { ButtonPrimary } from "@czechtvPackagesOverrides/components/ButtonPrimary";
import { BreadcrumbNavigation } from "@components/layouts/components/BreadcrumbNavigation/BreadcrumbNavigation";
import { createBreadcrumbs } from "@utils/pages";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import { projectGlobalMenuConfig } from "@components/layouts/components/Header/projectGlobalMenuConfig";
import { Blue_base } from "@czechtvPackagesOverrides/styles/fromCTV/index";
import { componetHeaderWithImage, componetHeaderWithImageBackgroundWrap, componetHeaderWithImageTxt, componetHeaderWithImageTxt_h1, componetHeaderWithImageTxtAr } from "@czechtvPackagesOverrides/styles/vanilla/components/headerWithImage/headerWithImage.css";
import { vars as globalVars } from "@czechtvPackagesOverrides/styles/vanilla/global/global.css";
const globalMenuConfig = {
  ...projectGlobalMenuConfig,
  defaultPosition: WrapperPositions.absolute,
  style: {
    default: {
      dark: true,
      backgroundColor: "transparent"
    },
    floating: {
      dark: true,
      backgroundColor: globalVars.colors.blueBase
    }
  }
};
export const DefaultLayout: React.FC<LayoutProps> = ({
  page,
  children,
  allPages,
  headerImage
}) => {
  headerImage = headerImage ? headerImage : page?.headerImage;
  return <div data-sentry-component="DefaultLayout" data-sentry-source-file="DefaultLayout.tsx">
      <Header globalMenuConfig={globalMenuConfig} data-sentry-element="Header" data-sentry-source-file="DefaultLayout.tsx" />
      <main>
        <header className={classNames(componetHeaderWithImage)}>
          {/** custom obrazek na pozadi hlavicky */}
          <div className={classNames(componetHeaderWithImageBackgroundWrap)}>
            {!!headerImage ? <SmartImage src={headerImage.downloadUrl} alt={page.title} width={headerImage.width} height={headerImage.height} bigImage={true} /> : null}
          </div>
          <Content data-sentry-element="Content" data-sentry-source-file="DefaultLayout.tsx">
            <div className={classNames(componetHeaderWithImageTxt)}>
              {/** drobeckovka */}
              {allPages ? <BreadcrumbNavigation threeLineMenu={true} darkMode={true} items={createBreadcrumbs(page, allPages)} /> : null}

              {/** title */}
              <h1 className={classNames(componetHeaderWithImageTxt_h1)}>{page.title}</h1>

              {/** perex */}
              {!!page?.perex ? <div className={classNames(componetHeaderWithImageTxtAr)}>
                  <HTMLBlock HTML={page.perex} />
                </div> : null}

              {/** akcni tlacitko v header */}
              {!!page?.headerActionLink ? <ButtonPrimary anchor href={page.headerActionLink.url} target={page.headerActionLink?.target ? page.headerActionLink?.target : undefined}>
                  {page.headerActionLink?.title}
                </ButtonPrimary> : null}
            </div>
          </Content>
        </header>
        <Content data-sentry-element="Content" data-sentry-source-file="DefaultLayout.tsx">{children ? children : null}</Content>
      </main>
      <CTGlobalFooter data-sentry-element="CTGlobalFooter" data-sentry-source-file="DefaultLayout.tsx" />
    </div>;
};