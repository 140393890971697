import classNames from "classnames";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import { BASE_container, DEFAULT_container, BIG_NUMBERS_container, BIG_NUMBERS_li, BIG_NUMBERS_ol } from "@components/blocks/StructuredContentBlock/sublocks/OrderedListBlock/OrderedListBlock.css";
type TListStyle = "DEFAULT" | "BIG_NUMBERS";
const getParsedMarkup = (markup: string) => {
  let parsedMarkup = markup.trim().replace(/<ol>/g, "").replace(/<\/ol>/g, "");
  const regex = /<li[^>]*>(.*?)<\/li>/g;
  let match;
  const items = [];
  while ((match = regex.exec(parsedMarkup)) !== null) {
    items.push(match[1]);
  }
  return items;
};
export const OrderedListBlock = ({
  markup,
  listStyle = "DEFAULT"
}: {
  markup: string;
  listStyle?: TListStyle;
}) => {
  return <div className={classNames(BASE_container, listStyle === "BIG_NUMBERS" ? BIG_NUMBERS_container : DEFAULT_container)} data-sentry-component="OrderedListBlock" data-sentry-source-file="OrderedListBlock.tsx">
      <ol className={classNames(listStyle === "BIG_NUMBERS" ? BIG_NUMBERS_ol : null)}>
        {getParsedMarkup(markup).map((item, index) => <li key={index} className={classNames(listStyle === "BIG_NUMBERS" ? BIG_NUMBERS_li : null)}>
            <HTMLBlock HTML={item} />
          </li>)}
      </ol>
    </div>;
};