const sectionId = "webViewership";
const baseSectionPath = "/sledovanost-a-data-o-vysilani";
const isThisSection = (page: any) => page.path.startsWith(baseSectionPath);
const pageTypes: any[] = [];
export const siteSection = {
  sectionId: sectionId,
  pageTypes: pageTypes,
  isThisSection: isThisSection,
  staticContentBlocks: [],
  getSideMenuRootPage: () => undefined
};