import { UneditablePageProps } from "@components/core/PageTypes/UneditablePage/UneditablePage";
import { DefaultLayout } from "@components/core/Layouts/DefaultLayout/DefaultLayout";
import { GlobalContactTop } from "./components/GlobalContactTop/GlobalContactTop";
import { GlobalContactStudias } from "./components/GlobalContactStudias/GlobalContactStudias";
import { GlobalContactForm } from "./components/GlobalContactForm/GlobalContactForm";
import { GlobalContactBoxes } from "./components/GlobalContactBoxes/GlobalContactBoxes";
//import { GlobalContactOmbudsman } from "./components/GlobalContactOmbudsman/GlobalContactOmbudsman";

import topImg from "./images/background.jpg";
export const GlobalContactsPage: React.FC<UneditablePageProps> = ({
  page,
  allPages
}) => {
  const updatedPage = {
    ...page,
    perex: "Korespondenční adresy a telefonní čísla, kontaktní formulář, provoz podatelny a podmínky přijímání dokumentů",
    headerImage: {
      downloadUrl: topImg.src,
      title: page.title,
      width: 2500,
      height: 504
    }
  };
  return <DefaultLayout page={updatedPage} allPages={allPages} data-sentry-element="DefaultLayout" data-sentry-component="GlobalContactsPage" data-sentry-source-file="GlobalContactsPage.tsx">
      <>
        <GlobalContactTop data-sentry-element="GlobalContactTop" data-sentry-source-file="GlobalContactsPage.tsx" />
        <GlobalContactForm data-sentry-element="GlobalContactForm" data-sentry-source-file="GlobalContactsPage.tsx" />
        <GlobalContactBoxes data-sentry-element="GlobalContactBoxes" data-sentry-source-file="GlobalContactsPage.tsx" />
        {/**<GlobalContactOmbudsman />*/}
        <GlobalContactStudias data-sentry-element="GlobalContactStudias" data-sentry-source-file="GlobalContactsPage.tsx" />
      </>
    </DefaultLayout>;
};