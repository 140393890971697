import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import { Navigation, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { container, itemActive, itemButton, itemLink, sliderItem } from "@components/reusables/CarouselHorizontalMenu/CarouselHorizontalMenu.css";
export interface ICarouselHorizontalMenuItem {
  id: string;
  label: string;
  url?: string;
}
export interface CarouselHorizontalMenuProps {
  currentId?: string;
  currentUrl?: string;
  items: ICarouselHorizontalMenuItem[];
  onSelect?: (id: string) => void;
}
export const CarouselHorizontalMenu = ({
  items = [],
  onSelect = undefined,
  currentUrl = undefined,
  currentId = undefined
}: CarouselHorizontalMenuProps) => {
  const [current, setCurrent] = useState<undefined | string>(undefined);
  useEffect(() => {
    currentUrl && setCurrent(items.find(item => item.url === currentUrl)?.id);
  }, [currentUrl, items]);
  useEffect(() => {
    setCurrent(currentId);
  }, [currentId]);
  const handleAction = (id: string) => {
    onSelect?.(id);
    setCurrent(id);
  };
  if (items.length < 2) return null;
  return <div className={classNames(container)} data-sentry-component="CarouselHorizontalMenu" data-sentry-source-file="CarouselHorizontalMenu.tsx">
      <Swiper modules={[Navigation, A11y]} slidesPerView={'auto'} navigation watchSlidesProgress={true} spaceBetween={0} data-sentry-element="Swiper" data-sentry-source-file="CarouselHorizontalMenu.tsx">
        {items.map(item => <SwiperSlide className={classNames(sliderItem, item.id === current ? itemActive : null)} key={item.id}>
            {item?.url ? <Link href={item.url} className={classNames(itemLink)} onClick={() => {
          handleAction(item.id);
        }}>

                {item.label}

              </Link> : <button type="button" className={classNames(itemButton)} onClick={() => {
          handleAction(item.id);
        }}>
                {item.label}
              </button>}
          </SwiperSlide>)}
      </Swiper>
    </div>;
};