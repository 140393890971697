import type { ImageLoader } from "next/image";
export const isStaticImportImage = (src: any) => {
  try {
    return !src.startsWith("/api/media/");
  } catch (e) {
    console.log("Bad image", src);
    return true;
  }
};
const dummyImageLoader: ImageLoader = ({
  src,
  width,
  quality
}) => {
  return src;
};
export const ORIGINAL_IMAGE = "original";
type TOriginal = typeof ORIGINAL_IMAGE;
type TFormatString = undefined | string | TOriginal;
export const createMediaServerUrl = (src: string, formatString: TFormatString = undefined) => {
  // ziskani filename z url
  const getFilename = (src: string, formatString: TFormatString): string | undefined => {
    let filename = src.replace(/\/$/, "").split("/").pop();
    if (filename && formatString !== ORIGINAL_IMAGE) {
      const parts = filename.split(".");
      parts.pop();
      parts.push("webp");
      filename = parts.join(".");
    }
    return filename;
  };
  const filename = getFilename(src, formatString);
  const filenamePart = filename ? `/filename/${filename}` : "";
  const url = `/vse-o-ct${src}${formatString ? `/format/${formatString}${filenamePart}` : ""}`.replace("//", "/");
  return url;
};
export const imageMediaServerUrl = (src: string, width: undefined | number | TOriginal = undefined) => {
  let formatString: undefined | string = width ? width as string : undefined;
  formatString = formatString && formatString !== ORIGINAL_IMAGE ? `width-${formatString}` : formatString;
  return createMediaServerUrl(src, formatString);
};
export const mediaServerImageLoader: ImageLoader = ({
  src,
  width
}) => {
  return createMediaServerUrl(src, width ? `width-${width}` : undefined);
};
const registeredImageLoaders = {
  dummy: dummyImageLoader,
  mediaServerImageLoader: mediaServerImageLoader
};
type TImageLoaderName = keyof typeof registeredImageLoaders;
export const getImageLoader = (name: TImageLoaderName | null | undefined): ImageLoader | undefined => {
  return registeredImageLoaders["dummy"];
};