import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fglobal%2Fglobal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42WTW%2FbMAyG7%2FsVPK6AUoiULNnabbcdBgzLsOvgJE7qLl91kjbtsP8%2BMLGU1lTXIrEO8iO%2BIl%2BJSeg2mz38%2BQAwGv3CatXd1LUOMN4cumkzrte7b91m9L1ZHJZ1p2BXr3ejXdO1808vVuBwxbhZtZ83y9nrS2i45Mu%2BXrbT1xeYAD9%2BMjyedk2zfntTdrDg%2F%2FspBvTXZtYeVq%2FzLkC3mNQfK6cAnVZARaEAr15Svqe04s%2Fwbdm%2F5aXxGTJVZIxXVCrjBFE%2Fi4LOZHQmPVF4RdoprEgg0x4xhYISFWDlZTazHkJyCssyo9REwlkui1GAJclA87hly1IaFZhM8RYpMaOACPthiN0kjKJcaSTWJoxjnIRtBrsN8HLi95sGLGPSFW%2BRj0KmdqsIWT4wp8JIaB0hXShgTktm0zPOKSAFVhLbFEXB%2BTsk7mJOxJU1vO0iE6iLgcqCEyPGMm7uUmqclreMZUT38cZwCOsVkM2U4BAPIqd%2F8txkPL%2BPCXgFpxtYZkr%2B8B7o%2BKxYp5OhM4V47CGrjFZOnoCnGOTkGmWSR31BkLNyGQZTtwCyCnLlQUpXh%2BucBsGZZDEjaRCcTYeX1S7DkCvSzea3l2HIuXQYLtcsYyDGzoh4qkgahlzskXyv4yOg2CQLryA%2BAop90pQK4iOgSSobF%2Bz8CGiaIEq1zQWbJa8oeZXjmnfGmyevit4myp1WXCSvimiTz3E37%2BTadEd88qrKcLc95%2F3ZpypXvNhPuZOwTy7nVWyphs4%2B5foJppbqzj6Z3N7XAcrtcTC5CYBOzG4DkBWzdwEMidkugNVidhfASrV9gEKqHQI4qXYfwEu1hwClVDsGKKXaY4BKqj0F7mzDadIBEIUeYQAkIUjE00KRTAA0QpJsALRSsgiAhZR0bImU9DwtJcsA6KVkFQBLKVkHwEpKTgKQlpLTANPDpJ2OJs1T23T83%2B0alb42ou3TjHPRq91gOv0Lev5br6%2FpCvT2CLg9Am2PIJXn%2BXXFZZ3JrlsEkEnfZA4%2BtbmDT7cB%2FNnAv%2F8Achf%2FrxAMAAA%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fcomponents%2Fheader.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xbjB3cWp2MCB7CiAgcGFkZGluZzogMCAwIHZhcigtLV8xOW1yaGFhMW8pIDA7CiAgbWFyZ2luOiB2YXIoLS1fMTltcmhhYTFxKSAwIHZhcigtLV8xOW1yaGFhMXEpIDA7CiAgY29sb3I6IHZhcigtLV8xOW1yaGFhMWspOwogIGJvcmRlci1ib3R0b206IDFweCBzb2xpZCB2YXIoLS1fMTltcmhhYTFlKTsKfQouXzFuMHdxanYxIHsKICBjb2xvcjogdmFyKC0tXzE5bXJoYWExayk7Cn0KQG1lZGlhIHNjcmVlbiBhbmQgKG1pbi13aWR0aDogNzY4cHgpIHsKICAuXzFuMHdxanYwIHsKICAgIG1hcmdpbjogdmFyKC0tXzE5bXJoYWExcykgMCB2YXIoLS1fMTltcmhhYTF1KSAwOwogIH0KfQpAbWVkaWEgc2NyZWVuIGFuZCAobWluLXdpZHRoOiAxMDI0cHgpIHsKICAuXzFuMHdxanYwIHsKICAgIG1hcmdpbjogMCAwIHZhcigtLV8xOW1yaGFhMXUpIDA7CiAgfQp9%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2FwebHistory%2FPageTypes%2FEventsPage%2FEventsPage.css.tsx.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA71WTXPbIBC9%2B1cw00t0QCP5MyGXnvo3MghWFgkCBmEnTif%2FvSMkORKWbDduo5uA3X1v3%2B5C%2FJSy0r2zTYp%2BzxDKtXK4Eu9A0DyeryyUjzOEpFCACxDbwtXrx2VwDiyuDGVCbQnCSZwcbbynnJZCHgjaU3uH8VP6UNqC0mVUH2BaahtuHfxWSe1WKOy0ISgxb72lTDuny9Aq1d7MUM49kORx9jGLO2Zzz6xxQFCCktDaRIHF4q8tlj2LKZAmGpoQSSuHWSEkH7MOA5hLoPQpKDMeI3S%2BCrVP41Hp03hzhbyrb5B3isnaM%2BGiMpIeCMolePfPu8qJ%2FICZVg6UI6iuWcAZuFcAVZ%2BgUmwVFg7KiiAGyoGtlzPKXrZW7xTHLaEfv%2Fw3ABTAVdE4gSPkhnOmLQeLLeViV41y7hHbhBIl8f1mQqRrejCJxjo4iZP0aN3gI0hpBfW%2F3rk60OfCaXKcpaoy1IJyZ5J6Is%2Bksgg5eHPY%2ByFIQu4ulBbb2areM1p00Twm4YRWBFEpA7M5D8Was2Hm733mXwV3BUHLVrsu2d2%2F0V0IC5I6sYcmvbk7yn0rjgeP41Yv9LTV0%2FE5n8aLa7q9Gea1kgSlg1DZsBe7qhlVL32IHgNcN2BKhpxZf7zaxtP5bmOk0HuwX8%2F4mSLtheFfBlbtt7eAawoTr%2BrKnPBuqCtuCZELKS%2FQhz79BtJ59vA9suRfxfVPVAlF6ZxzsW%2Bc9yair5lJKP9Pwp8lcEFRxSyAQlRxdFcKhdshmSbzpXmLfOh4%2BL4ctPii7eSgyRefF9j4G3Pd2784mT4uwl3XQzxAu27RTl3x%2BuSKN220npPslHJ%2F2p6ZbWd5LaLmyHGyZlKzl5brH8FXbF3QCwAA%22%7D"
export var componentHeader = '_1n0wqjv0';
export var componentHeader_h1 = '_1cmtzc71';
export var hisotryItem = '_1cmtzc74';
export var hisotryItem_h3 = '_1cmtzc75';
export var historyBtn = '_1cmtzc77';
export var historyBtn_back = '_1cmtzc7e';
export var historyBtn_backSvg = '_1cmtzc7f';
export var historyBtn_div = '_1cmtzc7a';
export var historyBtn_next = '_1cmtzc7c';
export var historyBtn_nextSvg = '_1cmtzc7d';
export var historyBtn_span = '_1cmtzc7b';
export var historyBtn_svg = '_1cmtzc78';
export var historyBtn_svgPath = '_1cmtzc79';
export var historyBtns = '_1cmtzc76';
export var historyItems = '_1cmtzc73';
export var historySlider = '_1cmtzc72';