import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fglobal%2Fglobal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42WTW%2FbMAyG7%2FsVPK6AUoiULNnabbcdBgzLsOvgJE7qLl91kjbtsP8%2BMLGU1lTXIrEO8iO%2BIl%2BJSeg2mz38%2BQAwGv3CatXd1LUOMN4cumkzrte7b91m9L1ZHJZ1p2BXr3ejXdO1808vVuBwxbhZtZ83y9nrS2i45Mu%2BXrbT1xeYAD9%2BMjyedk2zfntTdrDg%2F%2FspBvTXZtYeVq%2FzLkC3mNQfK6cAnVZARaEAr15Svqe04s%2Fwbdm%2F5aXxGTJVZIxXVCrjBFE%2Fi4LOZHQmPVF4RdoprEgg0x4xhYISFWDlZTazHkJyCssyo9REwlkui1GAJclA87hly1IaFZhM8RYpMaOACPthiN0kjKJcaSTWJoxjnIRtBrsN8HLi95sGLGPSFW%2BRj0KmdqsIWT4wp8JIaB0hXShgTktm0zPOKSAFVhLbFEXB%2BTsk7mJOxJU1vO0iE6iLgcqCEyPGMm7uUmqclreMZUT38cZwCOsVkM2U4BAPIqd%2F8txkPL%2BPCXgFpxtYZkr%2B8B7o%2BKxYp5OhM4V47CGrjFZOnoCnGOTkGmWSR31BkLNyGQZTtwCyCnLlQUpXh%2BucBsGZZDEjaRCcTYeX1S7DkCvSzea3l2HIuXQYLtcsYyDGzoh4qkgahlzskXyv4yOg2CQLryA%2BAop90pQK4iOgSSobF%2Bz8CGiaIEq1zQWbJa8oeZXjmnfGmyevit4myp1WXCSvimiTz3E37%2BTadEd88qrKcLc95%2F3ZpypXvNhPuZOwTy7nVWyphs4%2B5foJppbqzj6Z3N7XAcrtcTC5CYBOzG4DkBWzdwEMidkugNVidhfASrV9gEKqHQI4qXYfwEu1hwClVDsGKKXaY4BKqj0F7mzDadIBEIUeYQAkIUjE00KRTAA0QpJsALRSsgiAhZR0bImU9DwtJcsA6KVkFQBLKVkHwEpKTgKQlpLTANPDpJ2OJs1T23T83%2B0alb42ou3TjHPRq91gOv0Lev5br6%2FpCvT2CLg9Am2PIJXn%2BXXFZZ3JrlsEkEnfZA4%2BtbmDT7cB%2FNnAv%2F8Achf%2FrxAMAAA%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fcomponents%2Fheader.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xbjB3cWp2MCB7CiAgcGFkZGluZzogMCAwIHZhcigtLV8xOW1yaGFhMW8pIDA7CiAgbWFyZ2luOiB2YXIoLS1fMTltcmhhYTFxKSAwIHZhcigtLV8xOW1yaGFhMXEpIDA7CiAgY29sb3I6IHZhcigtLV8xOW1yaGFhMWspOwogIGJvcmRlci1ib3R0b206IDFweCBzb2xpZCB2YXIoLS1fMTltcmhhYTFlKTsKfQouXzFuMHdxanYxIHsKICBjb2xvcjogdmFyKC0tXzE5bXJoYWExayk7Cn0KQG1lZGlhIHNjcmVlbiBhbmQgKG1pbi13aWR0aDogNzY4cHgpIHsKICAuXzFuMHdxanYwIHsKICAgIG1hcmdpbjogdmFyKC0tXzE5bXJoYWExcykgMCB2YXIoLS1fMTltcmhhYTF1KSAwOwogIH0KfQpAbWVkaWEgc2NyZWVuIGFuZCAobWluLXdpZHRoOiAxMDI0cHgpIHsKICAuXzFuMHdxanYwIHsKICAgIG1hcmdpbjogMCAwIHZhcigtLV8xOW1yaGFhMXUpIDA7CiAgfQp9%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fcomponents%2Fbedge.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA%2B1SwU7DMAy99yuMuFCJVM0EY2R3fgN5SdZGS5PKSacNxL%2BjZt0YZQcEmtiBSIksx89%2Btl8RwvRuXZfwmgEoE1qLWwHGWeM0W1gvV%2FPsLSv2UXgLB3vRxehdAi69iyyYFy2gLGYP96SbeQaQktTaVHUUwIvJ3p%2FCl9gYuxWwRrph7Jk%2FNlQjlnkC6hg1sdCiNK7qk5b8gJbeehrjtgl3aGBgDiA7Cn10642LmnrXAuWqIt85xYZU10%2Fp9J8tKpVKztoN8Gn%2FlIOVsJ6UJkaoTBfGHLhPJBqkyjgBPW463HIHj3oTmdLSE0bjnQDnnYYr07SeIrqYYghdMLtvtHZUY6LysUfmxysStV9rAkx7%2BdrqZ%2BwqPxroMIUxnbNT5v8SuigJ8UFC5xTQ71lO%2Fkw1p9v8oW5OjfPMAvrJ2L%2Bju4%2FVXLSA3gFCk%2BAp8gYAAA%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2FwebLawsAndRules%2FPageTypes%2FDocumentsPage%2FDocumentsPage.css.tsx.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA72Uz3KbMBDG736K7fQSH8QAsZ1YufTU18gItAY16M9IS23a8bt3kEljMG6cmUw5wMxqV%2FvbT59InrO2zjsKKfxeAGjhK2U4pO4AoiULP4W%2FY%2Bw522pfC5GFZQw%2FLY6L5LUyi5VOSKlMFUufFgCNCsQCdQ0y6hxyMNZgvyBVcI3oOOwajJn9l%2B29cBz6dx%2F60QZSu46V1hAaOuWyQMLTqPUqtt5ZQyyoX8ghSx7WHvUJwCCrUVU1cciHYEzdCa2ajk9mWy37hNI21k%2BXurh00oaRdX%2BHHEKFJbJ6WpXZWPYmzAh9fYk%2BS3420T%2Fg1%2F8XfjM2C6SX1ZDCF6Wd9STM%2BNAeYnFhD%2F3gcfPCeomeFXbgOrC9klRzeNwMrIUoXypvWyPZMOTX7%2FEZQc6OMGzuhVRtuJIz70mpPJakrOHg7b4Pi0ZVhilCHTiUaAj9u3YFqHprT%2Fqa5W1HcKbbY9Rt9oyzl3HqdmquPMnn78Wb6ZAIPQtOlFFLliZpfoPzPv%2FamBnnfdMolYBQekQDwki408q82iRL85U7LOPQk3%2FDNRWu6vCOEjdocTyn2F5S3M8j3I%2FgZhg2H4M4%2FgEfEbmQ2wUAAA%3D%3D%22%7D"
export var bedge = 'ss64vh1';
export var bedgeActive = 'ss64vh2';
export var componetHeader = '_1uh2yts8 _1n0wqjv0';
export var componetHeaderH1 = '_1uh2yts9';
export var faqCategory = '_1uh2yts4';
export var faqSelect = '_1uh2yts7';
export var faqSubCategory = '_1uh2yts5';
export var faqTopTags = '_1uh2yts0';
export var faqTopTags_ul = '_1uh2yts1';
export var faqTxtContent = '_1uh2yts6';