import { DefaultLayout } from "@components/webPeople/Layouts/DefaultLayout/DefaultLayout";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import { StructuredContenBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock";
import { ImageBlock } from "@components/blocks/ImageBlock/ImageBlock";
import { AttachedDocumentsBlock } from "@components/blocks/AttachedDocumentsBlock/AttachedDocumentsBlock";
import classNames from "classnames";
import { RADIUS } from "@consts/roundCorners";
import { ASPECT_RATIO } from "@consts/aspectRatio";
import { componentHeader, componentHeader_h1, imageHolder, block } from "./PersonPage.css";
export const PersonPage = ({
  page,
  allPages
}: any) => {
  return <DefaultLayout page={page} allPages={allPages} data-sentry-element="DefaultLayout" data-sentry-component="PersonPage" data-sentry-source-file="PersonPage.tsx">
      <>
        {/** hlavicka */}
        <div className={classNames(componentHeader)}>
          <h1 className={classNames(componentHeader_h1)}>{page.title}</h1>
          <HTMLBlock HTML={page.perex} data-sentry-element="HTMLBlock" data-sentry-source-file="PersonPage.tsx" />
        </div>

        {/** fotka */}
        <div className={classNames(imageHolder)}>
          <ImageBlock cropAspectRatio={ASPECT_RATIO.SQUARE} radius={RADIUS.CIRCLE} image={{
          downloadUrl: page.photo.downloadUrl,
          width: page.photo.width,
          height: page.photo.height,
          title: page.photo.title
        }} data-sentry-element="ImageBlock" data-sentry-source-file="PersonPage.tsx" />
        </div>

        {/** strukturovany obsah */}
        <div className={classNames(block)}>
          <StructuredContenBlock content={page.structuredContent} data-sentry-element="StructuredContenBlock" data-sentry-source-file="PersonPage.tsx" />
        </div>

        {/** pripojene soubory */}
        {page.attachedDocuments?.length > 0 ? <AttachedDocumentsBlock documents={page.attachedDocuments.map((item: any) => item.value)} /> : null}
      </>
    </DefaultLayout>;
};