import React from "react";
import { IPageBase } from "@utils/pages";
import { HomePage } from "@components/core/PageTypes/UneditablePage/pages/HomePage/HomePage";
import { GlobalContactsPage } from "./pages/GlobalContactsPage/GlobalContactsPage";
interface IPageUneditable extends IPageBase {
  staticPage: string;
}
export type UneditablePageRegister = {
  [key: string]: React.ComponentType<any>;
};
export type UneditablePageProps = {
  page: IPageUneditable;
  allPages: any[];
  registerdUneditablePages?: UneditablePageRegister;
};

// registrovane staticke stranky
const staticPages: UneditablePageRegister = {
  HomePage: HomePage,
  GlobalContactsPage: GlobalContactsPage
};
export const UneditablePageBase = ({
  page,
  allPages,
  registerdUneditablePages
}: UneditablePageProps) => {
  const staticPageComponent = registerdUneditablePages ? registerdUneditablePages[page.staticPage] : null;
  return <>
      {staticPageComponent ? React.createElement(staticPageComponent, {
      page: page,
      allPages: allPages
    }) : <div>Stránka nenalezena</div>}
    </>;
};
export const UneditablePage = ({
  page,
  allPages
}: UneditablePageProps) => {
  return <UneditablePageBase page={page} allPages={allPages} registerdUneditablePages={staticPages} data-sentry-element="UneditablePageBase" data-sentry-component="UneditablePage" data-sentry-source-file="UneditablePage.tsx" />;
};