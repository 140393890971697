const Cell = ({
  value,
  isHeader = false
}: {
  value: string | number;
  isHeader: boolean;
}) => {
  if (isHeader) {
    return <th>{value}</th>;
  } else {
    return <td>{value}</td>;
  }
};
export const TableBlock = ({
  value
}: {
  value: any;
}) => {
  const getRows = (value: any) => {
    const data = [...value.data];
    !!value.firstRowIsTableHeader && data.shift();
    return data;
  };
  const data = getRows(value);
  return <>
      <table>
        {!!value?.tableCaption ? <caption>{value.tableCaption}</caption> : null}
        {value.firstRowIsTableHeader ? <thead>
            <tr>
              {value.data[0].map((cell: string, i: number) => <Cell key={`cell-0-${i}`} value={cell} isHeader={true} />)}
            </tr>
          </thead> : null}
        <tbody>
          {data.map((row: any[], i: number) => <tr key={`row-${i}`}>
              {row.map((cell: string, ii: number) => <Cell key={`cell-${i}-${ii}`} value={cell} isHeader={value.firstColIsHeader && ii == 0} />)}
            </tr>)}
        </tbody>
      </table>
    </>;
};