import { BaseContentPage } from '@components/webArchive/PageTypes/BaseContentPage/BaseContentPage';
const sectionId = 'webArchive';
const baseSectionPath = '/co-delame/archiv-ct';
const isThisSection = (page: any) => page.path.startsWith(baseSectionPath);
const pageTypes = [{
  id: 'BaseContentPage',
  component: BaseContentPage
}];
export const staticContentBlocks = [];
export const siteSection = {
  sectionId: sectionId,
  pageTypes: pageTypes,
  isThisSection: isThisSection,
  staticContentBlocks: staticContentBlocks
};