import React from "react";
import { LayoutProps } from "@components/core/Layouts";
import { Header } from "@components/layouts/components/Header/Header";
import { CTGlobalFooter } from "@components/layouts/components/CTGlobalFooter/CTGlobalFooter";
import { BreadcrumbNavigation } from "@components/layouts/components/BreadcrumbNavigation/BreadcrumbNavigation";
import { createBreadcrumbs } from "@utils/pages";
import { Content } from "@czechtv/components";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import { StructuredContenBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock";
import { AttachedDocumentsBlock } from "@components/blocks/AttachedDocumentsBlock/AttachedDocumentsBlock";
import { containerMain, containerContent } from "@components/core/Layouts/WithoutSideMenuLayout/WithoutSideMenuLayout.css";
import classNames from "classnames";
import { ctCouncilHeader_p, ctCouncilHeader_h1 } from "./CtCouncilHomePageLayou.css";
export const CtCouncilHomePageLayout: React.FC<LayoutProps> = ({
  page,
  allPages
}) => {
  return <>
      <Header data-sentry-element="Header" data-sentry-source-file="CtCouncilHomePageLayout.tsx" />
      <Content data-sentry-element="Content" data-sentry-source-file="CtCouncilHomePageLayout.tsx">
        {allPages ? <BreadcrumbNavigation items={createBreadcrumbs(page, allPages)} /> : null}
        <div className={classNames(containerMain)}>
          <div className={classNames(containerContent)}>
            {/** hlavicka */}
            <div className={classNames(ctCouncilHeader_p)}>
              <h1 className={classNames(ctCouncilHeader_h1)}>{page.title}</h1>
              <HTMLBlock HTML={page.perex} data-sentry-element="HTMLBlock" data-sentry-source-file="CtCouncilHomePageLayout.tsx" />
            </div>

            {/** strukturovany obsah */}
            <StructuredContenBlock content={page.structuredContent} data-sentry-element="StructuredContenBlock" data-sentry-source-file="CtCouncilHomePageLayout.tsx" />

            {/** pripojene soubory */}
            {page.attachedDocuments?.length > 0 ? <AttachedDocumentsBlock documents={page.attachedDocuments.map((item: any) => item.value)} /> : null}
          </div>
        </div>
      </Content>
      <CTGlobalFooter data-sentry-element="CTGlobalFooter" data-sentry-source-file="CtCouncilHomePageLayout.tsx" />
    </>;
};