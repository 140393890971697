import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fglobal%2Fglobal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42WTW%2FbMAyG7%2FsVPK6AUoiULNnabbcdBgzLsOvgJE7qLl91kjbtsP8%2BMLGU1lTXIrEO8iO%2BIl%2BJSeg2mz38%2BQAwGv3CatXd1LUOMN4cumkzrte7b91m9L1ZHJZ1p2BXr3ejXdO1808vVuBwxbhZtZ83y9nrS2i45Mu%2BXrbT1xeYAD9%2BMjyedk2zfntTdrDg%2F%2FspBvTXZtYeVq%2FzLkC3mNQfK6cAnVZARaEAr15Svqe04s%2Fwbdm%2F5aXxGTJVZIxXVCrjBFE%2Fi4LOZHQmPVF4RdoprEgg0x4xhYISFWDlZTazHkJyCssyo9REwlkui1GAJclA87hly1IaFZhM8RYpMaOACPthiN0kjKJcaSTWJoxjnIRtBrsN8HLi95sGLGPSFW%2BRj0KmdqsIWT4wp8JIaB0hXShgTktm0zPOKSAFVhLbFEXB%2BTsk7mJOxJU1vO0iE6iLgcqCEyPGMm7uUmqclreMZUT38cZwCOsVkM2U4BAPIqd%2F8txkPL%2BPCXgFpxtYZkr%2B8B7o%2BKxYp5OhM4V47CGrjFZOnoCnGOTkGmWSR31BkLNyGQZTtwCyCnLlQUpXh%2BucBsGZZDEjaRCcTYeX1S7DkCvSzea3l2HIuXQYLtcsYyDGzoh4qkgahlzskXyv4yOg2CQLryA%2BAop90pQK4iOgSSobF%2Bz8CGiaIEq1zQWbJa8oeZXjmnfGmyevit4myp1WXCSvimiTz3E37%2BTadEd88qrKcLc95%2F3ZpypXvNhPuZOwTy7nVWyphs4%2B5foJppbqzj6Z3N7XAcrtcTC5CYBOzG4DkBWzdwEMidkugNVidhfASrV9gEKqHQI4qXYfwEu1hwClVDsGKKXaY4BKqj0F7mzDadIBEIUeYQAkIUjE00KRTAA0QpJsALRSsgiAhZR0bImU9DwtJcsA6KVkFQBLKVkHwEpKTgKQlpLTANPDpJ2OJs1T23T83%2B0alb42ou3TjHPRq91gOv0Lev5br6%2FpCvT2CLg9Am2PIJXn%2BXXFZZ3JrlsEkEnfZA4%2BtbmDT7cB%2FNnAv%2F8Achf%2FrxAMAAA%3D%22%7D"
import "../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2Fblocks%2FSocialLinksBlock%2FSocialLinksBlock.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA8VVwW6jMBC95yssrVZqDkaYhqRxLnvqb1QGHGLF2K5xG7Kr%2FPsK24Ah0PSw0iKFiLFn3syb53F0yeSu2cfgzwqAiuiSCZhJY2SFwSfRTxC%2BoX2lT4Sg9%2FVhBUBG8nOp5YcoYC651Bj8eLVPu6hIUTBR3rkq5yp1QTXUpGAf9d0eafcUrFacXDHIuMzPh9VtFbkMkc3wKIWBNftNMUBRqmnV%2BnAmKDxRVp5Ma951drv5SCrGr1O01IL5AsZLV7vkqTBSYRCr5vCQHZd%2BT0A8eNiPvo7E1jGLjM7rkZdq7O8OyCXUR3wOejfG2tiVCyvMCQMUxz%2Fb8IY2BhLOSoEBp0cTlNZ%2BTsu1DMzU2oOkFqTv25FT697%2Bw4smCoP23ZosKGSGVrXbB2tDtAlibcOEJ6B12Bftmv1VXruHsTrJzCz2UV6c7DhtMECBfX8vx2eULChym%2FwXSYoZSfZ9YsIm6Y%2FZA2JFSAkJZ4XfP1ZkNi%2BJUf9zKgzVrbkk8wqb0VCPkE%2Fpj6OX3QL73yE%2FtnicGkM1rBXJHWNRPPR04FE1ACHXgOm4WhTV%2B3qUff1Z%2FhN5DpHG%2FUhdej584uXSRUx8u35VtGAE1LmmVAAiCvBUMQG913YTq2Ztg0fhLbE85t0NcRscNt6hG0Gpz2s8hWzOzjwaRLOXyOw5CCBTDxloR8huAi1qMAiw9QG%2BPBDKV3r7C5DSrjBABwAA%22%7D"
export var socialBlock = 'wbo7x90';
export var socialBlockBtn = 'wbo7x94';
export var socialBlockMultipleLinks = 'wbo7x9b';
export var socialBlockMultipleLinks_link = 'wbo7x9c';
export var socialBlockOneLink = 'wbo7x95';
export var socialBlockOneLinkIcon = 'wbo7x96';
export var socialBlockOneLinkIcon_svg = 'wbo7x97';
export var socialBlockOneLinkTxt = 'wbo7x98';
export var socialBlockOneLinkTxt_h4 = 'wbo7x99';
export var socialBlockOneLinkTxt_mr0 = 'wbo7x9a';
export var socialBlockTxtNoMargin = 'wbo7x93';
export var socialBlock_h3 = 'wbo7x91';
export var socialBlock_p = 'wbo7x92';