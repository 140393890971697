import { useState, useEffect } from "react";
//import Link from "next/link";
import { Header } from "@components/layouts/components/Header/Header";
import { BreadcrumbNavigation } from "@components/layouts/components/BreadcrumbNavigation/BreadcrumbNavigation";
import { Content } from "@czechtv/components";
import { useRouter } from "next/router";
import { VideoBlock } from "@components/blocks/VideoBlock/VideoBlock";
import classNames from "classnames";
import { AttachedDocumentsBlock } from "@components/blocks/AttachedDocumentsBlock/AttachedDocumentsBlock";
import { StructuredContenBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock";
import { Select } from "@czechtv/form";
import { LinkListBlock } from "@components/blocks/StructuredContentBlock/sublocks/LinkListBlock/LinkListBlock";
import { createBreadcrumbs } from "@utils/pages";
import { SideMenu, getSideMenuItems, getRootPage } from "@components/layouts/components/SideMenu/SideMenu";
import { CTGlobalFooter } from "@components/layouts/components/CTGlobalFooter/CTGlobalFooter";
import { getUpdatedMeetings } from "@components/webCtCouncil/utils";
import { compareDateStrings, dayjsCzech, formatDateTime } from "@utils/datetime";
import { containerMain, containerSideMenu, containerContent } from "@components/core/Layouts/WithSideMenuLayout/WithSideMenuLayout.css";
import { CtCouncilH1, CtCouncilH2, VideoBlockWrap, VideoBlockWrap_alert, VideoBlockWrap_info, YearSelectWrap, NavigationWrap, SelectWithGap } from "./CtCouncilMeetingPage.css";

// vraci rok z datoveho stringu
const getYearFromDate = (dateString: string | undefined) => dateString && dateString?.split && dateString.split("-")[0];

// vraci rok pro event
const getMeetingOrPageDate = (meetingOrPage: any) => {
  if (meetingOrPage.isYearSummaryPage) {
    return meetingOrPage.path.split("/")?.pop();
  }
  if (getYearFromDate(meetingOrPage.eventDate)) {
    return getYearFromDate(meetingOrPage.eventDate);
  }
};

// vraci stranky s vypisem roku
const getYearSummaryPages = (meetings: any[]) => meetings.filter((meeting: any) => meeting.isYearSummaryPage);

// vraci nejnovejsi meeting v zadanem roce
const getYearLastMeeting = (year: string, meetings: any[]): any => {
  const yearMeetings = meetings.filter((meeting: any) => getYearFromDate(meeting?.eventDate) == year);
  if (yearMeetings.length > 0) return yearMeetings[0];

  // nebyly nalezeny zadne meetingy > hleda se v tech neaktualnejsich
  const newestSummaryPage = getYearSummaryPages(meetings)[0];
  return getYearLastMeeting(getMeetingOrPageDate(newestSummaryPage), meetings);
};

// vraci options pro select vyberu roku
const getYearNavigationOptions = (meetings: any[]) => getYearSummaryPages(meetings).map((meeting: any) => {
  return {
    text: meeting.year,
    id: meeting.path
  };
});

// vraci meetingy pro konkretni rok
const getYearMeetings = (year: string, meetings: any[]) => {
  const yearPages = meetings.filter((meeting: any) => !meeting.isYearSummaryPage && `${meeting.year}` === year);
  return yearPages.map((meeting: any) => {
    return {
      url: meeting.path,
      title: meeting.menuTitle
    };
  });
};

// vytvori polozky pro side menu
const createSideMenuItems = (page: any, allPages: any[]) => {
  let slugs = getYearLastMeeting(page.year, getUpdatedMeetings(page.meetings)).path.split("/");
  slugs.pop();
  const rootPage = getRootPage(page, allPages);
  const sideMenuItems = getSideMenuItems({
    pages: allPages,
    rootPage: rootPage,
    disabledSubpaths: [slugs.join("/")]
  });
  return sideMenuItems;
};
export const CtCouncilMeetingPage = ({
  page,
  allPages
}: any) => {
  const router = useRouter();
  const [updatedMeetings, setUpdatedMeetings] = useState<any[]>(getUpdatedMeetings(page.meetings));

  // aktualizace dat
  useEffect(() => {
    setUpdatedMeetings(getUpdatedMeetings(page.meetings));
  }, [page, router.asPath]);

  // redirect na meeting
  const redirectToMeeting = (meeting: any | undefined) => {
    meeting?.path && router.push(meeting.path);
  };

  // reakce na stranku rocniho vypisu
  useEffect(() => {
    if (page.isYearSummaryPage) {
      redirectToMeeting(getYearLastMeeting(getMeetingOrPageDate(page), updatedMeetings));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, updatedMeetings]);

  // reakce na zmenu roku
  const changeYearHandler = (year: string) => {
    redirectToMeeting(getYearLastMeeting(year, updatedMeetings));
  };

  // summary page se nezobrazuje a ceka na redirect
  if (page.isYearSummaryPage) {
    return null;
  }
  return <>
      <Header data-sentry-element="Header" data-sentry-source-file="CtCouncilMeetingPage.tsx" />
      <Content data-sentry-element="Content" data-sentry-source-file="CtCouncilMeetingPage.tsx">
        {allPages ? <BreadcrumbNavigation items={createBreadcrumbs(page, allPages)} /> : null}
        <div className={classNames(containerMain)}>
          <div className={classNames(containerSideMenu)}>
            {allPages ? <SideMenu items={createSideMenuItems(page, allPages)} rootPage={getRootPage(page, allPages)} /> : null}
          </div>
          <div className={classNames(containerContent)}>
            <>
              <h1 className={classNames(CtCouncilH1)}>Archiv proběhlých zasedání rady ČT</h1>

              {/** vypis rocnich vypisu pro vyhledavace - BEGIN */}
              {/**
               <div>
                <p>
                  {getYearSummaryPages(updatedMeetings).map((meeting) => (
                    <span key={meeting.path}>
                      <Link href={meeting.path}>
                        {meeting.title}
                      </Link>
                    </span>
                  ))}
                </p>
               </div>
               */}
              {/** vypis rocnich vypisu pro vyhledavace - END */}

              {/** navigace - BEGIN */}
              <section className={classNames(NavigationWrap)}>
                <div className={classNames(YearSelectWrap)}>
                  <span>
                    <strong>Vyberte rok:</strong>
                  </span>
                  <div className={classNames(SelectWithGap)}>
                    <Select options={getYearNavigationOptions(updatedMeetings)} selectedOption={getMeetingOrPageDate(page)} onChange={(value: string) => changeYearHandler(value)} placeholder={""} name={""} data-sentry-element="Select" data-sentry-source-file="CtCouncilMeetingPage.tsx" />
                  </div>
                </div>

                <LinkListBlock value={getYearMeetings(getMeetingOrPageDate(page), updatedMeetings)} linkListStyle="BUTTONS" data-sentry-element="LinkListBlock" data-sentry-source-file="CtCouncilMeetingPage.tsx" />
              </section>
              {/** navigace - END */}

              <ContentComponent meeting={page} data-sentry-element="ContentComponent" data-sentry-source-file="CtCouncilMeetingPage.tsx" />
            </>
          </div>
        </div>
      </Content>
      <CTGlobalFooter data-sentry-element="CTGlobalFooter" data-sentry-source-file="CtCouncilMeetingPage.tsx" />
    </>;
};
const ContentComponent = ({
  meeting
}: any) => {
  const today = dayjsCzech().format("YYYY-MM-DD");
  const inPast = compareDateStrings(meeting.eventDate, today) < 0;
  return <>
      <h2 className={classNames(CtCouncilH2)}>{meeting.title}</h2>

      {/** zaznam ze zasedani */}
      {meeting.meetingRecord?.id && <div className={classNames(VideoBlockWrap)}>
          {!inPast && meeting.eventDate && new Date(meeting.eventDate).setHours(13, 0, 0, 0) >= new Date().getTime() && <div className={classNames(VideoBlockWrap_alert)}>
                Přímý přenos se spustí{" "}
                <strong>{meeting.eventDate && formatDateTime(meeting.eventDate)} ve 12:00</strong>.
              </div>}
          <VideoBlock videoID={meeting.meetingRecord.id} videoIdType={meeting.meetingRecord.videoIdType} previewImageUrl={meeting.meetingRecord.thumbnail?.downloadUrl} />
          {!inPast && meeting.eventDate && new Date(meeting.eventDate).setHours(13, 0, 0, 0) >= new Date().getTime() && <p className={classNames(VideoBlockWrap_info)}>
                Nestíháte sledovat přímý přenos? Po skončení živého vysílání naleznete jeho záznam v
                archivu.{" "}
              </p>}
        </div>}

      {/** pripojene soubory */}
      {meeting.attachedDocuments?.length > 0 && <AttachedDocumentsBlock documents={meeting.attachedDocuments.map((item: any) => item.value)} />}

      {/** strukturovany obsah */}
      {meeting?.structuredContent && <StructuredContenBlock content={meeting.structuredContent} />}

      {/** projednane materialy */}
      {meeting.discussedMaterials?.length > 0 && <div>
          <h2 className={classNames(CtCouncilH2)}>Projednávané materiály</h2>
          <AttachedDocumentsBlock documents={meeting.discussedMaterials.map((item: any) => item.value)} />
        </div>}
    </>;
};