import classNames from "classnames";
import { ImageBlock } from "@components/blocks/ImageBlock/ImageBlock";
import { VideoBlock } from "@components/blocks/VideoBlock/VideoBlock";
import Link from "next/link";
import { ASPECT_RATIO } from "@consts/aspectRatio";
import { headerDecoration, topVideosItem, topVideosItem_h3, topVideosItem_h3_span, topVideosItem_p, topVideosItem_a, topVideosTxt, topVideosTxt_p, topVideosVideo } from "./MediaCardBlock.css";
export const MediaCardBlock = ({
  item
}: {
  item: any;
}) => {
  // nebyl zadan obrazek ani video
  if (!item?.video == false && !item?.image == false) return <></>;
  return <div className={topVideosItem} data-sentry-component="MediaCardBlock" data-sentry-source-file="MediaCardBlock.tsx">
      {/** obrazek nebo video */}
      <div className={topVideosVideo}>
        {!!item?.video ? <>
            <VideoBlock videoID={item.video} />
          </> : <>
            <ImageBlock cropAspectRatio={ASPECT_RATIO.HORIZONTAL_16_9} image={{
          downloadUrl: item.image.downloadUrl,
          width: item.image.width,
          height: item.image.height,
          title: item.image.title
        }} />
          </>}
      </div>

      <div className={topVideosTxt}>
        <h3 className={topVideosItem_h3}>
          {item?.title ? item.title : null}
          {item?.subtitle ? <span className={topVideosItem_h3_span}>{item.subtitle}</span> : null}
        </h3>
        <div className={headerDecoration}></div>
        {item?.text ? <p className={classNames(topVideosItem_p, topVideosTxt_p)}>{item.text}</p> : null}

        {/** linky */}
        {!!item?.links && item.links.length > 0 ? <div>
            {item.links.map((link: any, i: number) => <Link key={i} href={link.url} target={link?.target} className={topVideosItem_a}>
                {link.title}
              </Link>)}
          </div> : null}
      </div>
    </div>;
};