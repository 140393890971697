import { WithoutSideMenuLayout } from "@components/core/Layouts/WithoutSideMenuLayout/WithoutSideMenuLayout";
import { StructuredContenBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock";
import { AttachedDocumentsBlock } from "@components/blocks/AttachedDocumentsBlock/AttachedDocumentsBlock";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import classNames from "classnames";
import { componetHeaderH1, componetHeader } from "./BaseContentPage.css";
export const BaseContentPage = ({
  page,
  allPages
}: any) => {
  return <WithoutSideMenuLayout page={page} allPages={allPages} data-sentry-element="WithoutSideMenuLayout" data-sentry-component="BaseContentPage" data-sentry-source-file="BaseContentPage.tsx">
      <>
        {/** hlavicka */}
        <div className={classNames(componetHeader)}>
          <h1 className={classNames(componetHeaderH1)}>{page.title}</h1>
          <HTMLBlock HTML={page.perex} data-sentry-element="HTMLBlock" data-sentry-source-file="BaseContentPage.tsx" />
        </div>

        {/** strukturovany obsah */}
        <StructuredContenBlock content={page.structuredContent} data-sentry-element="StructuredContenBlock" data-sentry-source-file="BaseContentPage.tsx" />

        {/** pripojene soubory */}
        {page.attachedDocuments?.length > 0 ? <AttachedDocumentsBlock documents={page.attachedDocuments.map((item: any) => item.value)} /> : null}
      </>
    </WithoutSideMenuLayout>;
};