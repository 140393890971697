import { ImageBlock } from "@components/blocks/ImageBlock/ImageBlock";
import { ASPECT_RATIO } from "@consts/aspectRatio";
import { RADIUS } from "@consts/roundCorners";
import { useMinTablet768 } from "@czechtvPackagesOverrides/styles/fromCTV/index";
import { galleryGrid, galleryItem, galleryItemCount, galleryItemCount_span } from "./MediaList.css";
interface MediaListProps {
  media: any[];
  displayedCount?: number;
  cropAspectRatio?: undefined | ASPECT_RATIO;
}
export const MediaList = ({
  media,
  cropAspectRatio = undefined
}: MediaListProps) => {
  const isDesktop = useMinTablet768();
  let maxGallery = 3;
  isDesktop ? maxGallery = 3 : maxGallery = 2;

  //const prepareMedia = () => displayedCount ? media.slice(0, displayedCount) : media;
  const prepareMedia = () => media.slice(0, maxGallery);
  return <div className={galleryGrid} data-sentry-component="MediaList" data-sentry-source-file="MediaList.tsx">
      {prepareMedia().map((medium: any, index) => <button className={galleryItem} key={medium.id}>
          {index === prepareMedia().length - 1 && media.length > maxGallery ? <div className={galleryItemCount}>
              <p>
                {media.length - maxGallery}+
                <span className={galleryItemCount_span}>
                  {media.length - maxGallery == 1 ? "další médium" : null}
                  {media.length - maxGallery > 1 && media.length - maxGallery < 5 ? "další média" : null}
                  {media.length - maxGallery > 5 ? "dalších médií" : null}
                </span>
              </p>
            </div> : null}

          {/** image */}
          {medium.type == "image" ? <ImageBlock image={{
        downloadUrl: medium.value.image.downloadUrl,
        width: medium.value.image.width,
        height: medium.value.image.height,
        title: medium.value.image.title
      }} cropAspectRatio={cropAspectRatio} radius={RADIUS.BASE} title={medium.value.title} subtitle={medium.value.subtitle} /> : null}

          {/** video */}
          {medium.type == "video" ? <></> : null}
        </button>)}
    </div>;
};