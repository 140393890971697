import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import classNames from "classnames";
import { BASE_LINK, BUTTONS_linksLink, BUTTONS_mainUl, DEFAULT_linksLink, DEFAULT_mainUl } from "@components/blocks/StructuredContentBlock/sublocks/LinkListBlock/LinkListBlock.css";
import { vars as globalVars } from "@czechtvPackagesOverrides/styles/vanilla/global/global.css";
type TLinkListStyle = "DEFAULT" | "BUTTONS";
export const LinkListBlock = ({
  value,
  linkListStyle = "DEFAULT"
}: {
  value: any;
  linkListStyle?: TLinkListStyle;
}) => {
  const mainUl = linkListStyle === "BUTTONS" ? BUTTONS_mainUl : DEFAULT_mainUl;
  const linksLink = linkListStyle === "BUTTONS" ? BUTTONS_linksLink : DEFAULT_linksLink;
  const router = useRouter();
  return <ul className={classNames(mainUl)} data-sentry-component="LinkListBlock" data-sentry-source-file="LinkListBlock.tsx">
      {value.map((item: any, i: number) => <li key={i} className={classNames(linksLink, item.type)}>
          <Link href={item.url} target={item?.target} className={classNames(BASE_LINK)} style={(item.url == router.asPath || `${item.url}/` == router.asPath) && linkListStyle === "BUTTONS" ? {
        backgroundColor: globalVars.colors.red_base,
        color: globalVars.colors.white
      } : {}}>
            {item.title}
          </Link>
        </li>)}
    </ul>;
};