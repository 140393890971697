import Link from "next/link";
import { ImageBlock } from "@components/blocks/ImageBlock/ImageBlock";
import { ASPECT_RATIO } from "@consts/aspectRatio";
import { RADIUS } from "@consts/roundCorners";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import { ButtonPrimary } from "@czechtvPackagesOverrides/components/ButtonPrimary";
import { headerDecorationStyle, btnContainer } from "./DisplayColumnsStrip.css";
interface IDisplayColumnsStripItem {
  headline?: string;
  headlineLink?: any;
  image?: any;
  text?: string;
  links?: any[];
}
interface DisplayColumnsStripProps {
  childrenSize: string;
  childrens: IDisplayColumnsStripItem[];
  link?: null | any;
}
export const DisplayColumnsStrip = ({
  childrenSize,
  childrens,
  link = null
}: DisplayColumnsStripProps) => {
  return <div data-sentry-component="DisplayColumnsStrip" data-sentry-source-file="DisplayColumnsStrip.tsx">
      <div>
        {childrens.map((item: IDisplayColumnsStripItem, i: number) => <DisplayColumnsStripItem key={i} item={item} itemSize={childrenSize} link={link} />)}
      </div>
    </div>;
};
interface DisplayColumnsStripItemProps {
  item: IDisplayColumnsStripItem;
  itemSize: string;
  link?: null | any;
}
const DisplayColumnsStripItem = ({
  item,
  itemSize,
  link = null
}: DisplayColumnsStripItemProps) => {
  return <div className={`item_size_${itemSize}`} data-sentry-component="DisplayColumnsStripItem" data-sentry-source-file="DisplayColumnsStrip.tsx">
      {/** nadpis */}
      {item?.headline ? <div className={"headline"}>
          {item?.headlineLink ? <Link href={item.headlineLink.url}>
              <h3>{item.headline}</h3>
            </Link> : <h3>{item.headline}</h3>}
          <div className={headerDecorationStyle}></div>
        </div> : null}

      {/** text */}
      {item?.text ? <div className={"text"}>
          <HTMLBlock HTML={item.text} />
        </div> : null}

      {/** linky */}
      {item?.links && !!item?.links?.length ? <div className={"links"}>
          <ul>
            {item.links.map((linkItem: any, i: number) => <li key={i}>
                <Link href={linkItem.url}>{linkItem.title}</Link>
              </li>)}
          </ul>
        </div> : null}

      {/** obrazek */}
      {item?.image ? <div className={"image"}>
          <ImageBlock image={{
        downloadUrl: item.image.downloadUrl,
        width: item.image.width,
        height: item.image.height,
        title: item.image.title
      }} cropAspectRatio={ASPECT_RATIO.HORIZONTAL_16_9} radius={RADIUS.BASE} />
        </div> : null}

      {/** hlavni link */}
      {link ? <div className={btnContainer}>
          <ButtonPrimary anchor href={link.url}>
            {link.title}
          </ButtonPrimary>
        </div> : null}
    </div>;
};