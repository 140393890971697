import classNames from "classnames";
import { StaticContentBlockComponentProps } from "@components/blocks/StructuredContentBlock/sublocks/StaticContentBlock/StaticContentBlock";
import { MediaCardBlock } from "@components/blocks/MediaCardBlock/MediaCardBlock";
import imgSoucek from "./images/jan_soucek_portrait.jpg";
import imgMockup from "./images/ct_mockup.webp";
import { topVideos, topVideosBoxes } from "@components/webCareer/StaticContentComponents/YouShouldSee/YouShouldSee.css";
const videos = {
  items: [{
    title: "Jan Souček",
    subtitle: "Generální ředitel",
    text: "Česká televize je zaměstnavatel s jasným posláním. Naším úkolem je poskytovat divákům důvěryhodnou a kvalitní veřejnoprávní službu. Stojíme o každého, kdo se na naší misi chce podílet.",
    image: {
      downloadUrl: imgSoucek.src,
      width: 556,
      height: 316
    }
  }, {
    title: "Hodnoty, poslání a vize České televize",
    subtitle: "Za čím si stojíme a k čemu můžete svou prací přispět.",
    text: "Poskytujeme nezávislý, vysoce kvalitní a jedinečný obsah, který informuje, vzdělává a baví.",
    image: {
      downloadUrl: imgMockup.src,
      width: 556,
      height: 316
    },
    links: [{
      url: "./kariera/hodnoty-poslani-a-vize-ceske-televize/",
      title: "Zobrazit více"
    }]
  }]
};
export const YouShouldSee = ({}: StaticContentBlockComponentProps) => {
  return <article className={classNames(topVideos)} data-sentry-component="YouShouldSee" data-sentry-source-file="YouShouldSee.tsx">
      <div className={classNames(topVideosBoxes)}>
        {videos.items.map((item, index) => <MediaCardBlock key={index} item={item} />)}
      </div>
    </article>;
};