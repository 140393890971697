import { TechnologyHomePage } from '@components/webTechnology/PageTypes/TechnologyHomePage/TechnologyHomePage';
import { BaseContentPage } from '@components/webTechnology/PageTypes/BaseContentPage/BaseContentPage';
import { staticContentBlocks } from './StaticContentComponents/index';
const sectionId = 'webTechnology';
const baseSectionPath = '/jak-sledovat';
const isThisSection = (page: any) => page.path.startsWith(baseSectionPath);
const pageTypes = [{
  id: 'TechnologyHomePage',
  component: TechnologyHomePage
}, {
  id: 'BaseContentPage',
  component: BaseContentPage
}];
export { staticContentBlocks };
export const tvSignalBasePath = `${baseSectionPath}/televizni-signal`;
export const internetBroadcastBasePath = `${baseSectionPath}/internetove-vysilani`;
const getSideMenuRootPage = (page: any, pages: any[]): any | undefined => {
  if (!!siteSection.isThisSection(page)) {
    let rootPage = pages.find(p => p.path == baseSectionPath);
    [tvSignalBasePath, internetBroadcastBasePath].map(subSectionBasePath => {
      if (page.path.startsWith(subSectionBasePath)) {
        rootPage = pages.find(p => p.path == subSectionBasePath);
      }
    });
    return rootPage;
  }
};
export const siteSection = {
  sectionId: sectionId,
  pageTypes: pageTypes,
  isThisSection: isThisSection,
  staticContentBlocks: staticContentBlocks,
  getSideMenuRootPage: getSideMenuRootPage
};