import { ButtonSecondary } from "@czechtvPackagesOverrides/components/ButtonSecondary";
import { iconFromFilename, fileTypeHumanFromFilename, getHumanFileSize } from "./utils";
import { listWrap, listWrap_ul, listWrap_li, listWrapIcon, listWrapCont, listWrapCont_p, listWrapCont_span, listWrapLink } from "./AttachedDocumentsBlock.css";
interface IDocumentLink {
  id: number;
  title: string;
  downloadUrl: string;
  fileSize: number;
}
interface AttachedDocumentsBlockProps {
  documents: IDocumentLink[];
}
export const AttachedDocumentsBlock = ({
  documents
}: AttachedDocumentsBlockProps) => {
  return <>
      {documents.length ? <div className={listWrap}>
          <ul className={listWrap_ul}>
            {documents.map((document: IDocumentLink) => <li key={document.id} className={listWrap_li}>
                <div className={listWrapIcon} dangerouslySetInnerHTML={{
            __html: iconFromFilename(document.downloadUrl)
          }}></div>
                <div className={listWrapCont}>
                  {document.title}
                  <p className={listWrapCont_p}>
                    <span className={listWrapCont_span}>
                      {fileTypeHumanFromFilename(document.downloadUrl)}
                    </span>
                    {getHumanFileSize(document.fileSize)}
                  </p>
                </div>
                <div className={listWrapLink}>
                  <ButtonSecondary size={"small"} anchor href={document.downloadUrl} download>
                    Stáhnout soubor
                  </ButtonSecondary>
                </div>
              </li>)}
          </ul>
        </div> : null}
    </>;
};