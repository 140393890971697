// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/vse-o-ct/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"9rGgYM6JVC0tjvbcjm2E3"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/vse-o-ct";import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: "https://62a982add21372ca7f2e19a32812e318@o4508486331793408.ingest.de.sentry.io/4508486419546192",

  // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
  //tracesSampleRate: 1,
  tracesSampleRate: 0,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
});
