import classNames from "classnames";
import Link from "next/link";
import { buttonPrimary } from "@czechtvPackagesOverrides/styles/vanilla/components/buttons.css";
import grBlogBgImg from "../../images/grBlogBg.jpg";
import grBlogBgImgMobile from "../../images/grBlogBg_mobile.jpg";
import { SmartImage } from "@components/reusables/SmartImage/SmartImage";
import { formatDateTime } from "@utils/datetime";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import { createArticleSummaryQueryString } from "@components/core/PageTypes/ArticleSummaryPage/ArticleSummaryPageContent";
import { contentBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock.css";
import { GeneralDirectorBlogArticle, GeneralDirectorBlogArticleDate, GeneralDirectorBlogArticleLink, GeneralDirectorBlogArticles, GeneralDirectorBlogArticleTitle, GeneralDirectorBlogContent, GeneralDirectorBlogHeading, GeneralDirectorBlogImageBackground, GeneralDirectorBlogImageBackgroundWrapper, GeneralDirectorBlogImageDesktop, GeneralDirectorBlogImageMobile, GeneralDirectorBlogPerex } from "@components/core/PageTypes/HomePage/components/GeneralDirectorBlog/GeneralDirectorBlog.css";
export const GeneralDirectorBlog = ({
  value
}: {
  value: any;
}) => {
  // pouze prvni dva clanky
  const items = value.pages.slice(0, 2);

  // link na source page vcetne query stringu
  const sourcePageUrlWithCategory = `${value.sourcePage.url}${createArticleSummaryQueryString(value.category)}`;
  return <div className={classNames(contentBlock, GeneralDirectorBlogContent)} data-sentry-component="GeneralDirectorBlog" data-sentry-source-file="GeneralDirectorBlog.tsx">
      <div className={classNames(GeneralDirectorBlogImageBackgroundWrapper)}>
        <div className={classNames(GeneralDirectorBlogImageDesktop)}>
          <SmartImage className={classNames(GeneralDirectorBlogImageBackground)} src={grBlogBgImg.src} alt="Blog generálního ředitele" width={grBlogBgImg.width} height={grBlogBgImg.height} data-sentry-element="SmartImage" data-sentry-source-file="GeneralDirectorBlog.tsx" />
        </div>
        <div className={classNames(GeneralDirectorBlogImageMobile)}>
          <SmartImage className={classNames(GeneralDirectorBlogImageBackground, GeneralDirectorBlogImageMobile)} src={grBlogBgImgMobile.src} alt="Blog generálního ředitele" width={grBlogBgImgMobile.width} height={grBlogBgImgMobile.height} data-sentry-element="SmartImage" data-sentry-source-file="GeneralDirectorBlog.tsx" />
        </div>
      </div>
      <div className={classNames(GeneralDirectorBlogPerex)}>
        <h2 className={classNames(GeneralDirectorBlogHeading)}>Blog generálního ředitele</h2>
        <p>
          Jaké výzvy a příležitosti přináší současný mediální svět<br />a jak se televize připravuje na budoucnost?
        </p>
        <Link href={sourcePageUrlWithCategory} className={classNames(buttonPrimary.normal)} data-sentry-element="Link" data-sentry-source-file="GeneralDirectorBlog.tsx">
          Všechny články
        </Link>
      </div>
      <div className={classNames(GeneralDirectorBlogArticles)}>
        {items.map((item: any) => <div key={item.id} className={classNames(GeneralDirectorBlogArticle)}>
            <h3 className={classNames(GeneralDirectorBlogArticleTitle)}>{item.title}</h3>
            <div className={classNames(GeneralDirectorBlogArticleDate)}>
              {formatDateTime(item.displayDate, "DATE")}
            </div>
            {item?.perex ? <HTMLBlock HTML={item.perex} /> : null}
            <Link href={item.url} className={classNames(GeneralDirectorBlogArticleLink)}>
              Celý článek
            </Link>
          </div>)}
      </div>
    </div>;
};