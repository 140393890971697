import React from "react";
import Link from "next/link";
import { WithSideMenuLayout } from "@components/core/Layouts/WithSideMenuLayout/WithSideMenuLayout";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import { ImageBlock } from "@components/blocks/ImageBlock/ImageBlock";
import classNames from "classnames";
import { RADIUS } from "@consts/roundCorners";
import { componentHeader, componentHeader_h1, subHeader, personList, personList_li, personItemInfo_h3, personItemInfo, personLink, HelperImgHAuto } from "./PersonListPage.css";
export const PersonListPage = ({
  page,
  allPages
}: any) => {
  return <WithSideMenuLayout page={page} allPages={allPages} data-sentry-element="WithSideMenuLayout" data-sentry-component="PersonListPage" data-sentry-source-file="PersonListPage.tsx">
      <>
        <div className={classNames(componentHeader)}>
          <h1 className={classNames(componentHeader_h1)}>{page.title}</h1>
          <HTMLBlock HTML={page.perex} data-sentry-element="HTMLBlock" data-sentry-source-file="PersonListPage.tsx" />
        </div>
        <h2 className={classNames(subHeader)}>{page.listTitle}</h2>

        <ul className={classNames(personList)}>
          {page.persons.map((person: any) => <li key={person.id} className={classNames(personList_li)}>
              {person.value.detailPage ? <Link href={person.value.detailPage.url} className={classNames(personLink)}>
                  <PersonListPageItem person={person.value} />
                </Link> : <PersonListPageItem person={person.value} />}
            </li>)}
        </ul>
      </>
    </WithSideMenuLayout>;
};
const PersonListPageItem = ({
  person
}: {
  person: any;
}) => {
  return <>
      {/* TODO: naposílá se  správný page.crop z adminu */}
      <div className={classNames(HelperImgHAuto)}>
        <ImageBlock image={{
        downloadUrl: person.photo.image.downloadUrl,
        width: person.photo.image.width,
        height: person.photo.image.height,
        title: person.photo.image.title
      }} cropAspectRatio={person.photo.crop} radius={RADIUS.CIRCLE} title={person.photo.title} subtitle={person.photo.subtitle} data-sentry-element="ImageBlock" data-sentry-source-file="PersonListPage.tsx" />
      </div>
      <div className={classNames(personItemInfo)}>
        {person?.photo.title ? <h3 className={classNames(personItemInfo_h3)}>{person.photo.title}</h3> : null}
        {person?.photo.subtitle ? <HTMLBlock HTML={person.photo.subtitle} /> : null}
      </div>
    </>;
};