import { useState } from "react";
import classNames from "classnames";
import { ButtonPrimary } from "@czechtvPackagesOverrides/components/ButtonPrimary";
import Link from "next/link";
import { ValidatedTextInput, ValidatedTextArea, ValidatedCheckbox, useForm, Form } from "@czechtv/form";
import { MessageSuccess, MessageFailed } from "@czechtv/components";
import { sendForm } from "@utils/form/sendForm";
import { FileInput, MB } from "@components/reusables/Form/inputs/FileInput/FileInput";
import { RadioInput } from "@components/reusables/Form/inputs/RadioInput/RadioInput";
import { formCareerCheck, formCareerCheckAgreed, formCareerCheckAgreedLink, formContact, formContactFileField, formContactFileFieldLabel, formContactFileFieldParagraph, formContactRadio, formContactRadioInput, formContactRadioParagraph, formContactSendButton, formContactSendGroup, formContactSubHead, formContactTextArea, formContactTitle, formContactTwoC } from "@components/webCareer/PageTypes/UneditablePage/pages/CareerContact/components/CareerFormContact/CareerFormContact.css";
interface FormValues {
  name: string;
  interest: string;
  phone: string;
  email: string;
  message: string;
  gdpr: string;
  attachement: null | File[];
}
export const CareerFormContact = () => {
  const form = useForm<FormValues>();
  const [disabled, setDisabled] = useState(false);
  const {
    register,
    formState: {
      isSubmitting,
      isValid
    },
    reset
  } = form;
  const onFormSubmit = async (data: FormValues) => {
    const url = "/vse-o-ct/api/forms/career-contact-form";
    try {
      await sendForm(url, data);
      resetForm();
    } catch (error: any) {
      console.log(error);
      throw new Error(error.message);
    } finally {
      setDisabled(true);
      setTimeout(() => {
        setDisabled(false);
      }, 10000);
    }
  };
  const resetForm = () => {
    reset();
  };
  return <article className={classNames(formContact)} id="formularKariera" data-sentry-component="CareerFormContact" data-sentry-source-file="CareerFormContact.tsx">
      <Form errorState={FormError} form={form} successState={FormSuccess} onSubmit={onFormSubmit} data-sentry-element="Form" data-sentry-source-file="CareerFormContact.tsx">
        {!disabled ? <>
            <h2 className={classNames(formContactTitle)}>Máte dotaz ohledně kariéry v ČT?</h2>
            <p className={classNames(formContactSubHead)}>
              Vyplňte formulář níže a my se vám co nejdříve ozveme.
            </p>
            <div className={classNames(formContactTwoC)}>
              <div>
                <ValidatedTextInput {...register("name", {
              required: "Zadejte prosím jméno a příjmení"
            })} label={{
              required: true,
              title: "Jméno a Příjmení"
            }} name="name" placeholder="" showValidationIcon
            // Adding default width to avoid hydration mismatch
            style={{
              width: "100%"
            }} />
              </div>
              <div className={classNames(formContactRadio)}>
                <p className={classNames(formContactRadioParagraph)}>
                  Co vás zajímá? <span>*</span>
                </p>
                <RadioInput {...register("interest", {
              required: "Vyberte možnost"
            })} value="Pracovní místo" id="interest1" className={classNames(formContactRadioInput)} />
                <label htmlFor="interest1">Pracovní místo</label>
                <RadioInput {...register("interest", {
              required: "Vyberte možnost"
            })} value="Stáž" id="interest2" className={classNames(formContactRadioInput)} />
                <label htmlFor="interest2">Stáž</label>
                <RadioInput {...register("interest", {
              required: "Vyberte možnost"
            })} value="Něco jiného" id="interest3" className={classNames(formContactRadioInput)} />
                <label htmlFor="interest3">Něco jiného</label>
              </div>
            </div>
            <div className={classNames(formContactTwoC)}>
              <div>
                <ValidatedTextInput {...register("phone", {
              required: {
                value: true,
                message: "Zadejte telefon"
              },
              pattern: {
                value: /^(\+420|00420)? ?(\d{3} ?){3}$/,
                message: "Telefon který jste zadali je ve špatném formátu"
              }
            })} autoComplete="phone" label={{
              required: true,
              title: "Telefon"
            }} type="tel" showValidationIcon={true} style={{
              width: "100%"
            }} />
              </div>
              <div>
                <ValidatedTextInput {...register("email", {
              required: {
                value: true,
                message: "Zadejte e-mail"
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "E-mail, který jste zadali, je ve špatném formátu. Zadejte platný e-mail."
              }
            })} autoComplete="email" label={{
              required: true,
              title: "E-mail"
            }} showValidationIcon style={{
              width: "100%"
            }} />
              </div>
            </div>
            <div className={classNames(formContactTextArea)}>
              <ValidatedTextArea {...register("message", {
            required: {
              value: true,
              message: "Vyplňte zprávu"
            }
          })} label={{
            required: true,
            title: "Zpráva nebo dotaz"
          }} />
            </div>
            <div className={classNames(formContactFileField)}>
              <label className={classNames(formContactFileFieldLabel)} htmlFor="attachement">
                Nahrajte Váš životopis nebo jiný dokument <span>*</span>
              </label>
              <div>
                <FileInput {...register("attachement", {
              required: {
                value: true,
                message: "Nahrajte prosím životopis"
              }
            })} required={true} accept=".doc,.png,.jpg,.jpeg,.docx,.pdf" maxSize={10 * MB} />
              </div>
              <p className={classNames(formContactFileFieldParagraph)}>Přijímáme všechny přílohy typu JPG, PNG, PDF do velikosti 10 Mb.</p>
            </div>
            <div className={classNames(formContactSendGroup)}>
              <div className={classNames(formCareerCheck)}>
                <ValidatedCheckbox {...register("gdpr", {
              required: {
                value: true,
                message: "Pro odeslání zprávy musíte souhlasit se zpracováním osobních údajů"
              }
            })} item={{
              id: "gdpr",
              title: ""
            }} />{" "}
                <span className={classNames(formCareerCheckAgreed)}>
                  Souhlasím se{" "}
                  <Link className={classNames(formCareerCheckAgreedLink)} href="./gdpr/" rel="noreferrer" target="_blank">
                    zpracováním osobních údajů
                  </Link>
                </span>
              </div>
              <ButtonPrimary disabled={isSubmitting || !isValid || disabled} type="submit" waiting={isSubmitting} className={classNames(formContactSendButton)}>
                Odeslat
              </ButtonPrimary>
            </div>
          </> : null}
      </Form>
    </article>;
};
const FormError = ({
  error
}: {
  error: unknown;
}) => <>
    <MessageFailed description={error instanceof Error ? error.message : "Vyskytla se neočekávaná chyba"} data-sentry-element="MessageFailed" data-sentry-source-file="CareerFormContact.tsx" />
    <br />
  </>;
const FormSuccess = () => <>
    <MessageSuccess description={"Formulář byl úspěšně odeslán, budeme Vás kontaktovat na uvedeném e-mailu."} data-sentry-element="MessageSuccess" data-sentry-source-file="CareerFormContact.tsx" />
    <br />
  </>;