import React from "react";
import classNames from "classnames";
import Link from "next/link";
import { container, container_H2, cardBox, cardBox_p, headerDecoration, doubleBox, doubleBoxItem, linkFullWithArrow } from "./ForMediaInfoBox.css";
export const ForMediaInfoBox = () => {
  return <div className={classNames(container)} id="archiv" data-sentry-component="ForMediaInfoBox" data-sentry-source-file="ForMediaInfoBox.tsx">
      <div className={classNames(doubleBox)}>
        <div className={classNames(doubleBoxItem, cardBox)}>
          <h2 className={classNames(container_H2)}>Mýty & fakta</h2>
          <div className={classNames(headerDecoration)}></div>
          <p className={classNames(cardBox_p)}>
            Také jste slyšeli mýty kolem televizních poplatků? Rádi vám vysvětlíme, jak je to
            doopravdy.
          </p>
          <Link href="../pro-media/myty-a-fakta/" className={classNames(linkFullWithArrow)} data-sentry-element="Link" data-sentry-source-file="ForMediaInfoBox.tsx">
            Zobrazit více
          </Link>
        </div>
        <div className={classNames(doubleBoxItem, cardBox)}>
          <h2 className={classNames(container_H2)}>Archiv tiskových zpráv</h2>
          <div className={classNames(headerDecoration)}></div>
          <p className={classNames(cardBox_p)}>
            Hledáte tiskové zprávy České televize s datem vydání před 1. 10. 2023?
            <a href="https://www.ceskatelevize.cz/vse-o-ct/press/tiskove-zpravy/" target="_blank" className={classNames(linkFullWithArrow)}>
              Zobrazit více
            </a>
          </p>
        </div>
      </div>
      <div className={classNames(doubleBox)}>
        <div className={classNames(doubleBoxItem, cardBox)}>
          <h2 className={classNames(container_H2)}>Fotoservis</h2>
          <div className={classNames(headerDecoration)}></div>
          <p className={classNames(cardBox_p)}>
            Fotografie k aktuálnímu programu České televize.
            <Link href="../pro-media/fotoservis/" target="_blank" className={classNames(linkFullWithArrow)} data-sentry-element="Link" data-sentry-source-file="ForMediaInfoBox.tsx">
              Zobrazit více
            </Link>
          </p>
        </div>
        <div className={classNames(doubleBoxItem, cardBox)}>
          <h2 className={classNames(container_H2)}>Export televizního programu</h2>
          <div className={classNames(headerDecoration)}></div>
          <p className={classNames(cardBox_p)}>XML export TV programu.</p>
          <a href="https://www.ceskatelevize.cz/xml/tv-program/" target="_blank" className={classNames(linkFullWithArrow)}>
            Zobrazit více
          </a>
        </div>
      </div>
    </div>;
};