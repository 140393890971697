import classNames from "classnames";
import { contentBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock.css";
import { cardsContent_two, InformationFromLawMainTitle, InformationFromLawWrapper } from "@components/core/PageTypes/HomePage/components/InformationFromLaw/InformationFromLaw.css";
import React from "react";
import { ContentCardBlock } from "@components/blocks/ContentCardBlock/ContentCardBlock";
export const InformationFromLaw = ({
  items
}: {
  items: any[];
}) => {
  const updatedItems = items.map((item: any) => {
    return {
      title: item.title,
      text: item.text,
      links: item?.link ? {
        link: item.link
      } : undefined
    };
  });
  return <div className={classNames(contentBlock, InformationFromLawWrapper)} data-sentry-component="InformationFromLaw" data-sentry-source-file="InformationFromLaw.tsx">
      <h2 className={classNames(InformationFromLawMainTitle)}>Informace ze zákona</h2>
      <div className={classNames(cardsContent_two)}>
        {updatedItems.map((item, index) => <ContentCardBlock key={index} value={item} />)}
      </div>
    </div>;
};