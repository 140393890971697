import { WithSideMenuLayout } from "@components/core/Layouts/WithSideMenuLayout/WithSideMenuLayout";
import { StructuredContenBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock";
import { AttachedDocumentsBlock } from "@components/blocks/AttachedDocumentsBlock/AttachedDocumentsBlock";
import { FaqPageContent } from "./FaqPageContent";
import { contentLimit } from "@components/core/PageTypes/FaqPage/FaqPage.css";
export const FaqPage = ({
  page,
  allPages
}: any) => {
  return <WithSideMenuLayout page={page} allPages={allPages} data-sentry-element="WithSideMenuLayout" data-sentry-component="FaqPage" data-sentry-source-file="FaqPage.tsx">
      <div className={contentLimit}>
        {/** FAQs s navigaci */}
        <FaqPageContent page={page} data-sentry-element="FaqPageContent" data-sentry-source-file="FaqPage.tsx" />

        {/** strukturovany obsah */}
        <StructuredContenBlock content={page.structuredContent} data-sentry-element="StructuredContenBlock" data-sentry-source-file="FaqPage.tsx" />

        {/** pripojene soubory */}
        {page.attachedDocuments?.length > 0 ? <AttachedDocumentsBlock documents={page.attachedDocuments.map((item: any) => item.value)} /> : null}
      </div>
    </WithSideMenuLayout>;
};