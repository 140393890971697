/*
Struktura dat:
{
  title: string,
  subtitle: string | "",
  text: string | "",
  medium: {
    image?: data pro obrazek,
    video?: data pro video,
    icon?: data pro ikonu
  },
  links: {
    link?: data pro jeden link,
    button?: data pro jeden link,
    list?: [data pro jeden link, ...]
  },
}
*/

import classNames from "classnames";
import { cardContent, cardDataLink, cardIcon, cardIconWrapper, cardImage, cardImageWrapper, cardItem, cardItemSubtitle, cardItemText, cardItemTitle, cardVideoWrapper, cartDataListLinks, headerDecorationCard } from "./ContentCardBlock.css";
import { TVideoIdType, VideoBlock } from "@components/blocks/VideoBlock/VideoBlock";
import { SmartImage } from "@components/reusables/SmartImage/SmartImage";
import { LinkListBlock } from "@components/blocks/StructuredContentBlock/sublocks/LinkListBlock/LinkListBlock";
import React from "react";
import { Link, TLinkStyle } from "@components/blocks/Link/Link";
import { IconBlock } from "@components/blocks/IconBlock/IconBlock";
export type Link = {
  url: string;
  title: string;
  target?: string;
  type?: TLinkStyle;
};
export type Image = {
  downloadUrl: string;
  title: string;
  width: number;
  height: number;
};
export type Video = {
  ID: string;
  videoIdType?: TVideoIdType;
};
export type TContentCard = {
  title: string;
  subtitle?: string;
  text?: string;
  medium?: {
    image?: Image;
    video?: Video;
    icon?: any;
  };
  links?: {
    link?: Link;
    button?: Link;
    list?: Link[];
  };
};
interface ContentCardProps {
  value: TContentCard;
}
export const ContentCardBlock: React.FC<ContentCardProps> = ({
  value
}) => {
  // console.log("ContentCardBlock", value);
  return <div className={classNames(cardItem)} data-sentry-component="ContentCardBlock" data-sentry-source-file="ContentCardBlock.tsx">
      {!!value.medium && !!value.medium.video ? <div className={classNames(cardVideoWrapper)}>
          <VideoBlock videoID={value.medium.video.ID} videoIdType={value.medium.video.videoIdType} />
        </div> : value.medium && value.medium.image ? <div className={classNames(cardImageWrapper)}>
          <SmartImage className={classNames(cardImage)} src={value.medium.image.downloadUrl} width={value.medium.image.width} height={value.medium.image.height} alt={value.medium.image.title} />
        </div> : value.medium && value.medium.icon ? <div className={classNames(cardIconWrapper)}>
          <div className={cardIcon}>
            <IconBlock value={value.medium.icon} />
          </div>
        </div> : null}

      <div className={classNames(cardContent)}>
        <h3 className={`${cardItemTitle}`}>{value.title}</h3>
        {value.subtitle && <div className={classNames(cardItemSubtitle)}>
            {value.subtitle}
          </div>}

        {value.text && <div className={classNames(headerDecorationCard)}></div>}

        {value.text && <div className={classNames(cardItemText)}>
            {value.text}
          </div>}

        {value.links ? <div>
            {value.links?.list ? <div className={classNames(cartDataListLinks)}>
                  <LinkListBlock value={value.links.list} linkListStyle="DEFAULT" />
                </div> : value.links.link ? <Link url={value.links.link.url} title={value.links.link.title} target={value.links.link.target} linkStyle={value.links.link.type ?? "DEFAULT"} externalClass={classNames(cardDataLink)} /> : value.links.button ? <Link url={value.links.button.url} title={value.links.button.title} target={value.links.button.target} linkStyle="ACTION_BUTTON" /> : null}
          </div> : null}
      </div>
    </div>;
};