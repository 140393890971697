import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { headline, visitUs, VisitUsIcons, VisitUsIconsItem, VisitUsIconsItemLink, VisitUsTabContent, VisitUsTabContentDecoration, VisitUsTabContentHeading, VisitUsTabContentMain, VisitUsTabDescriptionContent, VisitUsTabDescriptionContentButton, VisitUsTabDescriptionContentNoImage, VisitUsTabImage, VisitUsTabMenu } from "@components/core/PageTypes/HomePage/components/VisitUs/VisitUs.css";
import { contentBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock.css";
import { BUTTONS_linksLink, BUTTONS_mainUl } from "@components/blocks/StructuredContentBlock/sublocks/LinkListBlock/LinkListBlock.css";
import { vars as globalVars } from "@czechtvPackagesOverrides/styles/vanilla/global/global.css";
import { SmartImage } from "@components/reusables/SmartImage/SmartImage";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import { headerDecorationStyle } from "@components/blocks/DisplayColumnsStrip/DisplayColumnsStrip.css";
import { useRouter } from "next/router";
import { TVideoIdType, VideoBlock } from "@components/blocks/VideoBlock/VideoBlock";
import { bedge } from "@czechtvPackagesOverrides/styles/vanilla/components/bedge.css";
import date from "../../images/date.svg";
import openingHours from "../../images/time.svg";
import price from "../../images/price.svg";
import map from "../../images/maps.svg";
import { Link } from "@components/blocks/Link/Link";
export type Image = {
  downloadUrl: string;
  title: string;
  width: number;
  height: number;
};
export type Video = {
  ID: string;
  videoIdType?: TVideoIdType;
};
export type TVisitUsItem = {
  title: string;
  menuTitle: string;
  date: string;
  openingHours: string;
  price: string;
  mapLinkUrl: string;
  mapLinkTitle: string;
  medium: {
    image?: Image;
    video?: Video;
  };
  text: string;
  actionButton: {
    url: string;
    title: string;
  };
};
const TabMenu = ({
  items,
  activeIndex,
  setActiveIndex
}: {
  items: TVisitUsItem[];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
}) => {
  return <ul className={classNames(BUTTONS_mainUl, VisitUsTabMenu)} data-sentry-component="TabMenu" data-sentry-source-file="VisitUs.tsx">
      {items.map((item: TVisitUsItem, index: number) => {
      const tabIndex = index + 1; // Shift index to start from 1
      return <li key={tabIndex} className={classNames(BUTTONS_linksLink)}>
            <button className={classNames(bedge)} onClick={() => setActiveIndex(tabIndex)} // Pass 1-based index
        style={activeIndex === tabIndex ? {
          backgroundColor: globalVars.colors.red_base,
          color: globalVars.colors.white
        } : {}}>
              {item.menuTitle}
            </button>
          </li>;
    })}
    </ul>;
};
const TabContentComponent = ({
  content,
  isActive
}: {
  content: TVisitUsItem;
  isActive: boolean;
}) => <div className={classNames(VisitUsTabContent)} style={{
  display: isActive ? "flex" : "none"
}} data-sentry-component="TabContentComponent" data-sentry-source-file="VisitUs.tsx">
    <h4 className={classNames(VisitUsTabContentHeading)}>{content.title}</h4>

    <div className={classNames(headerDecorationStyle, VisitUsTabContentDecoration)}></div>

    <div className={classNames(VisitUsIcons)}>
      {content.date && <div className={classNames(VisitUsIconsItem)}>
          <SmartImage src={date.src} alt="datum" width={date.width} height={date.height} />
          {content.date}
        </div>}

      {content.openingHours && <div className={classNames(VisitUsIconsItem)}>
          <SmartImage src={openingHours.src} alt="Otevírací doba" width={openingHours.width} height={openingHours.height} />
          {content.openingHours}
        </div>}

      {content.price && <div className={classNames(VisitUsIconsItem)}>
          <SmartImage src={price.src} alt="Cena" width={price.width} height={price.height} />
          {content.price}
        </div>}

      {content.mapLinkUrl && <div className={classNames(VisitUsIconsItem)}>
          <SmartImage src={map.src} alt="Mapa" width={map.width} height={map.height} />
          <Link url={content.mapLinkUrl} title={content.mapLinkTitle || content.mapLinkUrl} target={"_blank"} externalClass={classNames(VisitUsIconsItemLink)} />
        </div>}
    </div>

    <div className={classNames(VisitUsTabContentMain)}>
      {content.medium?.image ? <div className={classNames(VisitUsTabImage)}>
          <SmartImage src={content.medium.image.downloadUrl} alt={content.title} width={content.medium.image.width} height={content.medium.image.height} />
        </div> : content.medium?.video ? <div className={classNames(VisitUsTabImage)}>
          <VideoBlock videoID={content.medium.video.ID} videoIdType={content.medium.video.videoIdType} />
        </div> : null}

      <div className={classNames(VisitUsTabDescriptionContent, !(content.medium?.image || content.medium?.video) ? VisitUsTabDescriptionContentNoImage : null)}>
        <HTMLBlock HTML={content.text} data-sentry-element="HTMLBlock" data-sentry-source-file="VisitUs.tsx" />
        {content.actionButton && <Link url={content.actionButton.url} title={content.actionButton.title} externalClass={VisitUsTabDescriptionContentButton} linkStyle={"ACTION_BUTTON"} />}
      </div>
    </div>
  </div>;
export const VisitUs = ({
  items
}: {
  items: TVisitUsItem[];
}) => {
  const router = useRouter();
  const [activeIndex, setActiveIndex] = useState(1); // Start from index 1
  const tabsRef = useRef<HTMLDivElement>(null); // Create a ref for the tabs container

  // Check for query parameter on initial render
  useEffect(() => {
    const visitUsParam = router.query.visitUs;
    if (visitUsParam && !isNaN(Number(visitUsParam))) {
      const index = Number(visitUsParam);
      if (index >= 1 && index <= items.length) {
        setActiveIndex(index); // Adjust to 1-based index

        // Scroll to the tabs section
        if (tabsRef.current) {
          tabsRef.current.scrollIntoView({
            behavior: "smooth"
          }); // Smooth scroll into view
        }
      }
    }
  }, [router.query.visitUs, items.length]);
  return <div ref={tabsRef} className={classNames(contentBlock, visitUs)} data-sentry-component="VisitUs" data-sentry-source-file="VisitUs.tsx">
      <h2 className={classNames(headline)}>Navštivte nás</h2>
      <TabMenu items={items} activeIndex={activeIndex} setActiveIndex={setActiveIndex} data-sentry-element="TabMenu" data-sentry-source-file="VisitUs.tsx" />
      {items.map((item, index) => {
      const tabIndex = index + 1; // 1-based indexing for tabs
      return <TabContentComponent key={tabIndex} content={item} isActive={tabIndex === activeIndex} // Compare with 1-based index
      />;
    })}
    </div>;
};
export default VisitUs;