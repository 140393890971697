import { SmartImage } from "@components/reusables/SmartImage/SmartImage";
import { RADIUS } from "@consts/roundCorners";
import { ASPECT_RATIO } from "@consts/aspectRatio";
import Link from "next/link";
import classNames from "classnames";
import { componentPeoples, componentPeoplesList, people, peopleDescription, peopleImage, peopleLink, peopleName } from "@components/blocks/StructuredContentBlock/sublocks/PersonsBlock/PersonsBlock.css";
export const PersonsBlock = ({
  value
}: {
  value: any;
}) => {
  const getPersonKey = (person: any) => JSON.stringify(person);
  return <div className={componentPeoples} data-sentry-component="PersonsBlock" data-sentry-source-file="PersonsBlock.tsx">
      {value?.persons && value.persons.length > 0 ? <ul className={componentPeoplesList}>
          {value.persons.map((person: any) => <PersonItem key={getPersonKey(person)} person={person} />)}
        </ul> : null}
    </div>;
};
const PersonItem = ({
  person
}: {
  person: any;
}) => {
  return <li className={people} data-sentry-component="PersonItem" data-sentry-source-file="PersonsBlock.tsx">
      {person.detailPage ? <Link href={person.detailPage.url} title={person.detailPage.title} className={peopleLink}>
          <div className={peopleImage}>
            <SmartImage src={person.image.downloadUrl} width={200} height={200} alt={person.title} cropAspectRatio={ASPECT_RATIO.SQUARE} radius={RADIUS.CIRCLE} />
          </div>
          <>
            <h4 className={peopleName}>{person.title}</h4>
            {person?.subtitle ? <p className={peopleDescription}>{person.subtitle}</p> : null}
          </>
        </Link> : <>
          <div className={peopleImage}>
            <SmartImage src={person.image.downloadUrl} width={200} height={200} alt={person.title} cropAspectRatio={ASPECT_RATIO.SQUARE} radius={RADIUS.CIRCLE} />
          </div>
          <h4 className={peopleName}>{person.title}</h4>
          {person?.subtitle ? <p className={peopleDescription}>{person.subtitle}</p> : null}
        </>}
    </li>;
};