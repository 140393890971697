import classNames from "classnames";
import { LinkListBlock } from "@components/blocks/StructuredContentBlock/sublocks/LinkListBlock/LinkListBlock";
import { mostInterest, mostInterestHeadline } from "@components/core/PageTypes/HomePage/components/MostInterest/MostInterest.css";
import { contentBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock.css";
export const MostInterest = ({
  items
}: {
  items: any[];
}) => {
  return <div className={classNames(contentBlock, mostInterest)} data-sentry-component="MostInterest" data-sentry-source-file="MostInterest.tsx">
      <h2 className={classNames(mostInterestHeadline)}>Nejčastěji vás zajímá</h2>
      <LinkListBlock value={items} linkListStyle="BUTTONS" data-sentry-element="LinkListBlock" data-sentry-source-file="MostInterest.tsx" />
    </div>;
};