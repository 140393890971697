import classNames from "classnames";
import { StaticContentBlockComponentProps } from "@components/blocks/StructuredContentBlock/sublocks/StaticContentBlock/StaticContentBlock";
import Link from "next/link";
import { headerDecoration, introBox, introBoxItem, introBoxItemDescription, introBoxItemHeader, introBoxItemLink } from "@components/core/PageTypes/UneditablePage/pages/GlobalContactsPage/components/GlobalContactBoxes/GlobalContactBoxes.css";
export const GlobalContactBoxes = ({
  parameters
}: StaticContentBlockComponentProps) => {
  return <article data-sentry-component="GlobalContactBoxes" data-sentry-source-file="GlobalContactBoxes.tsx">
      <div className={classNames(introBox)}>
        <div className={classNames(introBoxItem)}>
          <h2 className={introBoxItemHeader}>Mám dotaz na TV poplatky</h2>
          <div className={classNames(headerDecoration)}></div>
          <p className={introBoxItemDescription}>Máte dotaz týkající se TV poplatků? Veškeré informace naleznete na webové stránce.</p>
          <Link href="https://poplatky.ceskatelevize.cz/kontakty" className={classNames(introBoxItemLink)} data-sentry-element="Link" data-sentry-source-file="GlobalContactBoxes.tsx">
            Přejít na web
          </Link>
        </div>
        <div className={classNames(introBoxItem)}>
          <h2 className={introBoxItemHeader}>Podatelna</h2>
          <div className={classNames(headerDecoration)}></div>
          <p className={introBoxItemDescription}>Informace o provozu podatelny a podmínkách přijímání dokumentů do České televize</p>
          <Link href="https://www.ceskatelevize.cz/vse-o-ct/kontakty/podatelna/" className={classNames(introBoxItemLink)} data-sentry-element="Link" data-sentry-source-file="GlobalContactBoxes.tsx">
            Zobrazit více
          </Link>
        </div>
        <div className={classNames(introBoxItem)}>
          <h2 className={introBoxItemHeader}>Pravidla pro vyřizování podání, včetně stížností diváků</h2>
          <div className={classNames(headerDecoration)}></div>
          <p className={introBoxItemDescription}>Přečtěte si naše pravidla.</p>
          <Link href="./kontakty/pravidla-pro-vyrizovani-podani-vcetne-stiznosti-divaku/" rel="noreferrer" target="_blank" className={classNames(introBoxItemLink)} data-sentry-element="Link" data-sentry-source-file="GlobalContactBoxes.tsx">
            Přečíst pravidla
          </Link>
        </div>
        <div className={classNames(introBoxItem)}>
          <h2 className={introBoxItemHeader}>Informace pro oznamovatele</h2>
          <div className={classNames(headerDecoration)}></div>
          <p className={introBoxItemDescription}>Podání oznámení podle zákona o ochraně oznamovatelů.</p>
          <Link href="./kontakty/ochrana-oznamovatelu/" rel="noreferrer" target="_blank" className={classNames(introBoxItemLink)} data-sentry-element="Link" data-sentry-source-file="GlobalContactBoxes.tsx">
            Vnitřní oznamovací systém
          </Link>
        </div>
      </div>
    </article>;
};