import classNames from "classnames";
import { ButtonPrimary } from "@czechtvPackagesOverrides/components/ButtonPrimary";
import { CtHelping, CtHelpingHearth, CtHelpingParagraph, CtHelpingTitle, CtHelpingTxt } from "@components/webCareer/StaticContentComponents/BanerWeHelpTogether/BanerWeHelpTogether.css";
export const BanerWeHelpTogether = () => {
  return <article className={classNames(CtHelping)} data-sentry-component="BanerWeHelpTogether" data-sentry-source-file="BanerWeHelpTogether.tsx">
      <div className={classNames(CtHelpingHearth)}>#PomáhámeSpolečně</div>
      <div className={classNames(CtHelpingTxt)}>
        <h2 className={classNames(CtHelpingTitle)}>Pomáhejme společně</h2>
        <p className={classNames(CtHelpingParagraph)}>Darování krve, dobrovolnické dny nebo sbírky materiální i finanční pomoci</p>
        <p className={classNames(CtHelpingParagraph, "small")}>
          Naši zaměstnanci společně pomáhají různým neziskovým organizacím, podporují charitativní
          projekty a pořádají dobročinné sbírky.
        </p>
        <ButtonPrimary anchor href="https://www.ceskatelevize.cz/vse-o-ct/ct-podporuje/" data-sentry-element="ButtonPrimary" data-sentry-source-file="BanerWeHelpTogether.tsx">
          Více o podpoře
        </ButtonPrimary>
      </div>
    </article>;
};