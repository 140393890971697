import classNames from "classnames";
import { WeSupportHeading, WeSupportItem, WeSupportItemBig, WeSupportItemIcon, WeSupportItemIconElement, WeSupportItemIconSubTitle, WeSupportItemIconTitle, WeSupportItemLink, WeSupportItemLinkImage, WeSupportItemLinkImageWrapper, WeSupportItemLinkItem, WeSupportItemLinkItemTitle, WeSupportItems, WeSupportItemTitleElement, WeSupportStyles } from "@components/core/PageTypes/HomePage/components/WeSupport/WeSupport.css";
import { contentBlock } from "@components/blocks/StructuredContentBlock/StructuredContentBlock.css";
import Link from "next/link";

// images
import support1 from "./images/support1.svg";
import support2 from "./images/support2.svg";
import support3 from "./images/support3.svg";
import support4 from "./images/support4.svg";
import card1 from "./images/Card_pomozte_detem.jpg";
import card2 from "./images/Card_partnerstvi_akci.jpg";
import card3 from "./images/Card_jak_pomahame.jpg";
import card4 from "./images/Card_adventni_koncerty.jpg";
import card5 from "./images/Card_spotove_kampane.jpg";
import card6 from "./images/Card_beneficni_spoty.jpg";
import card7 from "./images/Card_dalsi_formy_podpory.jpg";
import { SmartImage } from "@components/reusables/SmartImage/SmartImage";
export const WeSupport = () => {
  return <div className={classNames(contentBlock, WeSupportStyles)} data-sentry-component="WeSupport" data-sentry-source-file="WeSupport.tsx">
      <h2 className={classNames(WeSupportHeading)}>Podporujeme dobrou věc</h2>
      <div className={classNames(WeSupportItems)}>
        <div className={classNames(WeSupportItem, WeSupportItemIcon)}>
          <div className={classNames(WeSupportItemIconElement)}>
            <SmartImage src={support4.src} alt="20 let pomáháme" width={support4.width} height={support4.height} data-sentry-element="SmartImage" data-sentry-source-file="WeSupport.tsx" />
          </div>
          <div className={classNames(WeSupportItemTitleElement)}>
            <div className={classNames(WeSupportItemIconTitle)}>
              20 let
            </div>
            <div className={classNames(WeSupportItemIconSubTitle)}>
              pomáháme
            </div>
          </div>
        </div>
        <div className={classNames(WeSupportItem, WeSupportItemLink, WeSupportItemBig)}>
          <div className={classNames(WeSupportItemLinkImageWrapper)}>
            <SmartImage className={classNames(WeSupportItemLinkImage)} src={card1.src} alt="Pomozte dětem" width={card1.width} height={card1.height} data-sentry-element="SmartImage" data-sentry-source-file="WeSupport.tsx" />
          </div>
          <Link href={"https://www.ceskatelevize.cz/vse-o-ct/ct-podporuje/ct-podporuje-charitu/pomozte-detem/"} className={classNames(WeSupportItemLinkItem)} data-sentry-element="Link" data-sentry-source-file="WeSupport.tsx">
            <span className={classNames(WeSupportItemLinkItemTitle)}>Pomozte dětem</span></Link>
        </div>
        <div className={classNames(WeSupportItem, WeSupportItemLink)}>
          <div className={classNames(WeSupportItemLinkImageWrapper)}>
            <SmartImage className={classNames(WeSupportItemLinkImage)} src={card2.src} alt="Partnerství akcí" width={card2.width} height={card2.height} data-sentry-element="SmartImage" data-sentry-source-file="WeSupport.tsx" />
          </div>
          <Link href={"https://www.ceskatelevize.cz/vse-o-ct/ct-podporuje/ct-podporuje-charitu/partnerstvi-akci/"} className={classNames(WeSupportItemLinkItem)} data-sentry-element="Link" data-sentry-source-file="WeSupport.tsx">
                <span className={classNames(WeSupportItemLinkItemTitle)}>Partnerství akcí</span></Link>
        </div>
        <div className={classNames(WeSupportItem, WeSupportItemIcon)}>
          <div className={classNames(WeSupportItemIconElement)}>
            <SmartImage src={support3.src} alt="1 024 benefičních sbírek" width={support3.width} height={support3.height} data-sentry-element="SmartImage" data-sentry-source-file="WeSupport.tsx" />
          </div>
          <div className={classNames(WeSupportItemTitleElement)}>
            <div className={classNames(WeSupportItemIconTitle)}>
              1 024
            </div>
            <div className={classNames(WeSupportItemIconSubTitle)}>
              benefičních sbírek
            </div>
          </div>
        </div>
        <div className={classNames(WeSupportItem, WeSupportItemLink)}>
          <div className={classNames(WeSupportItemLinkImageWrapper)}>
            <SmartImage className={classNames(WeSupportItemLinkImage)} src={card3.src} alt="Jak pomáháme" width={card3.width} height={card3.height} data-sentry-element="SmartImage" data-sentry-source-file="WeSupport.tsx" />
          </div>
          <Link href={"https://www.ceskatelevize.cz/vse-o-ct/ct-podporuje/ct-podporuje-charitu/"} className={classNames(WeSupportItemLinkItem)} data-sentry-element="Link" data-sentry-source-file="WeSupport.tsx">
                  <span className={classNames(WeSupportItemLinkItemTitle)}>Jak pomáháme</span></Link>
        </div>
        <div className={classNames(WeSupportItem, WeSupportItemIcon)}>
          <div className={classNames(WeSupportItemIconElement)}>
            <SmartImage src={support2.src} alt="96 kulturních projektů" width={support2.width} height={support2.height} data-sentry-element="SmartImage" data-sentry-source-file="WeSupport.tsx" />
          </div>
          <div className={classNames(WeSupportItemTitleElement)}>
            <div className={classNames(WeSupportItemIconTitle)}>
              96
            </div>
            <div className={classNames(WeSupportItemIconSubTitle)}>
              kulturních projektů
            </div>
          </div>
        </div>
        <div className={classNames(WeSupportItem, WeSupportItemLink)}>
          <div className={classNames(WeSupportItemLinkImageWrapper)}>
            <SmartImage className={classNames(WeSupportItemLinkImage)} src={card4.src} alt="Adventní koncerty" width={card4.width} height={card4.height} data-sentry-element="SmartImage" data-sentry-source-file="WeSupport.tsx" />
          </div>
          <Link href={"https://www.ceskatelevize.cz/vse-o-ct/ct-podporuje/ct-podporuje-charitu/adventni-koncerty/"} className={classNames(WeSupportItemLinkItem)} data-sentry-element="Link" data-sentry-source-file="WeSupport.tsx">
                <span className={classNames(WeSupportItemLinkItemTitle)}>Adventní koncerty</span></Link>
        </div>
        <div className={classNames(WeSupportItem, WeSupportItemLink)}>
          <div className={classNames(WeSupportItemLinkImageWrapper)}>
            <SmartImage className={classNames(WeSupportItemLinkImage)} src={card5.src} alt="Spotové kampaně" width={card5.width} height={card5.height} data-sentry-element="SmartImage" data-sentry-source-file="WeSupport.tsx" />
          </div>
          <Link href={"https://www.ceskatelevize.cz/vse-o-ct/ct-podporuje/ct-podporuje-charitu/spotove-kampane/"} className={classNames(WeSupportItemLinkItem)} data-sentry-element="Link" data-sentry-source-file="WeSupport.tsx">
                <span className={classNames(WeSupportItemLinkItemTitle)}>Spotové kampaně</span></Link>
        </div>
        <div className={classNames(WeSupportItem, WeSupportItemIcon)}>
          <div className={classNames(WeSupportItemIconElement)}>
            <SmartImage src={support1.src} alt="112 charitativních projektů" width={support1.width} height={support1.height} data-sentry-element="SmartImage" data-sentry-source-file="WeSupport.tsx" />
          </div>
          <div className={classNames(WeSupportItemTitleElement)}>
            <div className={classNames(WeSupportItemIconTitle)}>
              112
            </div>
            <div className={classNames(WeSupportItemIconSubTitle)}>
              charitativních projektů
            </div>
          </div>
        </div>
        <div className={classNames(WeSupportItem, WeSupportItemLink)}>
          <div className={classNames(WeSupportItemLinkImageWrapper)}>
            <SmartImage className={classNames(WeSupportItemLinkImage)} src={card6.src} alt="Benefiční spoty" width={card6.width} height={card6.height} data-sentry-element="SmartImage" data-sentry-source-file="WeSupport.tsx" />
          </div>
          <Link href={"https://www.ceskatelevize.cz/vse-o-ct/ct-podporuje/ct-podporuje-kulturu/kampane-2024/"} className={classNames(WeSupportItemLinkItem)} data-sentry-element="Link" data-sentry-source-file="WeSupport.tsx">
                      <span className={classNames(WeSupportItemLinkItemTitle)}>Benefiční spoty</span>
          </Link>
        </div>
        <div className={classNames(WeSupportItem, WeSupportItemLink)}>
          <div className={classNames(WeSupportItemLinkImageWrapper)}>
            <SmartImage className={classNames(WeSupportItemLinkImage)} src={card7.src} alt="Další formy podpory" width={card7.width} height={card7.height} data-sentry-element="SmartImage" data-sentry-source-file="WeSupport.tsx" />
          </div>
          <Link href={"https://www.ceskatelevize.cz/vse-o-ct/ct-podporuje/ct-podporila/charita/"} className={classNames(WeSupportItemLinkItem)} data-sentry-element="Link" data-sentry-source-file="WeSupport.tsx">
                  <span className={classNames(WeSupportItemLinkItemTitle)}>Další formy podpory</span>
          </Link>
        </div>
      </div>
    </div>;
};