import { BaseContentPage } from "./PageTypes/BaseContentPage/BaseContentPage";
const sectionId = "webContacts";
const baseSectionPath = "/kontakt";
const isThisSection = (page: any) => page.path.startsWith(baseSectionPath);
const pageTypes = [{
  id: "BaseContentPage",
  component: BaseContentPage
}];
export const siteSection = {
  sectionId: sectionId,
  pageTypes: pageTypes,
  isThisSection: isThisSection
};