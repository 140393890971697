import { useRouter } from "next/router";
import classNames from "classnames";
import Link from "next/link";
import { FaqBoxItem, FaqBoxItem_h3, FaqBoxItem_p, FaqBoxItemLink } from "./FaqContactUsBox.css";
export const FaqContactUsBox = () => {
  const router = useRouter();
  const buttonPathname = "/kontakty";
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const id = new URLSearchParams(window.location.search).get("id");
    router.push({
      pathname: buttonPathname,
      query: {
        pid: id
      }
    });
  };
  return <article data-sentry-component="FaqContactUsBox" data-sentry-source-file="FaqContactUsBox.tsx">
      <div className={classNames(FaqBoxItem)}>
        <h2 className={classNames(FaqBoxItem_h3)}>Nenašli jste odpověď na vaši otázku?</h2>
        <p className={classNames(FaqBoxItem_p)}>
          Kontaktujte Divácké centrum, kde vám rádi poradíme
        </p>
        <Link href={buttonPathname} onClick={e => {
        handleClick(e);
      }} className={classNames(FaqBoxItemLink)} data-sentry-element="Link" data-sentry-source-file="FaqContactUsBox.tsx">
          Zeptat se
        </Link>
      </div>
    </article>;
};