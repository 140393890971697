import React from "react";
import { UneditablePageRegister, UneditablePageBase, UneditablePageProps } from "@components/core/PageTypes/UneditablePage/UneditablePage";

// registrovane staticke stranky
const staticPages: UneditablePageRegister = {};
export const UneditablePage = ({
  page,
  allPages
}: UneditablePageProps) => {
  return <UneditablePageBase page={page} allPages={allPages} registerdUneditablePages={staticPages} data-sentry-element="UneditablePageBase" data-sentry-component="UneditablePage" data-sentry-source-file="UneditablePage.tsx" />;
};