import { useState, useEffect } from "react";
import { CarouselHorizontalMenu } from "@components/reusables/CarouselHorizontalMenu/CarouselHorizontalMenu";
import { FaqBlock, getFaqsCategories } from "@components/blocks/FaqBlock/FaqBlock";
export const FaqPageContent = ({
  page
}: {
  page: any;
}) => {
  // polozky do horizontal menu
  const horizontalMenuItems = getFaqsCategories(page.faqs).map((item: any) => {
    return {
      label: item.categoryLabel,
      id: item.categorySlug
    };
  });

  // aktualni kategorie
  const [currentCategory, setCurrentCategory] = useState<string>();
  useEffect(() => {
    setCurrentCategory(horizontalMenuItems[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  return <div data-sentry-component="FaqPageContent" data-sentry-source-file="FaqPageContent.tsx">
      {/** FAQs navigace */}
      <CarouselHorizontalMenu currentId={currentCategory} items={horizontalMenuItems} onSelect={id => {
      if (id !== currentCategory) {
        setCurrentCategory(id);
      }
    }} data-sentry-element="CarouselHorizontalMenu" data-sentry-source-file="FaqPageContent.tsx" />

      {/** vypis FAQs */}
      <FaqBlock faqCategories={page.faqs} displayCategorySlug={currentCategory} data-sentry-element="FaqBlock" data-sentry-source-file="FaqPageContent.tsx" />
    </div>;
};