import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fglobal%2Fglobal.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA42WTW%2FbMAyG7%2FsVPK6AUoiULNnabbcdBgzLsOvgJE7qLl91kjbtsP8%2BMLGU1lTXIrEO8iO%2BIl%2BJSeg2mz38%2BQAwGv3CatXd1LUOMN4cumkzrte7b91m9L1ZHJZ1p2BXr3ejXdO1808vVuBwxbhZtZ83y9nrS2i45Mu%2BXrbT1xeYAD9%2BMjyedk2zfntTdrDg%2F%2FspBvTXZtYeVq%2FzLkC3mNQfK6cAnVZARaEAr15Svqe04s%2Fwbdm%2F5aXxGTJVZIxXVCrjBFE%2Fi4LOZHQmPVF4RdoprEgg0x4xhYISFWDlZTazHkJyCssyo9REwlkui1GAJclA87hly1IaFZhM8RYpMaOACPthiN0kjKJcaSTWJoxjnIRtBrsN8HLi95sGLGPSFW%2BRj0KmdqsIWT4wp8JIaB0hXShgTktm0zPOKSAFVhLbFEXB%2BTsk7mJOxJU1vO0iE6iLgcqCEyPGMm7uUmqclreMZUT38cZwCOsVkM2U4BAPIqd%2F8txkPL%2BPCXgFpxtYZkr%2B8B7o%2BKxYp5OhM4V47CGrjFZOnoCnGOTkGmWSR31BkLNyGQZTtwCyCnLlQUpXh%2BucBsGZZDEjaRCcTYeX1S7DkCvSzea3l2HIuXQYLtcsYyDGzoh4qkgahlzskXyv4yOg2CQLryA%2BAop90pQK4iOgSSobF%2Bz8CGiaIEq1zQWbJa8oeZXjmnfGmyevit4myp1WXCSvimiTz3E37%2BTadEd88qrKcLc95%2F3ZpypXvNhPuZOwTy7nVWyphs4%2B5foJppbqzj6Z3N7XAcrtcTC5CYBOzG4DkBWzdwEMidkugNVidhfASrV9gEKqHQI4qXYfwEu1hwClVDsGKKXaY4BKqj0F7mzDadIBEIUeYQAkIUjE00KRTAA0QpJsALRSsgiAhZR0bImU9DwtJcsA6KVkFQBLKVkHwEpKTgKQlpLTANPDpJ2OJs1T23T83%2B0alb42ou3TjHPRq91gOv0Lev5br6%2FpCvT2CLg9Am2PIJXn%2BXXFZZ3JrlsEkEnfZA4%2BtbmDT7cB%2FNnAv%2F8Achf%2FrxAMAAA%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22czechtvPackagesOverrides%2Fstyles%2Fvanilla%2Fcomponents%2Fbedge.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA%2B1SwU7DMAy99yuMuFCJVM0EY2R3fgN5SdZGS5PKSacNxL%2BjZt0YZQcEmtiBSIksx89%2Btl8RwvRuXZfwmgEoE1qLWwHGWeM0W1gvV%2FPsLSv2UXgLB3vRxehdAi69iyyYFy2gLGYP96SbeQaQktTaVHUUwIvJ3p%2FCl9gYuxWwRrph7Jk%2FNlQjlnkC6hg1sdCiNK7qk5b8gJbeehrjtgl3aGBgDiA7Cn10642LmnrXAuWqIt85xYZU10%2Fp9J8tKpVKztoN8Gn%2FlIOVsJ6UJkaoTBfGHLhPJBqkyjgBPW463HIHj3oTmdLSE0bjnQDnnYYr07SeIrqYYghdMLtvtHZUY6LysUfmxysStV9rAkx7%2BdrqZ%2BwqPxroMIUxnbNT5v8SuigJ8UFC5xTQ71lO%2Fkw1p9v8oW5OjfPMAvrJ2L%2Bju4%2FVXLSA3gFCk%2BAp8gYAAA%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2Fcore%2FPageTypes%2FDocumentsPage%2FDocumentsPage.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA71Ty27jMAy8%2BytY7KU5yLCDpA%2F1sqf9jUK2aEetJQoSs4lb5N8XVpw2dtNtT%2FVBBkiOOEMN88eyaLDBtoDXDMCq0BonofB7UFsm%2BKvCtRCP5b0NG6XKuEjhh%2ByQ5SdkmZBeaW1cm6APGUBnIovIfYeCe48SHDkcEtpE36leQtNhqhz%2BYheUlzCcQ%2BhpG9k0vajJMTo%2B1orIKvCk9Sq1bsixiOYFJZT57TqgPRJwKDZo2g1LWI7BVNooa7pezrStFkNBTR2FeapPqeNsBJN%2FEzmGKmImO0eVlGDvg5lQX3%2BkfpH5maL%2FkF%2F%2FLPmbqVmg%2BIiGAq6M9RRYuemj3SZwRftBeLq8oqAxiIpGXnuxM5o3Eu5uRq6Vqp%2FbQFunxSjy15%2F0TUhelDBeHpQ22%2FhJzWVPahOwZkNOQqDdEFadaZ0wjDZKqNExhi%2FtCtAO1p71dYvvPcEh%2B21RGwWxDogOlNNwbY07Tagsliu%2FX6SRztZi4q5lvjzZaL4a%2BVkCmTGI6FWdBiqKvDjDfbk9h%2BzwD5qqFbRRBAAA%22%7D"
export var bedge = 'ss64vh1';
export var bedgeActive = 'ss64vh2';
export var faqCategoryHeadline = '_10fefeg4';
export var faqSelect = '_10fefeg7';
export var faqSubCategoryHeadline = '_10fefeg5';
export var faqTopTags = '_10fefeg0';
export var faqTopTagsList = '_10fefeg1';
export var faqTxtContent = '_10fefeg6';