import { BaseContentPage } from "@components/webPeople/PageTypes/BaseContentPage/BaseContentPage";
import { PersonPage } from "@components/core/PageTypes/PersonPage/PersonPage";
import { staticContentBlocks } from "./StaticContentComponents/index";
const sectionId = "webPeople";
const baseSectionPath = "/lide";
const isThisSection = (page: any) => page.path.startsWith(baseSectionPath);
const pageTypes = [{
  id: "BaseContentPage",
  component: BaseContentPage
}, {
  id: "PersonPage",
  component: PersonPage
}];
export { staticContentBlocks };
export const siteSection = {
  sectionId: sectionId,
  pageTypes: pageTypes,
  isThisSection: isThisSection,
  staticContentBlocks: staticContentBlocks
};