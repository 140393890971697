import React from 'react';
import { useContextWithProvider } from '@czechtv/components';
//import { getConfig } from '../../config/config';
import { PageViewEventType } from '@utils/pageView';
import { Consent, setOnCookieConsentChanged } from '@utils/setOnCookieConsentChanged';
interface Values {
  sendPageView: (pageType: PageViewEventType) => void;
}
const GemiusContext = React.createContext<Values | undefined>(undefined);

//const { GEMIUS_ID_HOMEPAGE, GEMIUS_ID_SECTION } = getConfig();
const GEMIUS_ID = 'nGFAUkfMc_xce.bcKeSEybde7zew4MLkHlOZRDuTEOf.M7';
export const useGemius = () => useContextWithProvider(GemiusContext);
declare global {
  interface Window {
    ctGetConsentStatus?: Promise<Consent>;
    gemius_consent: boolean;
    gemius_hit: (id: string) => void;
    gemius_identifier: string;
    gemius_init: (data: {
      consent: boolean;
    }) => void;
    gemius_init_timeout: number;
  }
}
const loadGemius = () => {
  (function (d, t) {
    try {
      var gt = d.createElement(t),
        s = d.getElementsByTagName(t)[0],
        l = 'http' + (location.protocol == 'https:' ? 's' : '');
      gt.setAttribute('async', 'async');
      gt.setAttribute('defer', 'defer');
      // @ts-expect-error
      gt.src = l + '://spir.hit.gemius.pl/xgemius.js';
      // @ts-expect-error
      s.parentNode.insertBefore(gt, s);
    } catch (e) {}
  })(document, 'script');
};

// tohle je příběh o tom, jak je gemius peklo

// abych měl gemius ve stránce jen jednou, inicializuji ho mimo react komponentu
// a dělám to mimo ssr
if (typeof window !== 'undefined') {
  // podle toho, co nám řekli, má mít iniciace 10s timeout
  window.gemius_init_timeout = 10000;

  // nastavím si do window základní funkce, abych je mohl okamžitě použít
  function gemius_pending(i: any) {
    window[i] = window[i] || function () {
      // @ts-expect-error
      var x = window[i + '_pdata'] = window[i + '_pdata'] || [];
      x[x.length] = arguments;
    };
  }
  gemius_pending('gemius_hit');
  gemius_pending('gemius_event');
  gemius_pending('gemius_init');
  gemius_pending('pp_gemius_hit');
  gemius_pending('pp_gemius_event');
  gemius_pending('pp_gemius_init');

  // ale samotné načtení scriptu musí nastat až později, protože cmp script musí nastavit
  // globální proměnnou gemius_consent, pokud by se nastavila dříve, gemius na ni nereaguje
  if (window.ctGetConsentStatus) {
    void window.ctGetConsentStatus.then((data: Consent) => {
      if (data.consentInteraction) {
        window.gemius_init({
          consent: data.analytics
        });
      }
    }).finally(loadGemius);
  } else {
    // pokud mám vypnutou cookie lištu, načtu gemius hned
    loadGemius();
  }

  // pokus se změní souhlas s cookies, odpálíme gemius
  setOnCookieConsentChanged(({
    analytics
  }) => {
    // musíme do něj natvrdo poslat jesti uživatel souhlasil, protože
    // v tomhle případě to gemius nečte z globální proměnné
    window.gemius_init({
      consent: analytics
    });
  });
}
export const GemiusProvider = (props: React.PropsWithChildren<{}>) => {
  return <GemiusContext.Provider {...props} value={React.useMemo(() => {
    return {
      sendPageView: page => {
        // do child komponent vystavuji API na odeslání pageview
        //const gemiusId =
        //  page === PageViewEventType.HomepageView ? GEMIUS_ID_HOMEPAGE : GEMIUS_ID_SECTION;
        window.gemius_hit(GEMIUS_ID);
      }
    };
  }, [])} data-sentry-element="unknown" data-sentry-component="GemiusProvider" data-sentry-source-file="Gemius.tsx" />;
};