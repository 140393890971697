import React from "react";
import { UneditablePageProps } from "@components/core/PageTypes/UneditablePage/UneditablePage";
import { DefaultLayout } from "@components/webCareer/Layouts/DefaultLayout/DefaultLayout";
import { Content } from "@czechtv/components";
import classNames from "classnames";
import { movedUp, alert } from "./JobsCzTemplatePage.css";
import backImg from "./careerBack.jpg";
export const JobsCzTemplatePage: React.FC<UneditablePageProps> = ({
  page,
  allPages
}) => {
  return <DefaultLayout page={page} allPages={allPages} headerImage={{
    downloadUrl: backImg.src,
    title: "Kariera ČT",
    width: 2500,
    height: 713
  }} data-sentry-element="DefaultLayout" data-sentry-component="JobsCzTemplatePage" data-sentry-source-file="JobsCzTemplatePage.tsx">
      <Content data-sentry-element="Content" data-sentry-source-file="JobsCzTemplatePage.tsx">
        <div className={classNames(alert, movedUp)}>Zde bude výpis pozic JOBS.czss</div>
      </Content>
    </DefaultLayout>;
};