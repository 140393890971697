import React, { useState, useEffect, useCallback } from "react";
import Link from "next/link";
import classNames from "classnames";
import { SmartImage } from "@components/reusables/SmartImage/SmartImage";
import { TextOrHTML } from "../../reusables/TextOrHTML/TextOrHTML";
import { IImage, THTMLString } from "../../../types/index";
import { headerDecoration, textContainerCard, textContainer, textContainer_a, textContainerMainHeader, textContainerMainHeader_span, imageContainer, linksColumn, linksColumn_li, linksLink } from "./DisplayStripBlock.css";
interface ILink {
  label: string;
  url: string;
  target?: string;
}
export interface DisplayStripBlockProps {
  headline: string;
  headlineLink?: string;
  image?: IImage;
  imagePosition?: "LEFT" | "RIGHT";
  links?: ILink[];
  linksColumns: 1 | 2;
  subheadline?: string;
  textHTML?: THTMLString;
}
const LinksContainer = ({
  links = undefined,
  linksColumns = 1
}: DisplayStripBlockProps) => {
  const linksToColumns = useCallback(() => {
    const updatedLinks: ILink[][] = [];
    if (links) {
      updatedLinks.push(links);
    }
    return updatedLinks;
  }, [links]);
  const [linksAsColumns, setLinksAsColumns] = useState(linksToColumns());
  useEffect(() => {
    setLinksAsColumns(linksToColumns());
  }, [linksColumns, linksColumns, linksToColumns]);
  if (links?.length == 0) {
    return null;
  }
  return <>
      {links != undefined && links?.length > 1 ? <>
          {linksAsColumns.map((columnsLinks: ILink[], i) => <ul className={classNames(linksColumn)} key={`column${columnsLinks[0].url}${columnsLinks[0].label}`}>
              {columnsLinks.map(columnLink => <li key={`${columnLink.url}${columnLink.label}`} className={classNames(linksColumn_li)}>
                  <Link href={columnLink.url} target={columnLink?.target ? columnLink.target : undefined} className={classNames(linksLink)}>
                    {columnLink.label}
                  </Link>
                </li>)}
            </ul>)}
        </> : null}
    </>;
};
const ImageContainer = ({
  headline,
  image
}: DisplayStripBlockProps) => {
  return <div className={classNames(imageContainer)} data-sentry-component="ImageContainer" data-sentry-source-file="DisplayStripBlock.tsx">
      {image ? <SmartImage src={image.src as string} alt={headline} width={460} height={305} /> : null}
    </div>;
};
const TextContainer = (props: DisplayStripBlockProps) => {
  return <article className={classNames(textContainer)} data-sentry-component="TextContainer" data-sentry-source-file="DisplayStripBlock.tsx">
      <header>
        {!!props?.headlineLink ? <Link href={props.headlineLink} className={classNames(textContainer_a)}>
            <h2 className={classNames(textContainerMainHeader)}>
              {props.headline}
              {props.subheadline ? <span className={classNames(textContainerMainHeader_span)}>
                  {props.subheadline}
                </span> : null}
            </h2>
            <div className={classNames(headerDecoration)}></div>
          </Link> : <>
            <h2 className={classNames(textContainerMainHeader)}>
              {props.headline}
              {props.subheadline ? <span className={classNames(textContainerMainHeader_span)}>
                  {props.subheadline}
                </span> : null}
            </h2>
            <div className={classNames(headerDecoration)}></div>
          </>}
      </header>
      <TextOrHTML HTML={props.textHTML} data-sentry-element="TextOrHTML" data-sentry-source-file="DisplayStripBlock.tsx" />
      <LinksContainer {...props} data-sentry-element="LinksContainer" data-sentry-source-file="DisplayStripBlock.tsx" />
    </article>;
};
export const DisplayStripBlock = (props: DisplayStripBlockProps) => {
  return <div className={classNames(textContainerCard)} data-sentry-component="DisplayStripBlock" data-sentry-source-file="DisplayStripBlock.tsx">
      {props.image ? <ImageContainer {...props} /> : null}
      <TextContainer {...props} data-sentry-element="TextContainer" data-sentry-source-file="DisplayStripBlock.tsx" />
    </div>;
};