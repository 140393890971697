import React, { useEffect, useState, useRef } from "react";
import { SmartImage } from "@components/reusables/SmartImage/SmartImage";
import { RADIUS } from "@consts/roundCorners";
import Link from "next/link";
import classNames from "classnames";
import { A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { CarouselHorizontalMenu } from "../../reusables/CarouselHorizontalMenu/CarouselHorizontalMenu";
import { HTMLBlock } from "@components/blocks/HTMLBlock/HTMLBlock";
import { IEvent, createEventsCarouselHorizontalNavigationMenuItems, filterEventsByYear } from "@components/webHistory/utils";
import { container, container_h2, mainCarouselWrap, mainCarouselItem, mainCarouselItemWrap, mainCarouselItemImg, mainCarouselItemTxt, swiperHolder, swiperButton, swiperButtonBack, swiperButtonNext, detailLink } from "./HistoricalMilestonesBlock.css";
export interface HistoricalMilestonesBlockProps {
  detailUrl?: string;
  headline?: string;
  events: IEvent[];
  oneEventPerSlide?: boolean;
}
const EventItem = ({
  event
}: {
  event: IEvent;
}) => {
  return <div className={mainCarouselItemWrap} data-sentry-component="EventItem" data-sentry-source-file="HistoricalMilestonesBlock.tsx">
      {event?.image ? <div className={mainCarouselItemImg}>
          <SmartImage radius={RADIUS.SMALL} src={event.image.downloadUrl as string} alt={""} width={event.image.width} height={event.image.height} />
        </div> : null}
      <div className={mainCarouselItemTxt}>
        <HTMLBlock HTML={event.description} data-sentry-element="HTMLBlock" data-sentry-source-file="HistoricalMilestonesBlock.tsx" />
      </div>
    </div>;
};
const MainCarouselItem = ({
  events,
  onlyOne = true
}: {
  events: IEvent[];
  onlyOne?: boolean;
}) => {
  return <div className={mainCarouselItem} data-sentry-component="MainCarouselItem" data-sentry-source-file="HistoricalMilestonesBlock.tsx">
      {onlyOne ? <EventItem event={events[0]} /> : events.map((event: IEvent, i: number) => <EventItem key={i} event={event} />)}
    </div>;
};
export const HistoricalMilestonesBlock = ({
  events,
  headline = undefined,
  detailUrl = undefined,
  oneEventPerSlide = true
}: HistoricalMilestonesBlockProps) => {
  const swiperRef = useRef(null);

  // polozky navigace (roky)
  const navigationItems = createEventsCarouselHorizontalNavigationMenuItems(events);

  // aktualni rok
  const [currentId, setCurrentId] = useState<string>(navigationItems[0].id);

  // reakce na zmenu currentId
  useEffect(() => {
    let newIndex = 0;
    navigationItems.forEach((item, i) => {
      if (item.id === currentId) {
        newIndex = i;
      }
    });
    (swiperRef.current as any)?.swiper.slideTo(newIndex);
  }, [currentId, navigationItems]);

  // nejsou zadne eventy
  if (events.length == 0) {
    return <></>;
  }
  const handleNextItem = () => {
    (swiperRef.current as any)?.swiper.slideNext();
  };
  const handlePrevItem = () => {
    (swiperRef.current as any)?.swiper.slidePrev();
  };
  return <section className={container} data-sentry-component="HistoricalMilestonesBlock" data-sentry-source-file="HistoricalMilestonesBlock.tsx">
      {/** nadpis */}
      {headline ? <h2 className={container_h2}>{headline}</h2> : null}

      <div className={mainCarouselWrap}>
        {/** navigace */}
        <CarouselHorizontalMenu currentId={currentId} items={navigationItems} onSelect={id => {
        if (id !== currentId) {
          setCurrentId(id);
        }
      }} data-sentry-element="CarouselHorizontalMenu" data-sentry-source-file="HistoricalMilestonesBlock.tsx" />

        <div className={swiperHolder}>
          {/** hlavni carousel - BEGIN */}
          {/* @ts-ignore */}
          <Swiper modules={[A11y]} ref={swiperRef} onSlideChange={(e: any) => {
          setCurrentId(navigationItems[e.activeIndex].id);
        }} data-sentry-element="Swiper" data-sentry-source-file="HistoricalMilestonesBlock.tsx">
            {navigationItems.map((navigationItem: any) => <SwiperSlide key={navigationItem.id}>
                <MainCarouselItem events={filterEventsByYear(events, navigationItem.id)} onlyOne={oneEventPerSlide} />
              </SwiperSlide>)}
          </Swiper>
          {/** hlavni carousel - END */}

          {/** pohyb carouselem */}
          <div>
            <button className={classNames(swiperButton, swiperButtonBack)} type="button" onClick={() => {
            handlePrevItem();
          }}></button>
            <button className={classNames(swiperButton, swiperButtonNext)} type="button" onClick={() => {
            handleNextItem();
          }}></button>
          </div>
        </div>

        {/** link na podrobnosti */}
        {detailUrl ? <div className={detailLink}>
            <Link href={detailUrl}>Podrobná časová osa</Link>
          </div> : null}
      </div>
    </section>;
};