import { useEffect } from 'react';
import { UneditablePageProps } from "@components/core/PageTypes/UneditablePage/UneditablePage";
const redirectToOldHell = true;
export const HomePage: React.FC<UneditablePageProps> = ({
  page
}) => {
  useEffect(() => {
    if (redirectToOldHell) {
      window.location.href = 'https://www.ceskatelevize.cz/vse-o-ct/';
    }
  }, []);
  return <></>;
};