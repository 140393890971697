import { ListJobsPositions } from "./ListJobsPositions/ListJobsPositions";
import { BanerWeHelpTogether } from "./BanerWeHelpTogether/BanerWeHelpTogether";
import { YouShouldSee } from "./YouShouldSee/YouShouldSee";
import { HpTopStrip } from "./HpTopStrip/HpTopStrip";
import { CategoryJobsSummary } from "./CategoryJobsSummary/CategoryJobsSummary";
import { AnotherPositionInterest } from "./AnotherPositionInterest/AnotherPositionInterest";
import { Ct24AcademyForm } from "./Ct24AcademyForm/Ct24AcademyForm";
import { OurCompanyRules } from "./OurCompanyRules/OurCompanyRules";
export const staticContentBlocks = [{
  id: "ListJobsPositions",
  component: ListJobsPositions
}, {
  id: "BanerWeHelpTogether",
  component: BanerWeHelpTogether
}, {
  id: "YouShouldSee",
  component: YouShouldSee
}, {
  id: "HpTopStrip",
  component: HpTopStrip
}, {
  id: "CategoryJobsSummary",
  component: CategoryJobsSummary
}, {
  id: "AnotherPositionInterest",
  component: AnotherPositionInterest
}, {
  id: "Ct24AcademyForm",
  component: Ct24AcademyForm
}, {
  id: "OurCompanyRules",
  component: OurCompanyRules
}];