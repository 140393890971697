export enum ASPECT_RATIO {
  ORIGINAL = "ORIGINAL",
  NONE = "NONE",
  SQUARE = "1:1",
  HORIZONTAL_4_3 = "4:3",
  HORIZONTAL_16_9 = "16:9",
  VERTICAL_4_3 = "3:4",
  VERTICAL_16_9 = "9:16",
}

// hodnota > css hodnota
export const value2CssValue = {
  ORIGINAL: "auto",
  NONE: "unset",
  "1:1": "1/1",
  "4:3": "4/3",
  "16:9": "16/9",
  "3:4": "3/4",
  "9:16": "9/16"
};
export const withCropAspectRatio = (value: ASPECT_RATIO = ASPECT_RATIO.ORIGINAL) => {
  return {
    aspectRatio: `${value2CssValue[value]}`,
    objectFit: "cover"
  };
};