import React from 'react';
import classNames from 'classnames';
import { containerFlex, dynamicContainerStyle } from './HorizontalSpaceBlok.css';
type HorizontalSpaceBlokProps = {
  multiplier: number | string;
};
export const HorizontalSpaceBlok: React.FC<HorizontalSpaceBlokProps> = ({
  multiplier
}) => {
  const dynamicStyleClass = typeof multiplier === 'number' && dynamicContainerStyle[multiplier] ? dynamicContainerStyle[multiplier] : '';
  return <div className={`containerFlex ${classNames(multiplier === 'flex' ? containerFlex : dynamicStyleClass)}`} data-sentry-component="HorizontalSpaceBlok" data-sentry-source-file="HorizontalSpaceBlok.tsx">

    </div>;
};